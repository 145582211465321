import { TOKEN_LOGOS, TOKEN_NAMES } from 'js/constants/shared'
import { useOrderBookMetasQuery } from 'js/providers/hooks/order-book-metas-hooks'
import Icon from '../uikit/Icon'
import TableCell from '../uikit/table/TableCell'

interface MarketCellProps {
  marketIndex: number
}

const MarketCell = ({ marketIndex }: MarketCellProps) => {
  const orderBookMetasQuery = useOrderBookMetasQuery()
  const symbol = orderBookMetasQuery.data[marketIndex]!.symbol

  return (
    <TableCell>
      <div className="flex gap-1 max-mobile:flex-col">
        <Icon icon={TOKEN_LOGOS[symbol as keyof typeof TOKEN_LOGOS]!} className="size-6" />
        <div className="flex flex-col">
          <p className="typography-body-1 whitespace-nowrap text-white max-mobile:hidden">
            {TOKEN_NAMES[symbol as keyof typeof TOKEN_NAMES]}
          </p>
          <p className="typography-body-1 text-white-opaque">{symbol}</p>
        </div>
      </div>
    </TableCell>
  )
}

export default MarketCell
