import { Backdrop } from './Backdrop'
import { Button } from './Button'
import { Modal } from './Modal'
import { useState } from 'react'
import { postFeedback } from 'js/util/api/api'
import Input from './uikit/Input'
import Textarea from './uikit/Textarea'
import { toast } from 'sonner'
import Toast from './uikit/Toast'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'

export const Feedback = ({ isOpen, closeModal }: { isOpen: boolean; closeModal: () => void }) => {
  const [telegram, setTelegram] = useState('')
  const [feedback, setFeedback] = useState('')

  const onSubmit = async () => {
    if (!feedback.trim()) {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Please enter your feedback"
          onClose={() => toast.dismiss(toastId)}
        />
      ))

      return
    }

    const res = await postFeedback({ telegram, feedback })
    if (res) {
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Feedback submitted successfully"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
      setTelegram('')
      setFeedback('')
      closeModal()
    } else {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
    }
  }

  const onCancel = () => {
    setTelegram('')
    setFeedback('')
    closeModal()
  }

  return (
    <>
      <Backdrop fullScreen isVisible={isOpen} />
      <Modal modalTitle="Support" isOpen={isOpen} onClose={closeModal}>
        <div className="flex max-w-full flex-col gap-6">
          <p className="typography-label-1 text-white">
            We are constantly improving our protocol and would love to hear your feedback. Please
            feel free to reach out to us with any suggestions or comments and we will get back to
            you promptly.
          </p>
          <Input
            className="bg-white-transparent"
            placeholder="Your telegram"
            onChange={(e) => setTelegram(e.target.value)}
          />
          <Textarea
            className="bg-white-transparent pt-2"
            placeholder="Your feedback"
            onChange={(e) => setFeedback(e.target.value)}
          />
          <div className="flex justify-end gap-4">
            <Tooltip open={feedback && telegram ? false : undefined}>
              <TooltipTrigger asChild>
                <Button disabled={!telegram || !feedback} onClick={onSubmit} className="w-fit">
                  Submit
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p className="typography-body-2 text-white">
                  {!telegram
                    ? 'Please provide your telegram'
                    : !feedback
                    ? 'Please provide feedback'
                    : ''}
                </p>
              </TooltipContent>
            </Tooltip>
            <Button color="grey" className="w-fit" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
