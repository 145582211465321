import { useResponsiveness } from 'js/ResponsivenessProvider'
import ProtocolPublicPoolList from './ProtocolPublicPoolList'
import UserPublicPoolList from './UserPublicPoolList'

const PublicPoolsLists = () => {
  const { isMobile } = useResponsiveness()

  return isMobile ? <MobilePoolsList /> : <DesktopPublicPoolsLists />
}

const DesktopPublicPoolsLists = () => (
  <div className="flex flex-col gap-4 rounded-lg border bg-black p-8">
    <div className="flex flex-col gap-3">
      <p className="typography-text-6 text-white">Protocol Public Pools</p>
      <ProtocolPublicPoolList />
    </div>
    <div className="flex flex-col gap-3">
      <p className="typography-text-6 text-white">User Public Pools</p>
      <div className="overflow-x-scroll">
        <UserPublicPoolList />
      </div>
    </div>
  </div>
)

const MobilePoolsList = () => (
  <div className="flex flex-col gap-3">
    <p className="typography-text-6 text-white">Protocol Public Pools</p>
    <div className="overflow-scroll whitespace-nowrap rounded-lg border bg-black-darkest">
      <ProtocolPublicPoolList />
    </div>
    <div className="flex flex-col gap-3">
      <p className="typography-text-6 text-white">User Public Pools</p>
      <div className="overflow-scroll whitespace-nowrap rounded-lg border bg-black-darkest">
        <UserPublicPoolList />
      </div>
    </div>
  </div>
)

export default PublicPoolsLists
