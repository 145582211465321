import { useState } from 'react'

import { TradesList } from 'js/pages/trade/components/orders/all-orders/TradesList'
import { OrderBook } from 'js/pages/trade/components/orders/all-orders/OrderBook'
import { UserOrders } from 'js/pages/trade/components/orders/user-orders'
import { PageContainer } from 'js/pages/page-container/PageContainer'
import { DepthChart } from 'js/pages/trade/components/chart/DepthChart'
import { InfoBox } from 'js/pages/trade/components/info-box/InfoBox'

import { TradingViewChart } from '../trade/components/chart/TradingViewChart'
import { useShowOnboarding } from 'js/providers/user-store'
import { ConnectWalletModal } from 'js/shared-components/ConnectWalletModal'
import { FundingRateChart } from '../trade/components/chart/FundingRateChart'
import { MarketDetails } from '../trade/components/chart/MarketDetails'
import { LiquidationNotifications } from '../trade/components/place-order-panel/components/LiquidationNotifications'
import cn from 'js/util/cn'
import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'

const Tabs = {
  book: {
    title: 'Order Book',
    icon: 'book',
  },
  trades: {
    title: 'Market Trades',
    icon: 'trades',
  },
  price: {
    title: 'Price',
    icon: 'price',
  },
  depth: {
    title: 'Depth',
    icon: 'depth',
  },
  funding: {
    title: 'Funding',
    icon: 'funding',
  },
  details: {
    title: 'Details',
    icon: 'details',
  },
} as const

type Tab = keyof typeof Tabs

const TabletViewTrade = () => {
  const [selectedTab, setSelectedTab] = useState<Tab>('book')
  const showOnboarding = useShowOnboarding()

  return (
    <PageContainer>
      <LiquidationNotifications />
      {showOnboarding && <ConnectWalletModal />}
      <div className="flex size-full p-2">
        <div className="flex size-full flex-col gap-2">
          <InfoBox />
          <div className="rounded-xl border">
            <div className="flex flex-col">
              <div className="flex h-10 w-full items-center justify-between border-b bg-white-transparent px-3">
                <p className="typography-body-2 text-white">{Tabs[selectedTab].title}</p>
              </div>
              <div className="flex h-[300px] w-full">
                <div className={cn('relative size-full', { hidden: selectedTab !== 'depth' })}>
                  {selectedTab === 'depth' && <DepthChart />}
                </div>
                <div className={cn('size-full', { hidden: selectedTab !== 'price' })}>
                  <TradingViewChart />
                </div>
                <div className={cn('size-full', { hidden: selectedTab !== 'funding' })}>
                  <FundingRateChart />
                </div>
                <div className={cn('flex size-full flex-col', { hidden: selectedTab !== 'book' })}>
                  {selectedTab === 'book' && <OrderBook />}
                </div>
                <div
                  className={cn('flex size-full flex-col', { hidden: selectedTab !== 'trades' })}
                >
                  {selectedTab === 'trades' && <TradesList selectedSide="all" />}
                </div>
                <div
                  className={cn('flex size-full flex-col', { hidden: selectedTab !== 'details' })}
                >
                  {selectedTab === 'details' && <MarketDetails />}
                </div>
              </div>
            </div>
            <div className="flex w-full justify-center gap-2 p-3">
              {Object.entries(Tabs).map(([tab, { icon }]) => (
                <Clickable
                  className={cn(
                    'flex size-16 items-center justify-center rounded-md hover:text-white',
                    {
                      'bg-blue-grey text-white': selectedTab === tab,
                      'text-white-opaque': selectedTab !== tab,
                    },
                  )}
                  key={tab}
                  onClick={() => setSelectedTab(tab as Tab)}
                >
                  <Icon icon={icon} className={'size-8'} />
                </Clickable>
              ))}
            </div>
          </div>
          <div className="h-full overflow-hidden rounded-xl border">
            <UserOrders />
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default TabletViewTrade
