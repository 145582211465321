import { getNetwork, useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { utils } from 'ethers'
import { createClient, initWASM } from 'js/zklighter-js-sdk/sdk'
import { useAccountIndex, useUserStore } from '../user-store'
import { useEffect, useMemo } from 'react'
import {
  clientRegistrationDataSchema,
  readLSLastAccountIndex,
  readLSSignature,
  writeLSLastAccountIndex,
  writeLSSignature,
} from 'js/util/localStorage'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { accountApi } from 'js/util/api/sdk'
import type { AccountRequest, DetailedAccount } from 'zklighter-perps'
import { SubAccountType } from '../types'
import { useLocation } from 'react-router-dom'
import { useIdentifyUser } from 'js/util/sentry'

export const useUserAddress = () => {
  const dyn = useDynamicContext()

  return useMemo(() => {
    const address = dyn.primaryWallet?.address ?? ''

    return address.length > 0 ? utils.getAddress(address) : address
  }, [dyn.primaryWallet?.address])
}

export const useAccountsQuery = () => {
  const location = useLocation()
  const userAddress = useUserAddress()
  const params: AccountRequest = useMemo(
    () => ({ by: 'l1_address', value: userAddress }),
    [userAddress],
  )
  return useQuery({
    queryKey: ['account', params],
    queryFn: () => accountApi.account(params),
    enabled: !!userAddress.length,
    refetchInterval: location.pathname.includes('sub-accounts') ? 5000 : undefined,
  })
}

const useVerifyAccount = () => {
  const queryClient = useQueryClient()

  return async (account: DetailedAccount) => {
    if (queryClient.getQueryData(['isRegistered', account.index])) {
      return true
    }

    const apiKey = await accountApi
      .apikeys({ api_key_index: 0, account_index: account.index })
      .then(({ api_keys }) => api_keys[0]?.public_key)
      .catch(() => '')

    const { data: signatureFromStorage } = readLSSignature()

    const storageAccount = signatureFromStorage?.[account.index]

    if (!storageAccount || !apiKey || storageAccount.pk !== apiKey) {
      return false
    }

    await initWASM()
    const clientResData = await createClient({
      url: '',
      seed: storageAccount.seed,
      address: account.l1_address,
      chainId: 300,
      accountIndex: account.index,
      nonce: 0, // nonce is not used only used when initializing
    })

    const { error } = clientRegistrationDataSchema.safeParse(clientResData)

    // recovery went wrong, removing from storage
    if (error) {
      writeLSSignature({ ...signatureFromStorage, [account.index]: undefined })

      return false
    }

    return true
  }
}

export const useVerifyAndSwitchAccountMutation = () => {
  const verifyAccount = useVerifyAccount()
  const setIsRegistered = useSetIsRegistered()

  return useMutation({
    mutationFn: (account: DetailedAccount) =>
      verifyAccount(account).then((isRegistered) => {
        setIsRegistered(account.index, isRegistered)
        writeLSLastAccountIndex(account.index)

        useUserStore.setState({ accountIndex: account.index, showOnboarding: !isRegistered })
      }),
  })
}

export const useSetIsRegistered = () => {
  const queryClient = useQueryClient()

  return (accountIndex: number, value: boolean) =>
    queryClient.setQueryData(['isRegistered', accountIndex], value)
}

export const useIsRegisteredQuery = () => {
  const verifyAccount = useVerifyAccount()
  const userAccount = useUserAccount()

  return useQuery({
    queryKey: ['isRegistered', userAccount?.index],
    queryFn: () => verifyAccount(userAccount!),
    initialData: null,
    enabled: !!userAccount,
  })
}

export const useUserAccount = () => {
  const accountIndex = useAccountIndex()
  const accountQuery = useAccountsQuery()

  return useMemo(
    () => accountQuery.data?.accounts.find(({ index }) => index === accountIndex),
    [accountQuery.data, accountIndex],
  )
}

export const useMainAccount = () => {
  const accountsQuery = useAccountsQuery()

  return useMemo(
    () =>
      accountsQuery.data?.accounts.find(({ account_type }) => account_type === SubAccountType.main),
    [accountsQuery.data],
  )
}

export const useSubAccounts = () => {
  const accountQuery = useAccountsQuery()

  return useMemo(
    () =>
      accountQuery.data?.accounts.filter(
        ({ account_type }) => account_type === SubAccountType.sub,
      ) ?? [],
    [accountQuery.data],
  )
}

export const usePublicAccounts = () => {
  const accountQuery = useAccountsQuery()

  return useMemo(
    () =>
      accountQuery.data?.accounts.filter(
        ({ account_type }) => account_type === SubAccountType.public,
      ) ?? [],
    [accountQuery.data],
  )
}

const useCreateKnownClients = () => {
  const queryClient = useQueryClient()
  const verifyAccount = useVerifyAccount()
  const accountsQuery = useAccountsQuery()

  useEffect(() => {
    const lsSignature = readLSSignature().data

    if (!lsSignature || !accountsQuery.data) {
      return
    }

    Object.keys(lsSignature).forEach((accountIndex) => {
      const account = accountsQuery.data.accounts.find(({ index }) => `${index}` === accountIndex)

      if (!account) {
        return
      }

      verifyAccount(account).then((isRegistered) =>
        queryClient.setQueryData(['isRegistered', account.index], isRegistered),
      )
    })
  }, [accountsQuery.data])
}

export const useAccount = () => {
  const dyn = useDynamicContext()
  const accountsQuery = useAccountsQuery()

  useEffect(() => {
    if (accountsQuery.isPending) {
      return
    }

    if (accountsQuery.isError) {
      return useUserStore.setState({ accountIndex: null })
    }

    const subAccounts = accountsQuery.data.accounts
    useUserStore.setState({
      accountIndex:
        (
          subAccounts.find(({ index }) => index === readLSLastAccountIndex().data) ??
          subAccounts.find(({ account_type }) => account_type === SubAccountType.main)
        )?.index ?? null,
    })
  }, [accountsQuery.isPending, accountsQuery.isError, accountsQuery.data])

  useEffect(() => {
    const fn = async () => {
      if (!dyn.primaryWallet?.connector) {
        return
      }

      await getNetwork(dyn.primaryWallet.connector)
    }

    fn()
  }, [dyn.primaryWallet?.connector, dyn.network])

  useCreateKnownClients()
  useIdentifyUser()
}
