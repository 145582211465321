import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import {
  useAccountsQuery,
  useIsRegisteredQuery,
  useMainAccount,
  useUserAccount,
  useUserAddress,
  useVerifyAndSwitchAccountMutation,
} from 'js/providers/hooks/useAccountQuery'
import { useUserStore } from 'js/providers/user-store'
import { Button } from 'js/shared-components'
import type { ReactNode } from 'react'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'

type PublicPoolActionsBlockerButtonProps = {
  children: ReactNode
  className?: string
}

const PublicPoolActionsBlocker = ({ children, className }: PublicPoolActionsBlockerButtonProps) => {
  const userAddress = useUserAddress()
  const mainAccount = useMainAccount()
  const userAccount = useUserAccount()
  const isRegisteredQuery = useIsRegisteredQuery()
  const accountsQuery = useAccountsQuery()
  const verifyAndSwitchAccountMutation = useVerifyAndSwitchAccountMutation()
  const { setShowAuthFlow, sdkHasLoaded } = useDynamicContext()

  if (!sdkHasLoaded || accountsQuery.isLoading || isRegisteredQuery.isLoading) {
    return null
  }

  if (!userAddress) {
    return (
      <Button className={className} onClick={() => setShowAuthFlow(true)}>
        Connect
      </Button>
    )
  }

  if (!userAccount || !mainAccount) {
    return (
      <Button className={className} onClick={() => useUserStore.setState({ showOnboarding: true })}>
        Create Account
      </Button>
    )
  }

  if (userAccount.index !== mainAccount.index) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            className={className}
            onClick={() => verifyAndSwitchAccountMutation.mutate(mainAccount)}
            isLoading={verifyAndSwitchAccountMutation.isPending}
          >
            Switch
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="typography-body-2 text-white">
            Switch to the main account to operate on this public pool
          </p>
        </TooltipContent>
      </Tooltip>
    )
  }

  if (!isRegisteredQuery.data) {
    return (
      <Button className={className} onClick={() => useUserStore.setState({ showOnboarding: true })}>
        Recover Keys
      </Button>
    )
  }

  return <>{children}</>
}

export default PublicPoolActionsBlocker
