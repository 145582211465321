import { TOKEN_LOGOS } from 'js/constants/shared'
import { useOrderBookMetasQuery } from 'js/providers/hooks/order-book-metas-hooks'
import { type SetStateAction, type Dispatch } from 'react'
import { SelectorDropdown } from 'js/shared-components/SelectorDropdown'
import type { OrderBookDetail } from 'zklighter-perps'

interface MarketSelectorProps {
  selectedMarket: OrderBookDetail | null
  setSelectedMarket: Dispatch<SetStateAction<OrderBookDetail | null>>
}

export const MarketSelector = ({ selectedMarket, setSelectedMarket }: MarketSelectorProps) => {
  const orderBookMetasQuery = useOrderBookMetasQuery()

  const options = [
    { title: 'All', onClick: () => setSelectedMarket(null), icon: 'coin' as const, key: 'all' },
    ...Object.values(orderBookMetasQuery.data).map((market) => ({
      title: market.symbol,
      key: market.symbol,
      onClick: () => setSelectedMarket(market),
      icon: TOKEN_LOGOS[market.symbol as keyof typeof TOKEN_LOGOS],
    })),
  ]

  return <SelectorDropdown options={options} selectedOption={selectedMarket?.symbol ?? 'all'} />
}
