import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { useMutation } from '@tanstack/react-query'
import { useUserAccountStats } from 'js/providers/accounts-store'
import { useCurrentMarket, useCurrentMarketId } from 'js/providers/hooks/order-book-metas-hooks'
import {
  useAccountsQuery,
  useIsRegisteredQuery,
  useUserAccount,
  useUserAddress,
} from 'js/providers/hooks/useAccountQuery'
import { useUserStore } from 'js/providers/user-store'
import { Button } from 'js/shared-components'
import Icon from 'js/shared-components/uikit/Icon'
import { useMemo, useRef } from 'react'
import { useOrderInputStore } from './PlaceOrder'
import { isZero } from 'js/util/util'
import { OrderTypeEnum } from 'zklighter-perps'
import {
  getOrderExpiry,
  getOrderPrice,
  getOrderTimeInForce,
  getOrderType,
  getPlaceOrderButtonLabel,
} from './utils'
import { createOrder } from 'js/zklighter-js-sdk/sdk'
import type { TxEventInfo } from 'js/types/api-types'
import { toast } from 'sonner'
import { OrderNotification } from './components/OrderNotification'
import Toast from 'js/shared-components/uikit/Toast'

const useBuyingPower = () => {
  const currentMarket = useCurrentMarket()
  const portfolioStats = useUserAccountStats()

  return useMemo(() => {
    const initialMargin = currentMarket.initial_margin_fraction / 10_000
    const marketLeverage = 1 / initialMargin

    return Number(portfolioStats.available_balance ?? 0) * marketLeverage
  }, [portfolioStats, currentMarket])
}

interface PlaceOrderMutationParams {
  accountIndex: number
  marketId: number
  amount0: string
  worstExecutionPrice: number
  estimatedPrice: string
}

const usePlaceOrderMutation = () => {
  const toastIdRef = useRef<number | string | null>(null)
  const orderInputs = useOrderInputStore()
  const currentMarket = useCurrentMarket()
  const currentMarketId = useCurrentMarketId()

  return useMutation({
    mutationFn: ({
      accountIndex,
      amount0,
      worstExecutionPrice,
      marketId,
    }: PlaceOrderMutationParams) =>
      createOrder({
        clientOrderIndex: 0,
        accountIndex,
        orderBookIndex: marketId,
        baseAmount: Math.floor(Number(amount0) * 10 ** currentMarket.supported_size_decimals),
        price: getOrderPrice(
          orderInputs.kind,
          orderInputs.isShort(),
          Number(orderInputs.limitPrice) * 10 ** currentMarket.supported_price_decimals,
          worstExecutionPrice * 10 ** currentMarket.supported_price_decimals,
        ),
        isAsk: Number(orderInputs.isShort()),
        reduceOnly: Number(orderInputs.reduceOnly),
        orderType: getOrderType(orderInputs.kind),
        timeInForce: getOrderTimeInForce(
          orderInputs.kind,
          orderInputs.timeInForce,
          orderInputs.postOnly,
        ),
        orderExpiry: getOrderExpiry(
          orderInputs.kind,
          orderInputs.timeInForce,
          orderInputs.timeInForceValue,
          orderInputs.timeInForceUnit,
          orderInputs.runtimeMinutes,
          orderInputs.runtimeHours,
        ),
        triggerPrice:
          Number(orderInputs.triggerPrice) * 10 ** currentMarket.supported_price_decimals,
      }),
    onMutate: ({ amount0, estimatedPrice, marketId }) => {
      toastIdRef.current = toast.custom(
        (toastId) => (
          <OrderNotification
            orderType={orderInputs.kind}
            size={Number(amount0)}
            price={
              [
                OrderTypeEnum.Limit,
                OrderTypeEnum.StopLossLimit,
                OrderTypeEnum.TakeProfitLimit,
              ].includes(orderInputs.kind)
                ? Number(orderInputs.limitPrice)
                : Number(estimatedPrice)
            }
            isAsk={orderInputs.isShort()}
            marketId={marketId}
            closePopup={() => toast.dismiss(toastId)}
          />
        ),
        { position: 'top-right', duration: 12000 },
      )
    },
    onSuccess: ({ event_info }, { amount0, estimatedPrice }) => {
      const eventInfo = JSON.parse(event_info) as TxEventInfo
      const orderIndex = eventInfo.to ? eventInfo.to.i : eventInfo.i

      toast.custom(
        () => (
          <OrderNotification
            orderType={orderInputs.kind}
            size={Number(amount0)}
            price={
              [
                OrderTypeEnum.Limit,
                OrderTypeEnum.StopLossLimit,
                OrderTypeEnum.TakeProfitLimit,
              ].includes(orderInputs.kind)
                ? Number(orderInputs.limitPrice)
                : Number(estimatedPrice)
            }
            isAsk={orderInputs.isShort()}
            marketId={currentMarketId}
            orderIndex={orderIndex}
            closePopup={() => toast.dismiss(toastIdRef.current!)}
          />
        ),
        { id: toastIdRef.current! },
      )
    },
    onError: () => {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
      toast.dismiss(toastIdRef.current!)
    },
    onSettled: () => {
      orderInputs.update('size', '')
      orderInputs.update('limitPrice', '')
      orderInputs.setStopPrice('')
    },
  })
}

interface PlaceOrderButtonProps {
  notEnoughLiquidity: boolean
  liquidationWarning: boolean
  triggerPriceInvalid: boolean
  passesLimitOrderChecks: boolean
  amount0: string
  worstExecutionPrice: number
  estimatedPrice: string
}

const PlaceOrderButton = ({
  notEnoughLiquidity,
  liquidationWarning,
  triggerPriceInvalid,
  passesLimitOrderChecks,
  amount0,
  worstExecutionPrice,
  estimatedPrice,
}: PlaceOrderButtonProps) => {
  const userAddress = useUserAddress()
  const userAccount = useUserAccount()
  const accountsQuery = useAccountsQuery()
  const isRegistered = useIsRegisteredQuery().data
  const currentMarket = useCurrentMarket()
  const portfolioStats = useUserAccountStats()
  const { setShowAuthFlow, sdkHasLoaded } = useDynamicContext()
  const orderInputs = useOrderInputStore()
  const buyingPower = useBuyingPower()
  const placeOrderMutation = usePlaceOrderMutation()

  if (!userAddress) {
    return (
      <Button
        isLoading={!sdkHasLoaded}
        onClick={() => setShowAuthFlow(true)}
        className="flex w-full gap-2"
      >
        <Icon className="size-5" icon="wallet" />
        Connect Wallet to Trade
      </Button>
    )
  }

  if (!userAccount) {
    return (
      <Button
        className="w-full"
        isLoading={accountsQuery.isLoading}
        onClick={() => useUserStore.setState({ showOnboarding: true })}
      >
        Create Account
      </Button>
    )
  }

  if (portfolioStats.available_balance === null) {
    return <Button className="w-full" isLoading />
  }

  if (!isRegistered) {
    return (
      <Button
        className="w-full"
        onClick={() => useUserStore.setState({ showOnboarding: true })}
        isLoading={isRegistered === null}
      >
        Recover Keys
      </Button>
    )
  }

  if (buyingPower <= 0) {
    return (
      <Button className="w-full" disabled>
        Insufficient Funds
      </Button>
    )
  }

  if (isZero(orderInputs.value)) {
    return (
      <Button className="w-full" disabled>
        Enter Amount
      </Button>
    )
  }

  if (!orderInputs.isLimit() && notEnoughLiquidity) {
    return (
      <Button className="w-full" disabled>
        Not enough liquidity
      </Button>
    )
  }

  if (orderInputs.isLimit() && isZero(orderInputs.limitPrice)) {
    return (
      <Button className="w-full" disabled>
        Enter Limit Price
      </Button>
    )
  }

  if (orderInputs.isStop() && isZero(orderInputs.triggerPrice)) {
    return (
      <Button className="w-full" disabled>
        Enter Stop Price
      </Button>
    )
  }

  if (
    orderInputs.isTwap() &&
    isZero(orderInputs.runtimeHours) &&
    isZero(orderInputs.runtimeMinutes)
  ) {
    return (
      <Button className="w-full" disabled>
        Enter Running Time
      </Button>
    )
  }

  if (liquidationWarning) {
    return (
      <Button className="w-full" disabled>
        Not Enough Margin
      </Button>
    )
  }

  if (
    orderInputs.isLimit() &&
    orderInputs.timeInForce === 'gtd' &&
    (orderInputs.timeInForceValue === '' ||
      (orderInputs.timeInForceUnit === 'm' && Number(orderInputs.timeInForceValue) < 10))
  ) {
    return (
      <Button className="w-full" disabled>
        Minimum Duration is 10 minutes
      </Button>
    )
  }

  if (triggerPriceInvalid) {
    return (
      <Button className="w-full" disabled>
        Trigger Price Invalid
      </Button>
    )
  }

  return (
    <Button
      className="w-full"
      isLoading={placeOrderMutation.isPending && !placeOrderMutation.isError}
      disabled={orderInputs.isLimit() && !passesLimitOrderChecks}
      onClick={() =>
        placeOrderMutation.mutate({
          accountIndex: userAccount.index,
          marketId: currentMarket.market_id,
          amount0,
          worstExecutionPrice,
          estimatedPrice,
        })
      }
    >
      {getPlaceOrderButtonLabel(orderInputs.kind)}
    </Button>
  )
}

export default PlaceOrderButton
