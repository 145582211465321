import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useUserAccountStats } from 'js/providers/accounts-store'
import { useMainAccount } from 'js/providers/hooks/useAccountQuery'
import { Button } from 'js/shared-components'
import { Backdrop } from 'js/shared-components/Backdrop'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import { Modal } from 'js/shared-components/Modal'
import PercentageInput from 'js/shared-components/PercentageInput'
import Input from 'js/shared-components/uikit/Input'
import Toast from 'js/shared-components/uikit/Toast'

import { accountApi } from 'js/util/api/sdk'
import { createPublicPool, setAccountMetadata } from 'js/zklighter-js-sdk/sdk'
import { useState, type ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

const MIN_USDC = 10000

const createPublicPoolEventInfoSchema = z.object({
  a: z.number(),
})

const wait = () => new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))

const CreatePublicPoolModal = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mainAccount = useMainAccount()
  const [modalOpen, setModalOpen] = useState(false)
  const [publicPoolName, setPublicPoolName] = useState('')
  const [publicPoolDescription, setPublicPoolDescription] = useState('')
  const [publicPoolOperatorFee, setPublicPoolOperatorFee] = useState('10')
  const [publicPoolMinimumOperatorShare, setPublicPoolMinimumOperatorShare] = useState('5')

  const [publicPoolDeposit, setPublicPoolDeposit] = useState('')
  const { portfolio_value } = useUserAccountStats()
  const availableToDeposit = Number(portfolio_value)

  const parseInput = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/,/g, '.').replace(/^0+(?=[0-9])/, '')

    if (newValue === '') {
      return newValue
    }

    if (!/^[0-9]*\.?[0-9]{0,2}$/.test(newValue)) {
      return null
    }

    if (Number(newValue) > availableToDeposit) {
      return availableToDeposit.toFixed(2)
    }

    return newValue
  }

  const onDepositChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseInput(e)

    if (parsedValue === null) {
      return
    }

    setPublicPoolDeposit(parsedValue)
  }

  const onOperatorFeeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseInput(e)

    if (parsedValue === null) {
      return
    }

    if (Number(parsedValue) > 100) {
      return setPublicPoolOperatorFee('100')
    }

    setPublicPoolOperatorFee(parsedValue)
  }

  const onMinimumOperatorShareChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseInput(e)

    if (parsedValue === null) {
      return
    }

    if (Number(parsedValue) > 100) {
      return setPublicPoolMinimumOperatorShare('100')
    }

    setPublicPoolMinimumOperatorShare(parsedValue)
  }

  const createPublicPoolMutation = useMutation({
    mutationFn: async () => {
      const { event_info } = await createPublicPool({
        accountIndex: mainAccount!.index,
        operatorFee: 10000 * Number(publicPoolOperatorFee),
        initialTotalShares: 1000 * Number(publicPoolDeposit),
        minOperatorShareRate: 5 * Number(publicPoolMinimumOperatorShare),
      })
      const newPublicPoolIndex = createPublicPoolEventInfoSchema.parse(JSON.parse(event_info)).a
      await setAccountMetadata({
        targetAccountIndex: newPublicPoolIndex,
        masterAccountIndex: mainAccount!.index,
        name: publicPoolName,
        description: publicPoolDescription,
      })
      const params = { by: 'index', value: `${newPublicPoolIndex}` } as const
      let newPublicPool = await accountApi.account(params)

      while (newPublicPool.accounts.length === 0) {
        await wait()
        newPublicPool = await accountApi.account(params)
      }

      return { newPublicPool, params }
    },
    onSuccess: ({ newPublicPool, params }) => {
      queryClient.setQueryData(['public_pool', params], newPublicPool.accounts[0])
      queryClient.invalidateQueries({ queryKey: ['account'] })
      navigate(`/public-pools/${newPublicPool.accounts[0]!.index}`)
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Public Pool created successfully"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
    },
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => setModalOpen(false),
  })

  return (
    <>
      <Button className="w-fit" onClick={() => setModalOpen(true)}>
        Create Public Pool
      </Button>
      <Backdrop isVisible={modalOpen} fullScreen onClick={() => setModalOpen(false)} />
      <Modal
        isOpen={modalOpen}
        modalTitle="Create Your Own Public Pool"
        onClose={() => setModalOpen(false)}
      >
        <div className="flex w-full flex-col gap-4">
          <div className="flex flex-col gap-2">
            <p className="typography-body-1 text-white">Enter a name for your Public Pool</p>
            <Input
              autoFocus
              placeholder="Public Pool Name"
              value={publicPoolName}
              onChange={(e) => setPublicPoolName(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="typography-body-1 text-white">Enter a description for your Public Pool</p>
            <Input
              placeholder="Public Pool Description"
              value={publicPoolDescription}
              onChange={(e) => setPublicPoolDescription(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="typography-body-1 text-white">
              Deposit a minimum of 10000 USDC from your account.
            </p>
            <Input placeholder="0.00" value={publicPoolDeposit} onChange={onDepositChange} />
            <div className="flex items-center justify-between">
              <p className="typography-body-1 text-white">Available to deposit</p>
              <p className="typography-body-1 text-white">
                {Number(portfolio_value).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}{' '}
                USDC
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-1">
              <p className="typography-body-1 text-white">Operator Fee</p>
              <Explanation explanation="Amount of fee public pool operator will receive from profits during shareholder withdrawals" />
            </div>
            <div className="flex items-center justify-between rounded-lg border px-3 py-2">
              <PercentageInput
                className="text-lg"
                placeholder="0"
                value={publicPoolOperatorFee}
                onChange={onOperatorFeeChange}
              />
              <p className="typography-body-1 text-white">%</p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <p className="typography-body-1 text-white">Minimum Operator Share</p>
              <Explanation explanation="Minimum operator share that will be enforced, deposits that would drop the operator ownership below this configuration will be rejected" />
            </div>
            <div className="flex items-center justify-between rounded-lg border px-3 py-2">
              <PercentageInput
                className="text-lg"
                placeholder="0"
                value={publicPoolMinimumOperatorShare}
                onChange={onMinimumOperatorShareChange}
              />
              <p className="typography-body-1 text-white">%</p>
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <Button
              className="w-fit"
              color="grey"
              onClick={() => setModalOpen(false)}
              disabled={createPublicPoolMutation.isPending && !createPublicPoolMutation.isError}
            >
              Cancel
            </Button>
            <Button
              className="w-fit"
              onClick={() => createPublicPoolMutation.mutate()}
              isLoading={createPublicPoolMutation.isPending && !createPublicPoolMutation.isError}
              disabled={
                Number.isNaN(Number(publicPoolDeposit)) ||
                Number(publicPoolDeposit) < MIN_USDC ||
                publicPoolName.length < 3
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CreatePublicPoolModal
