import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { useAccountStats, usePublicPoolInfo } from 'js/providers/accounts-store'
import { SubAccountType, type Share } from 'js/providers/types'
import { accountApi } from 'js/util/api/sdk'
import { formatUSD } from 'js/util/formatting'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import type { AccountRequest, PublicPool, PublicPoolsRequest } from 'zklighter-perps'

type PublicPoolParams = {
  public_pool_index: string
}

const usePublicPoolPageParams = () => useParams() as PublicPoolParams

export const useProtocolPoolListQuery = () => {
  const params: PublicPoolsRequest = { index: 0, limit: 100, filter: 'protocol' }

  return useQuery({
    queryKey: ['public_pool', params],
    queryFn: () => accountApi.publicPools(params),
    refetchInterval: 5000,
  })
}
export const usePublicPoolQuery = () => {
  const { public_pool_index } = usePublicPoolPageParams()
  const params: AccountRequest = useMemo(
    () => ({ by: 'index', value: public_pool_index }),
    [public_pool_index],
  )

  return useSuspenseQuery({
    queryKey: ['public_pool', params],
    queryFn: () => accountApi.account(params).then(({ accounts }) => accounts[0]!),
    refetchInterval: 5000,
  })
}

export const getYourDeposits = (
  shares: Share[],
  publicPoolIndex: number,
  tvl: number,
  totalShares: number,
) => {
  const share = shares.find((share) => share.public_pool_index === publicPoolIndex)

  if (!share) {
    return '-'
  }

  return formatUSD((share.shares_amount * tvl) / totalShares)
}

export const usePublicPoolSharePrice = () => {
  const publicPoolQuery = usePublicPoolQuery()
  const totalShares = usePublicPoolInfo(publicPoolQuery.data.index)?.total_shares
  const { portfolio_value } = useAccountStats(publicPoolQuery.data.index)

  if (totalShares === undefined || portfolio_value === null) {
    return null
  }

  if (totalShares === 0) {
    return 0.001
  }

  return Number(portfolio_value) / totalShares
}

export const getPublicPoolName = (publicPool: PublicPool) =>
  publicPool.name ||
  (publicPool.account_type === SubAccountType.lighterPublic ? 'Security Pool' : 'Unnamed Pool')
