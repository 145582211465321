import Icon from './uikit/Icon'
import { Clickable } from './Clickable'

interface NotificationPopupProps {
  children: React.ReactNode
  closePopup: () => void
}

export const NotificationPopup = ({ children, closePopup }: NotificationPopupProps) => (
  <div className="group w-60 max-mobile:w-full">
    <Clickable
      className="absolute right-0 top-0 flex size-5 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full border bg-black-darkest group-hover:flex"
      onClick={closePopup}
    >
      <Icon icon="x" className="size-4" />
    </Clickable>
    <div className="flex w-full flex-col items-center gap-2 rounded-lg border bg-black-darkest p-3 transition-all">
      {children}
    </div>
  </div>
)
