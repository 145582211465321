import UIKitPriceInput from 'js/shared-components/uikit/PriceInput'
import { useOrderInputStore } from '../PlaceOrder'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useMidPrice } from 'js/providers/order-book-store'

export const PriceInput = () => {
  const orderInputs = useOrderInputStore()
  const currentMarket = useCurrentMarket()
  const midPrice = useMidPrice()
  const value = orderInputs.limitPrice
  const onChange = (e: string) => orderInputs.update('limitPrice', e)
  const decimal = currentMarket.supported_price_decimals
  const syntaxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/,/g, '.').replace(/^0+(?=\d)/, '')
    const split = inputValue.split('.')

    const regex = /^[0-9]*\.?[0-9]*$/
    if (inputValue.length <= 12 && regex.test(inputValue) && split.length <= 2) {
      if (split.length === 2 && split[1]!.length > decimal) {
        return false
      }
      e.target.value = inputValue
      return true
    }

    return false
  }

  return (
    <UIKitPriceInput
      label="Limit Price (USD)"
      helperText="This order can only be filled at the specified limit price or better. If your order crosses at placement, it will fill any crossing orders at the most favorable price."
      value={value}
      setMidPrice={() => onChange(midPrice.toFixed(decimal))}
      onChange={(e) => {
        if (syntaxCheck(e)) onChange(e.target.value)
      }}
      placeholder={Number(0).toFixed(decimal)}
    />
  )
}
