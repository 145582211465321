import { type IconType } from 'js/shared-components/uikit/Icon'

// known symbols, maybe use this for better types?
type CoinSymbol =
  | 'BTC'
  | 'kDOGE'
  | 'SOL'
  | 'ETH'
  | 'mPEPE'
  | 'AVAX'
  | 'NEAR'
  | 'WLD'
  | 'TAO'
  | 'LINK'
  | 'XRP'
  | 'DOT'
  | 'POL'
  | 'TON'
  | 'WIF'

export const TOKEN_LOGOS = {
  BTC: 'btc',
  ETH: 'eth',
  AVAX: 'avax',
  SOL: 'sol',
  kDOGE: 'doge',
  LINK: 'link',
  POL: 'matic',
  mPEPE: 'pepe',
  XRP: 'xrp',
  WIF: 'wif',
  WLD: 'wld',
  NEAR: 'near',
  DOT: 'dot',
  TON: 'ton',
  TAO: 'tao',
} as const satisfies Record<CoinSymbol, IconType>

export const TOKEN_DESCRIPTIONS = {
  BTC: 'Bitcoin (BTC) is the first and most well-known cryptocurrency, created by an anonymous person or group of people using the pseudonym Satoshi Nakamoto. It operates on a decentralized peer-to-peer network, where transactions are recorded on a public ledger called the blockchain, and is often referred to as digital gold.',
  ETH: 'Ethereum (ETH) is a decentralized blockchain platform that enables smart contracts and decentralized applications (DApps) to be built and executed. It is the second-largest cryptocurrency by market capitalization and serves as the foundation for a wide range of projects in the blockchain space.',
  AVAX: 'Avalanche (AVAX) is a blockchain platform designed for decentralized applications (DApps) and custom blockchain networks, focusing on scalability and speed. It features a consensus mechanism called Avalanche consensus, which allows for high throughput and quick finality, making it suitable for a wide range of use cases.',
  SOL: 'Solana (SOL) is a high-performance blockchain platform designed for decentralized applications (DApps) and tokenized assets, focusing on scalability and low fees. It features a unique consensus mechanism called Proof of History (PoH), which allows for high throughput and low-latency transactions, making it suitable for applications requiring high-speed and low-cost transactions.',
  kDOGE:
    'Dogecoin (DOGE) is a cryptocurrency featuring a Shiba Inu dog as its logo, originally created as a joke but has since gained a significant following and community. It is known for its active and enthusiastic community, as well as its use in tipping and charitable donations.',
  LINK: 'Chainlink (LINK) is a decentralized oracle network that enables smart contracts on various blockchain platforms to securely interact with external data sources. It provides reliable and tamper-proof data feeds for decentralized applications (DApps), enabling them to access real-world information and execute based on that data.',
  POL: "Polygon (formerly Matic) is a layer 2 scaling solution for Ethereum, providing fast and low-cost transactions for decentralized applications (DApps) and assets. It aims to address Ethereum's scalability issues by using sidechains and a variety of scaling techniques, enabling high throughput and interoperability with the Ethereum mainnet.",
  mPEPE:
    "Pepe (PEPE) is a meme-based cryptocurrency that draws inspiration from the popular internet meme 'Pepe the Frog.' PEPE has gained traction in the meme coin community due to its playful nature and has become a symbol of internet culture within the crypto space. While primarily used for speculative trading, it represents the intersection of meme culture and blockchain technology.",
  XRP: 'The XRP Ledger (XRPL) is an open-source, permissionless and decentralized technology. Benefits of the XRP Ledger include its low-cost ($0.0002 to transact), speed (settling transactions in 3-5 seconds), scalability (1,500 transactions per second) and inherently green attributes (carbon-neutral and energy-efficient).',
  WIF: 'Dogwifhat Coin (WIF) is a Solana-based memecoin designed for fun and community engagement. Like Dogecoin, it doesn’t aim to revolutionize finance but thrives on meme contests and charity drives. Solana’s fast transactions and low fees make it ideal for seamless trading, while its DeFi ecosystem allows Dogwifhat to explore yield farming, liquidity provision, and NFTs.',
  WLD: "Worldcoin (WLD) is a unique cryptocurrency focused on creating a global identity and financial network. It aims to provide universal basic income (UBI) to every individual on the planet through a decentralized blockchain system. Worldcoin's innovative approach includes biometric technology to verify users and distribute tokens fairly across the globe, fostering financial inclusion and equality.",
  NEAR: 'NEAR Protocol (NEAR) is a decentralized application platform that aims to simplify the development and deployment of dApps by providing a scalable and developer-friendly blockchain environment. NEAR uses a unique sharding technology to achieve high throughput and low transaction costs, making it an attractive choice for developers looking to build on a secure and scalable network.',
  DOT: "Polkadot (DOT) is a multi-chain blockchain platform designed to enable different blockchains to interoperate and share information securely. Polkadot's unique architecture allows for the seamless transfer of data and assets across multiple blockchains, making it a cornerstone of the Web3 movement. It is known for its ability to provide scalability, security, and governance through a decentralized and community-driven approach.",
  TON: "The Open Network (TON) is a decentralized layer-1 blockchain originally developed by Telegram. It is designed to facilitate fast transactions, distributed data storage, and decentralized applications. TON's architecture supports high transaction throughput, making it suitable for a wide range of applications, from microtransactions to large-scale decentralized systems, with a focus on privacy and efficiency.",
  TAO: "Theta Network (TAO) is a blockchain-based platform for decentralized video streaming and delivery. It aims to revolutionize the video streaming industry by providing a decentralized infrastructure that reduces costs and improves quality for both content creators and viewers. Powered by its native cryptocurrency, TAO, Theta enables users to share bandwidth and computing resources, earning rewards while enhancing the network's performance.",
} as const satisfies Record<CoinSymbol, string>

export const TOKEN_NAMES = {
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  AVAX: 'Avalanche',
  SOL: 'Solana',
  kDOGE: 'Dogecoin',
  LINK: 'Chainlink',
  POL: 'Polygon',
  mPEPE: 'PepeCoin',
  XRP: 'Ripple',
  WIF: 'Dogwifhat Coin',
  WLD: 'Worldcoin',
  NEAR: 'NEAR Protocol',
  DOT: 'Polkadot',
  TON: 'The Open Network',
  TAO: 'Theta Network',
} as const satisfies Record<CoinSymbol, string>

export enum TX_STATUSES {
  StatusFailed = 0,
  StatusPending = 1,
  StatusExecuted = 2,
  StatusPacked = 3,
  StatusCommitted = 4,
  StatusVerified = 5,
}

export enum OrderDirections {
  Short, // Sell / Ask
  Long, // Buy / Bid
}

export const USDC_SYMBOL = 'USD'
