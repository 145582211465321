import SVG from 'react-inlinesvg'
import type { ComponentProps } from 'react'

import cn from 'js/util/cn'

import trophy from 'img/shared/trophy.svg'
import wallet from 'img/wallet/wallet.svg'
import x from 'img/shared/x.svg'
import swap from 'img/shared/swap.svg'
import chevron from 'img/shared/chevron.svg'
import spinner from 'img/shared/spinner.svg'
import triangle from 'img/shared/triangle.svg'
import logo from 'img/shared/logo.svg'
import externalLink from 'img/shared/external-link.svg'
import check from 'img/shared/check.svg'
import edit from 'img/shared/edit.svg'
import arrow from 'img/shared/arrow.svg'
import question from 'img/shared/question.svg'
import priceUp from 'img/shared/price-up.svg'
import priceDown from 'img/shared/price-down.svg'
import search from 'img/shared/search.svg'

import address from 'img/wallet/address.svg'
import explorer from 'img/wallet/explorer.svg'
import logout from 'img/wallet/logout.svg'

import asks from 'img/orders/asks.svg'
import bids from 'img/orders/bids.svg'
import allOrders from 'img/orders/all-orders.svg'

import price from 'img/small-screen/price.svg'
import depth from 'img/small-screen/depth.svg'
import trades from 'img/small-screen/trades.svg'
import book from 'img/small-screen/book.svg'
import funding from 'img/small-screen/funding.svg'
import details from 'img/small-screen/details.svg'

import eth from 'img/tokens/eth.svg'
import link from 'img/tokens/link.svg'
import avax from 'img/tokens/avax.svg'
import btc from 'img/tokens/btc.svg'
import sol from 'img/tokens/sol.svg'
import doge from 'img/tokens/doge.svg'
import matic from 'img/tokens/matic.svg'
import pepe from 'img/tokens/pepe.svg'
import xrp from 'img/tokens/xrp.svg'
import wif from 'img/tokens/wif.svg'
import wld from 'img/tokens/wld.svg'
import near from 'img/tokens/near.svg'
import dot from 'img/tokens/dot.svg'
import ton from 'img/tokens/ton.svg'
import tao from 'img/tokens/tao.svg'

import coin from 'img/tokens/coin.svg'

const ICON_MAP = {
  trophy,
  wallet,
  x,
  swap,
  chevron,
  spinner,
  triangle,
  logo,
  externalLink,
  check,
  edit,
  arrow,
  question,
  priceUp,
  priceDown,
  search,

  address,
  explorer,
  logout,

  asks,
  bids,
  allOrders,

  price,
  depth,
  trades,
  book,
  funding,
  details,

  btc,
  doge,
  sol,
  eth,
  pepe,
  link,
  avax,
  matic,
  xrp,
  wif,
  wld,
  near,
  dot,
  ton,
  tao,

  coin,
} as const

export type IconType = keyof typeof ICON_MAP

type ReactSVGProps = Omit<ComponentProps<typeof SVG>, 'src'>

interface IconProps extends ReactSVGProps {
  icon: IconType
}

const Icon = ({ icon, className, ...rest }: IconProps) => (
  <span className={cn('flex shrink-0 items-center justify-center', className)}>
    <SVG src={ICON_MAP[icon]} {...rest} className="size-full" />
  </span>
)

export default Icon
