import { useOrderBookMetasQuery } from 'js/providers/hooks/order-book-metas-hooks'
import { useMarketsStats } from 'js/providers/order-book-store'
import { formatUSD } from 'js/util/formatting'
import TableCell from '../uikit/table/TableCell'

interface SizeCellProps {
  size: string
  marketIndex: number
}

const SizeCell = ({ size, marketIndex }: SizeCellProps) => {
  const marketsStats = useMarketsStats()
  const orderBookMetasQuery = useOrderBookMetasQuery()
  const mark_price = marketsStats[marketIndex]?.mark_price
  const market = orderBookMetasQuery.data[marketIndex]!

  return (
    <TableCell>
      <div className="flex flex-col">
        <div className="flex gap-1">
          <p className="typography-body-1 text-white">{size}</p>
          <div className="rounded-lg bg-grey-light px-2 py-px text-[10px] text-white">
            {market.symbol}
          </div>
        </div>
        <p className="typography-body-1 text-white-opaque">
          {formatUSD(Number(mark_price) * Number(size))}
        </p>
      </div>
    </TableCell>
  )
}

export default SizeCell
