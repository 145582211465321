import { useState } from 'react'

import { ORDER_SIDE_TABS, type OrderSide } from 'js/constants/trades'

import { OrderBook } from './OrderBook'
import { TradesList } from './TradesList'
import { Clickable } from 'js/shared-components/Clickable'
import cn from 'js/util/cn'
import Icon from 'js/shared-components/uikit/Icon'

const headerTitles = {
  orderBook: 'Order Book',
  trades: 'Market Trades',
}

export const AllOrders = ({ type }: { type: 'orderBook' | 'trades' }) => {
  const [selectedSide, setSelectedSide] = useState<OrderSide>('all')

  return (
    <>
      <div className="flex h-10 w-full items-center justify-between border-b bg-white-transparent px-3">
        <p className="typography-body-2 text-white">{headerTitles[type]}</p>
        <div className="flex gap-2">
          {Object.entries(ORDER_SIDE_TABS).map(([orderType, { icon }]) => (
            <Clickable
              key={orderType}
              className={cn(
                'flex size-7 items-center justify-center rounded-full hover:bg-grey-medium',
                {
                  'bg-semi-transparent': selectedSide === orderType,
                  'bg-transparent': selectedSide !== orderType,
                },
              )}
              onClick={() => setSelectedSide(orderType as OrderSide)}
            >
              <Icon icon={icon} className="size-5" />
            </Clickable>
          ))}
        </div>
      </div>
      {type === 'orderBook' ? (
        <OrderBook selectedSide={selectedSide} />
      ) : (
        <TradesList selectedSide={selectedSide} />
      )}
    </>
  )
}
