import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'js/shared-components/uikit/DropdownMenu'
import React, { useState } from 'react'
import { useOrderInputStore } from '../PlaceOrder'
import { CheckboxSelector } from 'js/shared-components/CheckboxSelector'
import { SmallArrow } from 'js/shared-components/SmallArrow'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'js/shared-components/uikit/Collapsible'
import Input from 'js/shared-components/uikit/Input'
import { Clickable } from 'js/shared-components/Clickable'
import { ReduceOnly } from './ReduceOnly'

const TIME_IN_FORCE_OPTIONS = [
  { title: 'Good till Time', value: 'gtd' },
  { title: 'Immediate or Cancel', value: 'ioc' },
] as const

const GOOD_TILL_TIME_UNIT_OPTIONS = [
  { title: 'Minutes', value: 'm' },
  { title: 'Hours', value: 'h' },
  { title: 'Days', value: 'd' },
] as const

export const AdvancedLimitDropdown = () => {
  const {
    timeInForce,
    timeInForceValue,
    timeInForceUnit,
    postOnly,
    changeTimeInForce,
    changeTimeInForceValue,
    changeTimeInForceUnit,
    changePostOnly,
  } = useOrderInputStore()
  const [open, setOpen] = useState(false)
  const selectedTimeInForceOption = TIME_IN_FORCE_OPTIONS.find(
    (option) => option.value === timeInForce,
  )!
  const selectedGoodTillTimeUnitOption = GOOD_TILL_TIME_UNIT_OPTIONS.find(
    (option) => option.value === timeInForceUnit,
  )!

  return (
    <Collapsible onOpenChange={setOpen} open={open}>
      <CollapsibleTrigger asChild>
        <Clickable className="mt-2 flex items-center gap-2">
          <p className="typography-body-2 text-white">Advanced</p>
          <SmallArrow direction={open ? 'up' : 'down'} />
        </Clickable>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="flex flex-col gap-4 py-3">
          <div className="flex flex-col gap-2">
            <p className="typography-body-1 text-white">Time in Force</p>
            <DropdownMenu>
              <DropdownMenuTrigger className="w-full">
                {selectedTimeInForceOption.title}
              </DropdownMenuTrigger>
              <DropdownMenuContent className="-top-0.5" align="start">
                {TIME_IN_FORCE_OPTIONS.map((option) => (
                  <DropdownMenuItem
                    key={option.value}
                    onClick={() => changeTimeInForce(option.value)}
                  >
                    {option.title}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            {timeInForce === 'gtd' && (
              <div className="flex gap-2">
                <Input
                  placeholder="10"
                  value={timeInForceValue}
                  onChange={(e) => changeTimeInForceValue(e.target.value)}
                  className="flex-1 bg-white-transparent px-2 indent-0 text-sm placeholder:indent-0 placeholder:text-sm"
                />
                <DropdownMenu>
                  <DropdownMenuTrigger className="flex-1">
                    {selectedGoodTillTimeUnitOption.title}
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {GOOD_TILL_TIME_UNIT_OPTIONS.map((option) => (
                      <DropdownMenuItem
                        key={option.value}
                        onClick={() => changeTimeInForceUnit(option.value)}
                      >
                        {option.title}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )}
          </div>
          {timeInForce === 'gtd' ? (
            <div className="flex flex-col gap-2">
              <p className="typography-body-1 text-white">Execution</p>
              <div
                className="flex cursor-pointer items-center gap-3"
                onClick={() => changePostOnly(!postOnly)}
              >
                <CheckboxSelector isSelected={postOnly} />
                <p className="typography-body-2 text-white">Post Only</p>
              </div>
              <ReduceOnly />
            </div>
          ) : (
            <ReduceOnly />
          )}
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}
