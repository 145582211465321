import { useAppState } from 'js/providers/base-store'
import type { Position } from 'js/providers/types'
import { useChangeMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface CancelPositionButtonProps {
  position: Position
}

const CancelPositionButton = ({ position }: CancelPositionButtonProps) => {
  const changeMarket = useChangeMarket()

  const onClosePosition = (position: Position) => {
    changeMarket(position.market_id)
    useAppState.setState({ isClosingPosition: true })
  }

  return (
    <TableCell>
      <Clickable color="red" onClick={() => onClosePosition(position)}>
        <Icon icon="x" className="size-4" />
      </Clickable>
    </TableCell>
  )
}

export default CancelPositionButton
