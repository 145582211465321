import { useState } from 'react'

import { TradesList } from 'js/pages/trade/components/orders/all-orders/TradesList'
import { OrderBook } from 'js/pages/trade/components/orders/all-orders/OrderBook'
import { PageContainer } from 'js/pages/page-container/PageContainer'
import { DepthChart } from 'js/pages/trade/components/chart/DepthChart'
import { Backdrop } from 'js/shared-components/Backdrop'

import { TradingViewChart } from 'js/pages/trade/components/chart/TradingViewChart'
import { UserOrders } from 'js/pages/trade/components/orders/user-orders'
import { TradeFooter } from 'js/pages/navbar/tablet-trade-dropdown/TradeFooter'
import { PlaceOrder } from 'js/pages/trade/components/place-order-panel/PlaceOrder'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'

import { MobileMarketSelector } from './components/mobile/MobileMarketSelector'
import { ConnectWalletModal } from 'js/shared-components/ConnectWalletModal'
import { FundingRateChart } from 'js/pages/trade/components/chart/FundingRateChart'
import { MarketDetails } from 'js/pages/trade/components/chart/MarketDetails'
import { useIsClosingPosition } from 'js/providers/base-store'
import { useShowOnboarding } from 'js/providers/user-store'
import { LiquidationNotifications } from 'js/pages/trade/components/place-order-panel/components/LiquidationNotifications'
import { Clickable } from 'js/shared-components/Clickable'
import cn from 'js/util/cn'
import Icon from 'js/shared-components/uikit/Icon'

const Tabs = {
  book: { title: 'Order Book', icon: 'book' },
  trades: { title: 'Trades', icon: 'trades' },
  price: { title: 'Price', icon: 'price' },
  depth: { title: 'Depth', icon: 'depth' },
  funding: { title: 'Funding', icon: 'funding' },
  details: { title: 'Details', icon: 'details' },
} as const

type Tab = keyof typeof Tabs

const MobileViewTrade = () => {
  const [selectedTab, setSelectedTab] = useState<Tab>('book')
  const [tradeModalOpen, setTradeModalOpen] = useState(false)

  const isClosingPosition = useIsClosingPosition()
  const showOnboarding = useShowOnboarding()

  return (
    <PageContainer>
      {showOnboarding && <ConnectWalletModal />}
      <LiquidationNotifications />
      <div className="mb-3 flex w-full">
        <Backdrop isVisible={tradeModalOpen} onClick={() => setTradeModalOpen(false)} />
        <div className="relative flex size-full flex-col gap-6">
          <MobileMarketSelector />
          <div className="flex flex-col">
            <div className="flex h-[300px] w-full">
              <div className={cn('relative size-full', { hidden: selectedTab !== 'depth' })}>
                {selectedTab === 'depth' && <DepthChart />}
              </div>
              <div className={cn('size-full', { hidden: selectedTab !== 'price' })}>
                <TradingViewChart />
              </div>
              <div className={cn('relative size-full', { hidden: selectedTab !== 'funding' })}>
                <FundingRateChart />
              </div>
              <div className={cn('flex size-full flex-col', { hidden: selectedTab !== 'book' })}>
                {selectedTab === 'book' && <OrderBook />}
              </div>
              <div className={cn('flex size-full flex-col', { hidden: selectedTab !== 'trades' })}>
                {selectedTab === 'trades' && <TradesList selectedSide="all" />}
              </div>
              <div className={cn('flex size-full flex-col', { hidden: selectedTab !== 'details' })}>
                {selectedTab === 'details' && <MarketDetails />}
              </div>
            </div>
          </div>
          <div className="flex w-full items-center justify-evenly">
            {Object.entries(Tabs).map(([tab, { icon, title }]) => (
              <Clickable
                key={title}
                onClick={() => setSelectedTab(tab as Tab)}
                color="white"
                className="flex flex-col items-center"
              >
                <Icon
                  icon={icon}
                  className={cn('size-8 rounded-lg p-2', {
                    'bg-blue-grey': selectedTab === tab,
                  })}
                />
                <p className="typography-body-1">{title}</p>
              </Clickable>
            ))}
          </div>
        </div>
      </div>
      <UserOrders />
      <BottomAnimatedDropdown
        className="top-0 z-[11] h-full"
        isOpen={tradeModalOpen || isClosingPosition}
      >
        <Clickable className="flex justify-end p-2" onClick={() => setTradeModalOpen(false)}>
          <Icon icon="x" className="size-5" />
        </Clickable>
        <PlaceOrder />
      </BottomAnimatedDropdown>
      {!tradeModalOpen && !isClosingPosition && (
        <TradeFooter setTradeModalOpen={setTradeModalOpen} />
      )}
    </PageContainer>
  )
}

export default MobileViewTrade
