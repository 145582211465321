import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'
import { type OrderBookDetail } from 'zklighter-perps'
import { TOKEN_LOGOS } from 'js/constants/shared'
import type { WsMarketStats } from 'js/providers/orderBookWorker/types'
import cn from 'js/util/cn'
import Icon from 'js/shared-components/uikit/Icon'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableRow from 'js/shared-components/uikit/table/TableRow'
const MarginFractionTick = 100

type MergeTypes<A, B> = Omit<A, keyof B> & B

interface MarketButtonProps {
  elem: MergeTypes<OrderBookDetail, WsMarketStats>
  onClickFunc: (marketid: number) => void
  isSelected: boolean
}
export const MarketRow = ({ elem, onClickFunc, isSelected }: MarketButtonProps) => {
  const diffPct = elem.daily_price_change
  const roundedDiffPct = parseFloat(diffPct.toFixed(2))

  return (
    <TableRow key={`${elem.market_id}`} onClick={() => onClickFunc(elem.market_id)}>
      <TableCell className="border-r">
        <div className="flex items-center gap-2">
          <Icon icon={TOKEN_LOGOS[elem.symbol as keyof typeof TOKEN_LOGOS]!} className="size-5" />
          <p
            className={cn('typography-body-1', {
              'text-white': isSelected,
              'text-white-opaque': !isSelected,
            })}
          >
            {elem.symbol}
          </p>
          <div className="rounded-full border border-semi-transparent bg-white-transparent bg-origin-border p-1 px-2">
            <p className="typography-body-1 !leading-3 text-white">
              {Math.round((100 * MarginFractionTick) / elem.initial_margin_fraction)}x
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <p
          className={cn('typography-body-2 text-center', {
            'text-white': isSelected,
            'text-white-opaque': !isSelected,
          })}
        >
          <NumberOrSpinner decimals={elem.price_decimals} value={elem.last_trade_price} />
        </p>
      </TableCell>
      <TableCell>
        <div className="flex items-center justify-center gap-1">
          {roundedDiffPct !== 0 && (
            <Icon
              icon={roundedDiffPct > 0 ? 'priceUp' : 'priceDown'}
              className={cn('size-3.5', {
                'text-green-main': roundedDiffPct > 0,
                'text-red-main': roundedDiffPct <= 0,
              })}
            />
          )}
          <p
            className={cn('typography-body-1 text-white', {
              'text-green-main': roundedDiffPct > 0,
              'text-red-main': roundedDiffPct < 0,
              'text-white': roundedDiffPct === 0 && isSelected,
              'text-white-opaque': roundedDiffPct === 0 && !isSelected,
            })}
          >
            {roundedDiffPct}%
          </p>
        </div>
      </TableCell>
      <TableCell>
        <p
          className={cn('typography-body-2 text-center', {
            'text-white': isSelected,
            'text-white-opaque': !isSelected,
          })}
        >
          <NumberOrSpinner decimals={elem.price_decimals} value={elem.index_price} />
        </p>
      </TableCell>
      <TableCell>
        <p
          className={cn('typography-body-2 text-center', {
            'text-white': isSelected,
            'text-white-opaque': !isSelected,
          })}
        >
          <NumberOrSpinner decimals={elem.price_decimals} value={elem.mark_price} />
        </p>
      </TableCell>
      <TableCell>
        <p
          className={cn('typography-body-2 text-center', {
            'text-white': isSelected,
            'text-white-opaque': !isSelected,
          })}
        >
          <NumberOrSpinner decimals={2} value={elem.daily_quote_token_volume} />
        </p>
      </TableCell>
    </TableRow>
  )
}

const NumberOrSpinner = ({ value, decimals }: { value: number | string; decimals: number }) => {
  if (value !== undefined) {
    return (
      <>
        {Number(value).toLocaleString('en-US', {
          maximumFractionDigits: decimals,
          minimumFractionDigits: decimals,
          style: 'currency',
          currency: 'USD',
        })}
      </>
    )
  }
  return <SkeletonRectangle className="h-[1ch] w-10" />
}
