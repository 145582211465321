import cn from 'js/util/cn'
import type { InputHTMLAttributes } from 'react'

const Input = ({ className, ...rest }: InputHTMLAttributes<HTMLInputElement>) => (
  <input
    className={cn(
      'h-11 rounded-lg border bg-transparent text-white',
      'indent-3 text-lg placeholder:indent-3 placeholder:text-lg placeholder:text-white-opaque/50',
      className,
    )}
    {...rest}
  />
)

export default Input
