import { useEffect, useRef, useState } from 'react'

import { SelectorIndicator, TabButton } from 'js/shared-components'
import { ORDER_SIDE_TABS, type OrderSide, USER_ORDERS_TABS } from 'js/constants/trades'
import { SmallArrow } from 'js/shared-components/SmallArrow'
import { Backdrop } from 'js/shared-components/Backdrop'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'

import { ActiveOrderTable } from 'js/shared-components/tables/ActiveOrderTable'
import { TradeHistoryTable } from 'js/shared-components/tables/TradeHistoryTable'
import { OrderHistoryTable } from 'js/shared-components/tables/OrderHistoryTable'
import { PositionsTable } from 'js/shared-components/tables/PositionsTable'
import { useChangeMarket, useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { TOKEN_LOGOS } from 'js/constants/shared'
import { SelectorDropdown } from 'js/shared-components/SelectorDropdown'
import { OrderListContainer } from '../styles'
import TradeTablesBlocker from './TradeTablesBlocker'
import { useAccountIndex } from 'js/providers/user-store'
import cn from 'js/util/cn'
import type { OrderBookDetail } from 'zklighter-perps'
import { useIsRegisteredQuery } from 'js/providers/hooks/useAccountQuery'
import { useResponsiveness } from 'js/ResponsivenessProvider'

type TabType = keyof typeof USER_ORDERS_TABS

export const UserOrders = () => {
  const accountIndex = useAccountIndex()
  const changeMarket = useChangeMarket()
  const [selectedTab, setSelectedTab] = useState<TabType>('positions')
  const [selectedSide, setSelectedSide] = useState<OrderSide>('all')
  const [isSelectorOpen, setIsSelectorOpen] = useState(false)
  const [selectedAllMarkets, setSelectedAllMarkets] = useState(true)
  const [indicatorLeft, setIndicatorLeft] = useState(12)
  const [indicatorWidth, setIndicatorWidth] = useState(56)
  const { isMobile } = useResponsiveness()

  const isRegistered = useIsRegisteredQuery().data
  const setSelectedMarket = (market: OrderBookDetail | null) => {
    if (!market) {
      setSelectedAllMarkets(true)
    } else {
      changeMarket(market.market_id)
    }
  }
  const currentMarket = useCurrentMarket()
  const useRefs = () => {
    const refsByKey = useRef<Record<string, HTMLElement | null>>({})

    const setRef = (element: HTMLElement | null, key: number) => {
      refsByKey.current[key] = element
    }

    return { refsByKey: refsByKey.current, setRef }
  }

  const marketOptions = [
    { key: 'all', title: 'All', onClick: () => setSelectedAllMarkets(true), icon: 'coin' as const },
    {
      key: currentMarket.symbol,
      title: currentMarket.symbol,
      onClick: () => setSelectedAllMarkets(false),
      icon: TOKEN_LOGOS[currentMarket.symbol as keyof typeof TOKEN_LOGOS],
    },
  ]
  const sideOptions = Object.entries(ORDER_SIDE_TABS).map(([side, { title, icon }]) => ({
    key: side,
    title,
    icon,
    onClick: () => setSelectedSide(side as OrderSide),
  }))
  const { refsByKey, setRef } = useRefs()

  const refs = Object.values(refsByKey).filter(Boolean)

  const selectedTabIndex = Object.keys(USER_ORDERS_TABS).findIndex((tab) => tab === selectedTab)!

  useEffect(() => {
    if (refs[selectedTabIndex]) {
      const rect = refs[selectedTabIndex]!.getBoundingClientRect()
      setIndicatorLeft(refs[selectedTabIndex]!.offsetLeft)
      setIndicatorWidth(rect.width)
    }
  }, [selectedTabIndex, refs[selectedTabIndex]])

  return (
    <div className="relative flex size-full flex-col justify-start max-tablet:min-h-[300px]">
      <div className="flex h-10 w-full items-center justify-between border-b bg-white-transparent max-mobile:border-t max-mobile:px-3">
        {!isMobile && (
          <div className="relative flex gap-6 px-3">
            <SelectorIndicator
              left={indicatorLeft}
              width={indicatorWidth}
              numButtons={Object.values(USER_ORDERS_TABS).length}
              selectedElemIdx={selectedTabIndex}
            />
            {Object.entries(USER_ORDERS_TABS).map(([tab, { title }], index) => (
              <TabButton
                className={cn({ 'text-white': selectedTab === tab })}
                setRef={setRef}
                id={index}
                key={title}
                isSelected={false}
                onClick={() => setSelectedTab(tab as TabType)}
              >
                {title}
              </TabButton>
            ))}
          </div>
        )}
        {isMobile && (
          <>
            <Backdrop
              fullScreen
              isVisible={isSelectorOpen}
              onClick={() => setIsSelectorOpen(false)}
            />
            <div>
              <div
                className="flex items-center gap-2"
                onClick={() => setIsSelectorOpen((prev) => !prev)}
              >
                <TabButton isSelected={false}>
                  <p className="typography-label-1 text-white">
                    {USER_ORDERS_TABS[selectedTab].title}
                  </p>
                </TabButton>
                <SmallArrow direction="down" />
              </div>
              <BottomAnimatedDropdown isOpen={isSelectorOpen} className="z-[101]">
                {Object.entries(USER_ORDERS_TABS).map(([tab, { title }]) => (
                  <TabButton
                    className={cn('h-[unset] py-4', {
                      'text-white': selectedTab === tab,
                      'text-white-opaque': selectedTab !== tab,
                    })}
                    key={title}
                    isSelected={false}
                    onClick={() => {
                      setSelectedTab(tab as TabType)
                      setIsSelectorOpen(false)
                    }}
                  >
                    <p
                      className={cn('typography-label-1', {
                        'text-white': selectedTab === tab,
                        'text-white-opaque': selectedTab !== tab,
                      })}
                    >
                      {title}
                    </p>
                  </TabButton>
                ))}
              </BottomAnimatedDropdown>
            </div>
          </>
        )}

        <div className="flex items-center gap-2 pr-3 max-mobile:pr-0">
          <SelectorDropdown
            options={marketOptions}
            selectedOption={!selectedAllMarkets ? currentMarket.symbol : 'all'}
          />
          <SelectorDropdown options={sideOptions} selectedOption={selectedSide} />
        </div>
      </div>
      <OrderListContainer>
        <TradeTablesBlocker>
          {selectedTab === 'positions' && (
            <PositionsTable
              accountIndex={accountIndex!}
              showCloseColumn={!!isRegistered}
              selectedMarket={selectedAllMarkets ? null : currentMarket}
              setSelectedMarket={setSelectedMarket}
              selectedSide={selectedSide}
            />
          )}
          {selectedTab === 'activeOrders' && (
            <ActiveOrderTable
              accountIndex={accountIndex!}
              showCancelColumn
              selectedMarket={selectedAllMarkets ? null : currentMarket}
              setSelectedMarket={setSelectedMarket}
              selectedSide={selectedSide}
            />
          )}
          {selectedTab === 'orderHistory' && (
            <OrderHistoryTable
              accountIndex={accountIndex!}
              selectedMarket={selectedAllMarkets ? null : currentMarket}
              setSelectedMarket={setSelectedMarket}
              selectedSide={selectedSide}
            />
          )}
          {selectedTab === 'tradeHistory' && (
            <TradeHistoryTable
              accountIndex={accountIndex!}
              selectedMarket={selectedAllMarkets ? null : currentMarket}
              setSelectedMarket={setSelectedMarket}
              selectedSide={selectedSide}
            />
          )}
        </TradeTablesBlocker>
      </OrderListContainer>
    </div>
  )
}
