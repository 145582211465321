import { create } from 'zustand'
import { createSelector } from 'reselect'
import { type WsMarketStats, type WsOrderbook } from './orderBookWorker/types'
import { type Trade } from './types'

interface OrderBookState {
  marketsStats: Record<string, WsMarketStats>
  trades: Trade[] | null
  orderBook: WsOrderbook | null
  height: number | null
}

export const useOrderBookStore = create<OrderBookState>(() => ({
  marketsStats: {},
  trades: null,
  orderBook: null,
  height: null,
}))

const selectOrderBook = (state: OrderBookState) => state.orderBook
export const useOrderBook = () => useOrderBookStore(selectOrderBook)
const selectPrunedOrderBook = createSelector([selectOrderBook], (orderBook) => ({
  asks: (orderBook?.asks ?? []).slice(0, 15),
  bids: (orderBook?.bids ?? []).slice(0, 15),
}))
export const usePrunedOrderbook = () => useOrderBookStore(selectPrunedOrderBook)

const selectMaxTotalSize = createSelector([selectPrunedOrderBook], ({ asks, bids }) =>
  Math.max(
    asks.reduce((sum, ask) => sum + Number(ask.size), 0),
    bids.reduce((sum, bid) => sum + Number(bid.size), 0),
  ),
)
export const useMaxTotalSize = () => useOrderBookStore(selectMaxTotalSize)

const selectMidPrice = createSelector([selectPrunedOrderBook], ({ asks, bids }) => {
  if (!asks.length && !bids.length) {
    return 0
  }

  if (!asks.length) {
    return Number(bids[0]!.price)
  }

  if (!bids.length) {
    return Number(asks[0]!.price)
  }

  return (Number(asks[0]!.price) + Number(bids[0]!.price)) / 2
})
export const useMidPrice = () => useOrderBookStore(selectMidPrice)

const selectSpread = createSelector(
  [selectPrunedOrderBook],
  ({ asks, bids }) => Number(asks[0]?.price ?? 0) - Number(bids[0]?.price ?? 0),
)
export const useSpread = () => useOrderBookStore(selectSpread)

const selectDepthChartData = createSelector(
  [selectOrderBook, selectMidPrice],
  (orderBook, midPrice) => {
    const depthChartData = {
      prices: [] as number[],
      asksDepth: [] as number[],
      bidsDepth: [] as number[],
      maxDepth: 0,
    }

    if (!orderBook) {
      return depthChartData
    }

    const { bids, asks } = orderBook

    const depthChartBids = bids
      .map((bid) => ({ price: Number(bid.price), size: Number(bid.size) }))
      .filter((bid) => bid.price >= midPrice * 0.9)
    const depthChartAsks = asks
      .map((ask) => ({ price: Number(ask.price), size: Number(ask.size) }))
      .filter((ask) => ask.price <= midPrice * 1.1)
    const distance = Math.max(
      (depthChartAsks.at(-1)?.price ?? 0) - midPrice,
      midPrice - (depthChartBids.at(-1)?.price ?? 0),
    )

    if (bids.length !== 0 && midPrice) {
      const minPrice = midPrice - distance
      const bids = accumulate(
        depthChartBids.filter((r) => r.price >= minPrice),
        minPrice,
        'bids',
      )
      depthChartData.prices = bids.map((r) => r.x)
      depthChartData.bidsDepth = bids.map((r) => r.y)
    }

    if (asks.length !== 0 && midPrice) {
      const maxPrice = midPrice + distance
      const asks = accumulate(
        depthChartAsks.filter((r) => r.price <= maxPrice),
        maxPrice,
        'asks',
      )
      depthChartData.prices = depthChartData.prices.concat(asks.map((r) => r.x))
      depthChartData.asksDepth = (
        Array.from({ length: depthChartData.prices.length - asks.length }) as number[]
      ).concat(asks.map((r) => r.y))
    }

    const maxDepthSum = Math.max(
      asks.reduce((acc, row) => acc + Number(row.size), 0),
      bids.reduce((acc, row) => acc + Number(row.size), 0),
    )
    depthChartData.maxDepth = maxDepthSum > 1 ? Math.ceil(maxDepthSum) : maxDepthSum

    return depthChartData
  },
)
export const useDepthChartData = () => useOrderBookStore(selectDepthChartData)

const accumulate = (
  orders: Array<{ price: number; size: number }>,
  basePoint: number,
  type: 'asks' | 'bids',
) => {
  if (orders.length === 0) {
    return []
  }
  const accumulated: Array<{ x: number; y: number }> = []
  let sum = 0

  for (let i = 0; i < orders.length; i += 1) {
    sum = sum + orders[i]!.size

    accumulated.push({ x: orders[i]!.price, y: sum })
  }

  if (basePoint < orders[orders.length - 1]!.price) {
    accumulated.push({ x: basePoint, y: sum })
  } else if (basePoint > orders[0]!.price) {
    accumulated.unshift({ x: basePoint, y: sum })
  }

  const sorted = accumulated.toSorted((a, b) => a.x - b.x)

  return type === 'bids'
    ? [...sorted, { x: sorted[sorted.length - 1]!.x, y: 0 }]
    : [{ x: sorted[0]!.x, y: 0 }, ...sorted]
}

const selectMarketsStats = (state: OrderBookState) => state.marketsStats
export const useMarketsStats = () => useOrderBookStore(selectMarketsStats)

const selectBlockHeight = (state: OrderBookState) => state.height
export const useBlockHeight = () => useOrderBookStore(selectBlockHeight)

const selectOrderBookTrades = (state: OrderBookState) => state.trades
export const useOrderBookTrades = () => useOrderBookStore(selectOrderBookTrades)
