import cn from 'js/util/cn'

export const CheckboxSelector = ({ isSelected }: { isSelected: boolean }) => (
  <span className="relative size-5 cursor-pointer rounded-md border bg-black-darkest">
    <span
      className={cn(
        'absolute left-[7px] top-1 h-2 w-[5px] rounded-[1px] border-b-2 border-r-2 border-[#F7F7F7] transition',
        {
          'opacity-100 scale-100 rotate-[40deg]': isSelected,
          'opacity-0 scale-0 rotate-0': !isSelected,
        },
      )}
    />
  </span>
)
