import { getYourDeposits, usePublicPoolQuery } from './utils'
import {
  useAccountStats,
  usePublicPoolInfo,
  useUserAccountShares,
} from 'js/providers/accounts-store'
import { formatUSD } from 'js/util/formatting'

const PublicPoolStats = () => {
  const publicPoolQuery = usePublicPoolQuery()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)
  const publicPoolPortfolioValue = useAccountStats(publicPoolQuery.data.index).portfolio_value
  const shares = useUserAccountShares()

  return (
    <div className="flex justify-between gap-32 max-mobile:gap-0">
      <div>
        <p className="typography-body-2 text-white">TVL</p>
        <p className="typography-text-8 text-white">
          {publicPoolPortfolioValue === null ? '-' : formatUSD(Number(publicPoolPortfolioValue))}
        </p>
      </div>
      <div>
        <p className="typography-body-2 text-white">Your Deposits</p>
        <p className="typography-text-8 text-white">
          {getYourDeposits(
            shares,
            publicPoolQuery.data.index,
            Number(publicPoolPortfolioValue),
            poolInfo?.total_shares ?? publicPoolQuery.data.pool_info.total_shares,
          )}
        </p>
      </div>
    </div>
  )
}

export default PublicPoolStats
