import { useMemo } from 'react'

import Table from 'js/shared-components/uikit/table/Table'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import TableCell from 'js/shared-components/uikit/table/TableCell'

import { useAccountFundingHistory, useAccountLoading } from 'js/providers/accounts-store'
import { type OrderSide } from 'js/constants/trades'
import DateCell from 'js/shared-components/cells/DateCell'
import SideCell from 'js/shared-components/cells/SideCell'
import SymbolCell from 'js/shared-components/cells/funding-history/SymbolCell'
import SizeCell from 'js/shared-components/cells/SizeCell'
import FundingRateCell from 'js/shared-components/cells/funding-history/FundingRateCell'
import RateChangeCell from 'js/shared-components/cells/funding-history/RateChangeCell'
import MarketCell from 'js/shared-components/cells/MarketCell'
import { NoItemsInMarket } from './NoItemsInMarket'
import { NoOrdersText } from './NoOrdersText'
import HeaderCell from 'js/shared-components/HeaderCell'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'
import type { OrderBookDetail } from 'zklighter-perps'

interface FundingHistoryTableHeaderProps {
  showMarketColumn: boolean
}

const FundingHistoryTableHeader = ({ showMarketColumn }: FundingHistoryTableHeaderProps) => (
  <TableHeader>
    <TableHeaderRow>
      {showMarketColumn && <HeaderCell title="Name" />}
      <HeaderCell title="Time" />
      <HeaderCell title="Token" />
      <HeaderCell title="Position Side" />
      <HeaderCell title="Position Size" />
      <HeaderCell title="Payment" />
      <HeaderCell title="Rate" />
    </TableHeaderRow>
  </TableHeader>
)

interface FundingHistoryProps {
  accountIndex: number
  selectedSide?: OrderSide
  selectedMarket?: OrderBookDetail | null
  setSelectedMarket?: (selectedMarket: OrderBookDetail | null) => void
}

export const FundingHistoryTable = ({
  accountIndex,
  selectedSide = 'all',
  selectedMarket = null,
  setSelectedMarket = () => {},
}: FundingHistoryProps) => {
  const fundingHistory = useAccountFundingHistory(accountIndex)
  const arePaymentsLoading = useAccountLoading(accountIndex)
  const showMarketColumn = !selectedMarket

  const paymentsToDisplay = useMemo(
    () =>
      (selectedMarket
        ? fundingHistory[selectedMarket.market_id] ?? []
        : Object.values(fundingHistory).flat()
      ).filter((payment) => {
        switch (selectedSide) {
          case 'asks': {
            return payment.position_side === 'Long'
          }
          case 'bids': {
            return payment.position_side !== 'Long'
          }
          case 'all':
          default: {
            return true
          }
        }
      }),
    [selectedMarket, selectedSide, fundingHistory],
  )

  if (arePaymentsLoading) {
    return (
      <Table>
        <FundingHistoryTableHeader showMarketColumn={showMarketColumn} />
        <TableBody>
          {Array(4)
            .fill(null)
            .map((_, i) => (
              <TableRow key={i}>
                {new Array(6 + Number(showMarketColumn)).fill(null).map((_, j) => (
                  <TableCell key={j} className="h-6 p-2">
                    <SkeletonRectangle />
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    )
  }

  if (!Object.values(fundingHistory).flat().length) {
    return <NoOrdersText type="fundingHistory" />
  }

  if (selectedMarket && !fundingHistory[selectedMarket.market_id]?.length) {
    return <NoItemsInMarket type="fundingHistory" buttonOnClick={() => setSelectedMarket(null)} />
  }

  return (
    <Table>
      <FundingHistoryTableHeader showMarketColumn={showMarketColumn} />
      <TableBody>
        {paymentsToDisplay.map((item) => (
          <TableRow key={item.funding_id}>
            {showMarketColumn && <MarketCell marketIndex={item.market_id} />}
            <DateCell timestamp={item.timestamp} />
            <SymbolCell funding={item} />
            <SideCell is_short={item.position_side === 'short'} />
            <SizeCell size={item.position_size} marketIndex={item.market_id} />
            <FundingRateCell funding={item} />
            <RateChangeCell funding={item} />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
