import type { Order } from 'js/providers/types'
import { useMutation } from '@tanstack/react-query'
import { cancelOrder } from 'js/zklighter-js-sdk/sdk'
import { useAccountIndex } from 'js/providers/user-store'
import Icon from 'js/shared-components/uikit/Icon'
import { Clickable } from 'js/shared-components/Clickable'
import { toast } from 'sonner'
import Toast from 'js/shared-components/uikit/Toast'

interface CancelOrderButtonProps {
  order: Order
}

const CancelOrderButton = ({ order }: CancelOrderButtonProps) => {
  const accountIndex = useAccountIndex()

  const cancelOrderMutation = useMutation({
    mutationFn: (order: Order) =>
      cancelOrder({
        accountIndex: accountIndex!,
        marketIndex: order.market_index,
        orderIndex: order.order_index,
      }),
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Order canceled"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
  })

  const isLoading = cancelOrderMutation.isPending && !cancelOrderMutation.isError

  return (
    <Clickable color="red" onClick={() => cancelOrderMutation.mutate(order)} disabled={isLoading}>
      <Icon icon={isLoading ? 'spinner' : 'x'} className="size-4" />
    </Clickable>
  )
}

export default CancelOrderButton
