import TableCell from 'js/shared-components/uikit/table/TableCell'
import { type PublicPool } from 'zklighter-perps'

interface PublicPoolOperatorFeeCellProps {
  publicPool: PublicPool
}

const PublicPoolOperatorFeeCell = ({ publicPool }: PublicPoolOperatorFeeCellProps) => (
  <TableCell>
    <p className="typography-body-1 text-end text-white">
      {Number(publicPool.pool_info.operator_fee)}%
    </p>
  </TableCell>
)

export default PublicPoolOperatorFeeCell
