import { useAccountIndex } from 'js/providers/user-store'
import { usePublicPoolQuery, usePublicPoolSharePrice } from '../utils'
import { useMutation } from '@tanstack/react-query'
import { mintShares } from 'js/zklighter-js-sdk/sdk'
import { Button } from 'js/shared-components'
import { useState, type ChangeEvent } from 'react'
import { usePublicPoolInfo, useUserAccountStats } from 'js/providers/accounts-store'
import CurrencyInput from 'js/shared-components/uikit/CurrencyInput'
import { formatUSD } from 'js/util/formatting'
import { useAccountsQuery } from 'js/providers/hooks/useAccountQuery'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import { toast } from 'sonner'
import Toast from 'js/shared-components/uikit/Toast'

const useAvailableSharesToMint = () => {
  const accountsQuery = useAccountsQuery()
  const publicPoolQuery = usePublicPoolQuery()
  const { available_balance } = useUserAccountStats()
  const publicPoolSharePrice = usePublicPoolSharePrice()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)

  // catch all for loading state
  if (
    publicPoolSharePrice === null ||
    !poolInfo ||
    !accountsQuery.data ||
    available_balance === null
  ) {
    return 0
  }

  const availableBalance = Number(available_balance)

  const isOperator = accountsQuery.data.accounts.some(
    (account) => account.index === publicPoolQuery.data.index,
  )

  // operator_share can only increase if the operator is depositing
  if (isOperator) {
    return Math.floor(availableBalance / publicPoolSharePrice)
  }

  // coming from BE as '5.000' -> 5% and needs to be converted to 0.05
  const minOperatorShareRate = Number(poolInfo.min_operator_share_rate) / 100

  /*
    min_operator_share_rate <= operator_shares / (total_shares + delta)
    min_operator_share_rate * (total_shares + delta) <= operator_shares
    total_shares + delta <= operator_shares / min_operator_share_rate
    delta <= (operator_shares / min_operator_share_rate) - total_shares
  */
  return Math.floor(
    Math.min(
      availableBalance / publicPoolSharePrice,
      poolInfo.operator_shares / minOperatorShareRate - poolInfo.total_shares,
    ),
  )
}

const DepositForm = () => {
  const accountsQuery = useAccountsQuery()
  const accountIndex = useAccountIndex()!
  const publicPoolQuery = usePublicPoolQuery()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)
  const publicPoolSharePrice = usePublicPoolSharePrice()
  const [value, setValue] = useState('')
  const { available_balance } = useUserAccountStats()
  const mintSharesMutation = useMutation({
    mutationFn: mintShares,
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Successfully deposited funds"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => setValue(''),
  })
  const availableToMintShares = useAvailableSharesToMint()
  const availableToDeposit = availableToMintShares * (publicPoolSharePrice ?? 0)

  const isOperator =
    accountsQuery.data?.accounts.some((account) => account.index === publicPoolQuery.data.index) ??
    false

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/,/g, '.').replace(/^0+(?=[0-9])/, '')

    if (newValue === '') {
      return setValue(newValue)
    }

    if (!/^[0-9]*\.?[0-9]{0,2}$/.test(newValue)) {
      return
    }

    if (Number(newValue) > availableToDeposit) {
      return setValue(availableToDeposit.toFixed(2))
    }

    setValue(newValue)
  }

  if (!poolInfo) {
    return null
  }

  if (poolInfo.status === 1) {
    return (
      <p className="typography-body-2 text-white">
        This public pool is frozen, deposits are not allowed anymore.
      </p>
    )
  }

  return (
    <div className="flex h-full flex-col justify-between gap-16">
      <div className="flex flex-col gap-2">
        <p className="typography-body-2 text-white">Deposit funds</p>
        <CurrencyInput
          label="Amount"
          value={value}
          onChange={onInputChange}
          symbol="USDC"
          placeholder={'0.00'}
        />
        <div className="flex items-center justify-between">
          <p className="typography-body-1 text-white">Available to deposit</p>
          <div className="flex items-center gap-2">
            <p className="typography-body-1 text-white">{formatUSD(availableToDeposit)}</p>
            {!isOperator &&
              available_balance !== null &&
              availableToDeposit + 1 <= Number(available_balance) && (
                <Explanation
                  explanation={`Because of the size of the pool, you can only deposit up to ${formatUSD(
                    availableToDeposit,
                  )}.`}
                />
              )}
          </div>
        </div>
      </div>
      <Button
        className="w-full"
        disabled={!value}
        isLoading={mintSharesMutation.isPending || publicPoolSharePrice === null}
        onClick={() =>
          mintSharesMutation.mutate({
            accountIndex,
            publicPoolIndex: publicPoolQuery.data.index,
            shareAmount: Math.min(
              availableToMintShares,
              Math.floor(Number(value) / (publicPoolSharePrice as number)),
            ),
          })
        }
      >
        Deposit
      </Button>
    </div>
  )
}

export default DepositForm
