import { Button } from 'js/shared-components'
import { Backdrop } from 'js/shared-components/Backdrop'
import { Modal } from 'js/shared-components/Modal'
import { accountApi } from 'js/util/api/sdk'
import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { useUserAccountStats } from 'js/providers/accounts-store'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'
import Toast from './uikit/Toast'

interface FaucetModalProps {
  isOpen: boolean
  closeModal: () => void
}

export const FaucetModal = ({ isOpen, closeModal }: FaucetModalProps) => {
  const userAddress = useUserAddress()
  const { portfolio_value } = useUserAccountStats()
  const canRequestFunds = Number(portfolio_value) <= 100

  const faucetMutation = useMutation({
    mutationFn: () => accountApi.faucet({ l1_address: userAddress }),
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Funds received"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => closeModal(),
  })

  return (
    <>
      <Backdrop fullScreen isVisible={isOpen} />
      <Modal modalTitle="Request Funds" onClose={closeModal} isOpen={isOpen}>
        <div className="flex w-full flex-col gap-3">
          <p className="typography-label-1 text-white">
            {canRequestFunds
              ? 'Your account will be credited with test funds'
              : 'You can request more funds when your total portfolio value is below 100 USD'}
          </p>
          <Button
            disabled={!canRequestFunds}
            isLoading={faucetMutation.isPending}
            className="w-full"
            onClick={() => faucetMutation.mutate()}
          >
            Request Funds
          </Button>
        </div>
      </Modal>
    </>
  )
}
