import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatUSD } from 'js/util/formatting'
import { type PublicPool } from 'zklighter-perps'

interface PublicPoolTVLCellProps {
  publicPool: PublicPool
}

const PublicPoolTVLCell = ({ publicPool }: PublicPoolTVLCellProps) => (
  <TableCell>
    <p className="typography-body-1 text-white">
      {formatUSD(Number(publicPool.total_asset_value))}
    </p>
  </TableCell>
)

export default PublicPoolTVLCell
