import { create } from 'zustand'
import { type PortfolioStats, type AccountsState } from './types'
import { useAccountIndex } from './user-store'
import { createSelector } from 'reselect'

export const useAccountsStore = create<AccountsState>(() => ({
  accounts: {},
  portfolioStats: {},
  poolInfo: {},
}))

const selectAccounts = (state: AccountsState) => state.accounts

const selectAccount = (accountIndex: number) =>
  createSelector([selectAccounts], (accounts) => accounts[accountIndex])

const selectAccountLoading = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account === undefined)
export const useAccountLoading = (accountIndex: number) =>
  useAccountsStore(selectAccountLoading(accountIndex))
export const useUserAccountLoading = () => {
  const accountIndex = useAccountIndex()

  return useAccountLoading(accountIndex ?? -1)
}

const selectAccountTrades = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.trades ?? {})
export const useAccountTrades = (accountIndex: number) =>
  useAccountsStore(selectAccountTrades(accountIndex))

const selectAccountActiveOrders = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.activeOrders ?? {})
export const useAccountActiveOrders = (accountIndex: number) =>
  useAccountsStore(selectAccountActiveOrders(accountIndex))
export const useUserAccountActiveOrders = () => {
  const accountIndex = useAccountIndex()

  return useAccountActiveOrders(accountIndex ?? -1)
}

const selectAccountInactiveOrders = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.inactiveOrders ?? {})
export const useAccountInactiveOrders = (accountIndex: number) =>
  useAccountsStore(selectAccountInactiveOrders(accountIndex))
export const useUserAccountInactiveOrders = () => {
  const accountIndex = useAccountIndex()

  return useAccountInactiveOrders(accountIndex ?? -1)
}

const selectAccountPositions = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.positions ?? {})
export const useAccountPositions = (accountIndex: number) =>
  useAccountsStore(selectAccountPositions(accountIndex))
export const useUserAccountPositions = () => {
  const accountIndex = useAccountIndex()

  return useAccountPositions(accountIndex ?? -1)
}

// TODO: this shouldn't be a state imo
const selectAccountLiquidations = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.liquidations ?? [])
export const useUserAccountLiquidations = () => {
  const accountIndex = useAccountIndex()

  return useAccountsStore(selectAccountLiquidations(accountIndex ?? -1))
}

const selectAccountShares = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.shares ?? [])
const useAccountShares = (accountIndex: number) =>
  useAccountsStore(selectAccountShares(accountIndex))
export const useUserAccountShares = () => {
  const accountIndex = useAccountIndex()

  return useAccountShares(accountIndex ?? -1)
}

const selectAccountFundingHistory = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.fundingHistory ?? {})
export const useAccountFundingHistory = (accountIndex: number) =>
  useAccountsStore(selectAccountFundingHistory(accountIndex))

const DEFAULT_PORTFOLIO_STATS: PortfolioStats = {
  portfolio_value: null,
  leverage: null,
  available_balance: null,
  margin_usage: null,
}

const selectPortfolioStats = (state: AccountsState) => state.portfolioStats

const selectAccountStats = (accountIndex: number) =>
  createSelector(
    [selectPortfolioStats],
    (portfolioStats) => portfolioStats[accountIndex] ?? DEFAULT_PORTFOLIO_STATS,
  )
export const useAccountStats = (accountIndex: number) =>
  useAccountsStore(selectAccountStats(accountIndex))
export const useUserAccountStats = (): PortfolioStats => {
  const accountIndex = useAccountIndex()

  return useAccountStats(accountIndex ?? -1)
}

const selectPublicPoolInfos = (state: AccountsState) => state.poolInfo

const selectPublicPoolInfo = (publicPoolIndex: number) =>
  createSelector([selectPublicPoolInfos], (poolInfos) => poolInfos[publicPoolIndex])
export const usePublicPoolInfo = (publicPoolIndex: number) =>
  useAccountsStore(selectPublicPoolInfo(publicPoolIndex))
