import { formatDate } from 'date-fns'
import type { Order } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface OrderExpiryCellProps {
  order: Order
}

const OrderExpiryCell = ({ order }: OrderExpiryCellProps) => (
  <TableCell>
    <p className="typography-body-1 text-end text-white">
      {formatDate(new Date(order.order_expiry), 'MM-dd HH:mm:ss')}
    </p>
  </TableCell>
)

export default OrderExpiryCell
