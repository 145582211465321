import cn from 'js/util/cn'

interface ToggleSwitchProps {
  onClick: () => void
  isOn: boolean
}

export const ToggleSwitch = ({ isOn, onClick }: ToggleSwitchProps) => (
  <button onClick={onClick}>
    <div
      className={cn('h-6 w-10 max-w-10 rounded-2xl border p-px', {
        'bg-primary-blue-pale': isOn,
        'bg-black-darkest': !isOn,
      })}
    >
      <div
        className={cn('size-5 rounded-full transition-all', {
          'bg-primary-blue-main translate-x-4': isOn,
          'bg-grey-light': !isOn,
        })}
      />
    </div>
  </button>
)
