import { SubAccountType } from 'js/providers/types'
import type { DetailedAccount } from 'zklighter-perps'
import { formatUSD } from 'js/util/formatting'
import cn from 'js/util/cn'
import Icon from 'js/shared-components/uikit/Icon'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'js/shared-components/uikit/DropdownMenu'

export const AccountSelector = ({
  accountsList,
  onAccountChange,
  selectedAccountIndex,
  direction,
}: {
  accountsList: DetailedAccount[]
  onAccountChange: (idx: number) => void
  selectedAccountIndex: number | null
  direction: 'to' | 'from'
}) => (
  <div className="flex flex-col gap-1">
    <p className="typography-label-1 text-white">Transfer {direction === 'to' ? 'to' : 'from'}</p>
    <DropdownMenu>
      <DropdownMenuTrigger>
        {selectedAccountIndex !== null
          ? `Sub-account index ${selectedAccountIndex}`
          : 'Select a sub-account'}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {accountsList.map((account) => (
          <DropdownMenuItem
            key={account.index}
            onClick={() => onAccountChange(account.index)}
            className="flex items-center justify-between"
          >
            <div className="flex flex-col items-start gap-1">
              <p className="typography-label-1 text-white">
                {account.account_type === SubAccountType.main
                  ? 'Main Account'
                  : account.name || `Account Index: ${account.index}`}
              </p>
              <p className="typography-body-1 text-white">
                Available: {formatUSD(Number(account.collateral))}
              </p>
            </div>
            <Icon
              icon="check"
              className={cn('size-4 rounded-full', {
                'border border-green-main text-green-main': selectedAccountIndex === account.index,
                'border-2 border-grey-light text-grey-light':
                  selectedAccountIndex !== account.index,
              })}
            />
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
)
