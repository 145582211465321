import * as RadixCollapsible from '@radix-ui/react-collapsible'
import cn from 'js/util/cn'
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react'

export const CollapsibleContent = forwardRef<
  ElementRef<typeof RadixCollapsible.Content>,
  ComponentPropsWithoutRef<typeof RadixCollapsible.Content>
>(({ className, ...rest }, forwardedRef) => (
  <RadixCollapsible.Content
    className={cn(
      'overflow-hidden data-[state=closed]:animate-[collapsibleSlideUp_150ms_ease-out] data-[state=open]:animate-[collapsibleSlideDown_150ms_ease-out]',
      className,
    )}
    ref={forwardedRef}
    {...rest}
  />
))

CollapsibleContent.displayName = 'CollapsibleContent'
