import { useEffect } from 'react'

import { AllOrders } from 'js/pages/trade/components/orders/all-orders'
import { UserOrders } from 'js/pages/trade/components/orders/user-orders/'
import { InfoBox } from 'js/pages/trade/components/info-box/InfoBox'
import { Chart } from 'js/pages/trade/components/chart'

import { PageContainer } from '../page-container/PageContainer'

import { PlaceOrder } from './components/place-order-panel/PlaceOrder'
import { useShowOnboarding } from 'js/providers/user-store'
import { ConnectWalletModal } from 'js/shared-components/ConnectWalletModal'
import { LiquidationNotifications } from './components/place-order-panel/components/LiquidationNotifications'

const DesktopViewTrade = () => {
  const showOnboarding = useShowOnboarding()

  useEffect(() => {
    window.hj('trigger', 'trade page')
  }, [])

  return (
    <PageContainer>
      <LiquidationNotifications />
      {showOnboarding && <ConnectWalletModal />}
      <div className="flex size-full flex-col overflow-hidden p-2 pt-0">
        <div className="relative z-[3] w-full">
          <InfoBox />
        </div>
        <div className="grid h-[calc(100%-57px)] grid-cols-[[col1]_1fr_[col2]_minmax(200px,350px)] gap-2 overflow-y-hidden">
          <div className="col-start-1 row-start-1 grid max-h-full grid-cols-[[col1]_1fr_[col2]_minmax(200px,_330px)] grid-rows-[65%_35%] overflow-y-hidden rounded-xl border">
            <div className="col-start-[col1] row-start-1 size-full overflow-y-hidden border-b border-r">
              <Chart />
            </div>
            <div className="relative col-start-[col1] size-full overflow-y-hidden border-r">
              <UserOrders />
            </div>
            <div className="col-start-[col2] row-start-1 size-full overflow-y-hidden border-b">
              <AllOrders type="orderBook" />
            </div>
            <div className="col-start-[col2] size-full overflow-hidden">
              <AllOrders type="trades" />
            </div>
          </div>
          <div className="col-start-[col2] row-start-1 max-h-full overflow-y-hidden rounded-xl border">
            <PlaceOrder />
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default DesktopViewTrade
