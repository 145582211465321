import { create } from 'zustand'

interface UserState {
  accountIndex: number | null
  showOnboarding: boolean
}

export const useUserStore = create<UserState>(() => ({
  accountIndex: null,
  showOnboarding: false,
}))

const selectAccountIndex = (state: UserState) => state.accountIndex
export const useAccountIndex = () => useUserStore(selectAccountIndex)

const selectShowOnboarding = (state: UserState) => state.showOnboarding
export const useShowOnboarding = () => useUserStore(selectShowOnboarding)
