import * as RadixPopover from '@radix-ui/react-popover'
import cn from 'js/util/cn'
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react'

export const PopoverContent = forwardRef<
  ElementRef<typeof RadixPopover.Content>,
  ComponentPropsWithoutRef<typeof RadixPopover.Content>
>(({ sideOffset = 8, className, ...rest }, forwardedRef) => (
  <RadixPopover.Content
    ref={forwardedRef}
    sideOffset={sideOffset}
    className={cn(
      'z-[100] overflow-hidden rounded-lg border bg-black shadow-popover',
      'data-[state=closed]:animate-[fadeOut_150ms_ease-out] data-[state=open]:animate-[fadeIn_150ms_ease-out]',
      className,
    )}
    {...rest}
  />
))

PopoverContent.displayName = 'PopoverContent'
