import { useEffect, useRef, useState } from 'react'

import { type OrderSide } from 'js/constants/trades'
import { USDC_SYMBOL } from 'js/constants/shared'

import { OrderBookItem } from '../components/OrderBookItem'
import { OrderBookList } from '../components/OrderBookList'
import {
  useMaxTotalSize,
  useMidPrice,
  useOrderBook,
  usePrunedOrderbook,
  useSpread,
} from 'js/providers/order-book-store'
import { useCurrentMarket, useCurrentMarketId } from 'js/providers/hooks/order-book-metas-hooks'
import { SelectorDropdown } from 'js/shared-components/SelectorDropdown'
import HeaderCell from 'js/shared-components/HeaderCell'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'
import { useOrderInputStore } from '../../place-order-panel/PlaceOrder'
import { useResponsiveness } from 'js/ResponsivenessProvider'

interface OrderBookProps {
  selectedSide?: OrderSide
}

export const OrderBook = ({ selectedSide }: OrderBookProps) => {
  const { isMobile, isTablet } = useResponsiveness()
  const [isSizeInUsd, setIsSizeInUsd] = useState(false)
  const [isTotalSelected, setIsTotalSelected] = useState(false)

  const isOrderBookLoading = useOrderBook() === null

  const { asks, bids } = usePrunedOrderbook()
  const maxTotalSize = useMaxTotalSize()
  const spread = useSpread()
  const spreadPercentage = spread === 0 ? 0 : (spread / Number(asks[0]?.price ?? 0)) * 100
  const currentMarket = useCurrentMarket()
  const currentMarketId = useCurrentMarketId()
  const symbol = currentMarket.symbol
  const alreadyScrolled = useRef(false)
  const spreadRef = useRef<HTMLDivElement>(null)

  const sizeOptions = [
    { key: symbol, title: symbol, onClick: () => setIsSizeInUsd(false) },
    { key: USDC_SYMBOL, title: USDC_SYMBOL, onClick: () => setIsSizeInUsd(true) },
  ]

  const sizeOrTotalOptions = [
    { key: 'size', title: 'Size', onClick: () => setIsTotalSelected(false) },
    { key: 'total', title: 'Total', onClick: () => setIsTotalSelected(true) },
  ]

  const spreadPercentageLabel =
    spreadPercentage === Infinity ? '' : `${spreadPercentage.toFixed(3)}%`
  const midPrice = useMidPrice()
  const midPriceLabel = midPrice.toLocaleString(undefined, {
    maximumFractionDigits: currentMarket.price_decimals,
    minimumFractionDigits: currentMarket.price_decimals,
  })

  const numColumns = 3

  useEffect(() => {
    if (
      !isTablet &&
      !isMobile &&
      spreadRef.current !== null &&
      !alreadyScrolled.current &&
      (asks.length > 0 || bids.length > 0)
    ) {
      spreadRef.current.scrollIntoView({
        inline: 'center',
        block: 'center',
      })
      alreadyScrolled.current = true
    }
  }, [spreadRef, alreadyScrolled, asks, bids])

  useEffect(() => {
    alreadyScrolled.current = false
  }, [currentMarketId])

  if (isMobile || isTablet) {
    return (
      <div className="flex max-h-full flex-col gap-4 px-6 py-2">
        <div className="flex gap-2 self-end">
          <SelectorDropdown
            options={sizeOrTotalOptions}
            selectedOption={isTotalSelected ? 'total' : 'size'}
          />
          <SelectorDropdown
            options={sizeOptions}
            selectedOption={isSizeInUsd ? USDC_SYMBOL : symbol}
          />
        </div>
        {asks.length > 0 && bids.length > 0 && (
          <div className="grid w-full grid-cols-3 items-center border-b py-1 text-white-opaque">
            <span className="typography-body-2 text-start text-white">Spread</span>
            <span className="typography-body-2 text-center text-white">
              {Number(spread).toLocaleString(undefined, {
                minimumFractionDigits: Math.min(currentMarket.price_decimals, 4),
                maximumFractionDigits: Math.min(currentMarket.price_decimals, 4),
              })}
              {spreadPercentageLabel}
            </span>
            <span
              onClick={(e) => {
                e.stopPropagation()
                useOrderInputStore.getState().update('limitPrice', midPrice.toString())
              }}
              className="typography-body-2 text-end text-white"
            >
              {midPriceLabel}
            </span>
          </div>
        )}
        <div className="flex items-center justify-between gap-2 text-white">
          <HeaderCell title="Bid Price" className="!px-0 text-white" />
          <HeaderCell
            title={isTotalSelected ? 'Total' : 'Size'}
            symbol={isSizeInUsd ? USDC_SYMBOL : symbol}
            className="!px-0 text-white"
          />
          <HeaderCell title="Ask Price" className="!px-0 text-white" />
        </div>
        <div className="flex gap-2 overflow-x-hidden overflow-y-scroll">
          {isOrderBookLoading ? (
            <>
              <div className="flex w-full flex-col gap-2">
                {Array(20)
                  .fill(null)
                  .map((_, i) => (
                    <SkeletonOrderRow key={i} numColumns={numColumns} />
                  ))}
              </div>
              <div className="flex w-full flex-col gap-2">
                {Array(20)
                  .fill(null)
                  .map((_, i) => (
                    <SkeletonOrderRow key={i} numColumns={numColumns} />
                  ))}
              </div>
            </>
          ) : (
            <div className="relative mx-auto flex size-full flex-col max-tablet:h-fit max-tablet:flex-row">
              <OrderBookList
                reverseCols
                rows={bids}
                isAsk={false}
                className="h-full"
                maxTotalSize={maxTotalSize}
                isSizeInUsd={isSizeInUsd}
                isTotalSelected={isTotalSelected}
              />
              <OrderBookList
                rows={asks.toReversed()}
                isAsk
                className="h-full"
                maxTotalSize={maxTotalSize}
                isSizeInUsd={isSizeInUsd}
                isTotalSelected={isTotalSelected}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="relative flex h-[calc(100%_-_40px)] flex-col">
      <div className="pointer-events-none absolute bottom-0 left-0 z-[1] w-full shadow-[0_0_15px_5px_#06060C]" />
      <div className="flex size-full flex-col">
        <div className="flex w-full justify-end pr-2 pt-1">
          <SelectorDropdown
            options={sizeOptions}
            selectedOption={isSizeInUsd ? USDC_SYMBOL : symbol}
          />
        </div>
        <div className="z-[1] grid h-10 min-h-10 w-full grid-cols-[25%_35%_40%] items-center px-2 py-1 text-white shadow-dark-bottom backdrop-blur-sm max-tablet:py-3 max-tablet:pl-0 max-tablet:pr-2 max-mobile:p-2">
          <OrderBookItem title="Price" symbol={USDC_SYMBOL} />
          <OrderBookItem title="Size" symbol={isSizeInUsd ? USDC_SYMBOL : symbol} />
          <OrderBookItem title="Total" symbol={isSizeInUsd ? USDC_SYMBOL : symbol} />
        </div>
        <div className="relative h-[calc(100%_-_8px)] overflow-y-scroll">
          <div className="min-h-full" />
          {isOrderBookLoading ? (
            <div className="absolute left-0 top-0 flex w-full flex-col justify-center gap-2">
              {Array(20)
                .fill(null)
                .map((_, i) => (
                  <SkeletonOrderRow key={i} numColumns={numColumns - 1} />
                ))}
            </div>
          ) : (
            <div className="absolute left-0 top-0 w-full">
              {selectedSide !== 'bids' && asks.length > 0 && (
                <OrderBookList
                  rows={asks.toReversed()}
                  isAsk
                  className="flex justify-end"
                  maxTotalSize={maxTotalSize}
                  isSizeInUsd={isSizeInUsd}
                  dataTestId="orderbook-bids"
                />
              )}
              {selectedSide === 'all' &&
                spreadPercentage !== Infinity &&
                asks.length > 0 &&
                bids.length > 0 && (
                  <div className="my-2 grid grid-cols-3 border-y px-2 py-1" ref={spreadRef}>
                    <div className="flex justify-end gap-1">
                      <OrderBookItem
                        title={Number(spread).toLocaleString(undefined, {
                          minimumFractionDigits: Math.min(currentMarket.price_decimals, 4),
                          maximumFractionDigits: Math.min(currentMarket.price_decimals, 4),
                        })}
                      />
                      <OrderBookItem title={`(${spreadPercentageLabel})`} />
                    </div>
                    <OrderBookItem
                      onClick={(e) => {
                        e.stopPropagation()
                        useOrderInputStore.getState().update('limitPrice', midPrice.toString())
                      }}
                      title={midPriceLabel}
                    />
                    <OrderBookItem title="Spread" />
                  </div>
                )}
              {selectedSide !== 'asks' && bids.length > 0 && (
                <OrderBookList
                  rows={bids}
                  isAsk={false}
                  maxTotalSize={maxTotalSize}
                  isSizeInUsd={isSizeInUsd}
                  dataTestId="orderbook-asks"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const SkeletonOrderRow = ({ numColumns }: { numColumns: number }) => (
  <div className="flex gap-4 px-3 max-mobile:gap-1">
    {Array(numColumns)
      .fill(null)
      .map((_, i) => (
        <SkeletonRectangle key={i} className="h-4 min-w-12" />
      ))}
  </div>
)
