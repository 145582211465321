import { useOrderBookMetasQuery } from 'js/providers/hooks/order-book-metas-hooks'
import type { Order } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { OrderTypeEnum } from 'zklighter-perps'

interface OrderPriceCellProps {
  order: Order
}

const OrderPriceCell = ({ order }: OrderPriceCellProps) => {
  const market = useOrderBookMetasQuery().data[order.market_index]!
  const isLimit =
    order.type === OrderTypeEnum.Limit ||
    order.type === OrderTypeEnum.StopLossLimit ||
    order.type === OrderTypeEnum.TakeProfitLimit

  return (
    <TableCell>
      <p className="typography-body-1 text-end text-white">
        {isLimit
          ? Number(order.price).toLocaleString('en-US', {
              maximumFractionDigits: market.price_decimals,
              minimumFractionDigits: market.price_decimals,
              style: 'currency',
              currency: 'USD',
            })
          : '-'}
      </p>
    </TableCell>
  )
}
export default OrderPriceCell
