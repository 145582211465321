import DesktopViewTrade from './DesktopViewTrade'
import MobileViewTrade from './MobileViewTrade'
import TabletViewTrade from './TabletViewTrade'
import { Navigate, useParams } from 'react-router-dom'
import { useOrderBookMetasQuery } from 'js/providers/hooks/order-book-metas-hooks'
import { useResponsiveness } from 'js/ResponsivenessProvider'

const useValidateSymbolParam = () => {
  const orderBookMetasQuery = useOrderBookMetasQuery()

  return (symbol: string | null | undefined) =>
    Object.values(orderBookMetasQuery.data).some((metas) => metas.symbol === symbol)
}

const Trade = () => {
  const validateSymbolParam = useValidateSymbolParam()
  const params = useParams()
  const { isMobile, isTablet } = useResponsiveness()

  if (!validateSymbolParam(params.symbol)) {
    const lastSymbol = localStorage.getItem('last_symbol')

    if (!validateSymbolParam(lastSymbol)) {
      localStorage.setItem('last_symbol', 'ETH')
      return <Navigate replace to={`/trade/ETH`} />
    }

    return <Navigate replace to={`/trade/${lastSymbol}`} />
  }

  if (isMobile) {
    return <MobileViewTrade />
  }

  if (isTablet) {
    return <TabletViewTrade />
  }

  return <DesktopViewTrade />
}

export default Trade
