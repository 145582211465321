import { useCallback, useMemo, useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'

import Table from 'js/shared-components/uikit/table/Table'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { type OrderSide } from 'js/constants/trades'

import { NoOrdersText } from './NoOrdersText'
import { useAccountLoading, useAccountTrades } from 'js/providers/accounts-store'
import MarketCell from 'js/shared-components/cells/MarketCell'
import SideCell from 'js/shared-components/cells/SideCell'
import TradeRoleCell from 'js/shared-components/cells/trades/TradeRoleCell'
import TradePriceCell from 'js/shared-components/cells/trades/TradePriceCell'
import SizeCell from 'js/shared-components/cells/SizeCell'
import { orderApi } from 'js/util/api/sdk'
import { NoItemsInMarket } from './NoItemsInMarket'
import DateCell from 'js/shared-components/cells/DateCell'
import HeaderCell from 'js/shared-components/HeaderCell'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'
import type { OrderBookDetail } from 'zklighter-perps'

interface TradeHistoryHeaderProps {
  showMarketColumn: boolean
}

const TradeHistoryHeader = ({ showMarketColumn }: TradeHistoryHeaderProps) => (
  <TableHeader>
    <TableHeaderRow>
      {showMarketColumn && <HeaderCell title="Name" />}
      <HeaderCell title="Date" />
      <HeaderCell title="Side" />
      <HeaderCell title="Size" />
      <HeaderCell title="Price" className="justify-end" />
      <HeaderCell title="Role" className="justify-end" />
    </TableHeaderRow>
  </TableHeader>
)

interface TradeHistoryTableProps {
  accountIndex: number
  selectedSide?: OrderSide
  selectedMarket?: OrderBookDetail | null
  setSelectedMarket?: (market: OrderBookDetail | null) => void
}

const PAGE_SIZE = 20

const getAskFilter = (selectedSide: OrderSide) => {
  switch (selectedSide) {
    case 'asks': {
      return 1
    }
    case 'bids': {
      return 0
    }
    case 'all':
    default: {
      return -1
    }
  }
}

export const TradeHistoryTable = ({
  accountIndex,
  selectedSide = 'all',
  selectedMarket = null,
  setSelectedMarket = () => {},
}: TradeHistoryTableProps) => {
  const observer = useRef<IntersectionObserver | null>(null)
  const userTrades = useAccountTrades(accountIndex)
  const areTradesLoading = useAccountLoading(accountIndex)
  const showMarketColumn = !selectedMarket

  const params = useMemo(
    () =>
      ({
        market_id: selectedMarket?.market_id,
        account_index: accountIndex!,
        sort_by: 'timestamp',
        sort_dir: 'desc',
        limit: PAGE_SIZE,
        ask_filter: getAskFilter(selectedSide),
      } as const),
    [selectedMarket, selectedSide, accountIndex],
  )

  const tradeHistoryQuery = useInfiniteQuery({
    queryKey: ['tradeHistory', params],
    queryFn: ({ pageParam }) => orderApi.trades({ cursor: pageParam, ...params }),
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage) => lastPage.next_cursor,
    enabled: !!accountIndex,
  })

  const lastTradeRef = useCallback(
    (node: HTMLTableRowElement) => {
      if (tradeHistoryQuery.isFetchingNextPage || !tradeHistoryQuery.hasNextPage) {
        return
      }

      if (observer.current) {
        observer.current.disconnect()
      }

      observer.current = new IntersectionObserver(([entry]) => {
        if (entry?.isIntersecting) {
          tradeHistoryQuery.fetchNextPage()
        }
      })

      if (node) {
        observer.current.observe(node)
      }
    },
    [
      tradeHistoryQuery.isFetchingNextPage,
      tradeHistoryQuery.hasNextPage,
      tradeHistoryQuery.fetchNextPage,
    ],
  )

  const wsUserTrades = useMemo(
    () =>
      (selectedMarket
        ? userTrades[selectedMarket.market_id] ?? []
        : Object.values(userTrades).flat()
      ).filter((t) => {
        switch (selectedSide) {
          case 'asks': {
            return !t.is_maker_ask
          }
          case 'bids': {
            return t.is_maker_ask
          }
          case 'all':
          default: {
            return true
          }
        }
      }),
    [selectedMarket, selectedSide, userTrades],
  )

  const restApiUserTrades = useMemo(
    () =>
      (tradeHistoryQuery.data?.pages ?? [])
        .map(({ trades }) => trades)
        .flat()
        .filter((trade) => !wsUserTrades.some((wsTrade) => wsTrade.trade_id === trade.trade_id)),
    [tradeHistoryQuery.data, wsUserTrades],
  )

  const userTradesToDisplay = useMemo(
    () => [...wsUserTrades, ...restApiUserTrades].sort((a, b) => b.timestamp - a.timestamp),
    [selectedSide, restApiUserTrades, wsUserTrades],
  )

  if (areTradesLoading || tradeHistoryQuery.isLoading) {
    return (
      <Table>
        <TradeHistoryHeader showMarketColumn={showMarketColumn} />
        <TableBody>
          {Array(4)
            .fill(null)
            .map((_, i) => (
              <TableRow key={i}>
                {new Array(5 + Number(showMarketColumn)).fill(null).map((_, j) => (
                  <TableCell key={j} className="h-6 p-2">
                    <SkeletonRectangle />
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    )
  }

  if (!userTradesToDisplay.length) {
    if (selectedMarket) {
      return <NoItemsInMarket type={'tradeHistory'} buttonOnClick={() => setSelectedMarket(null)} />
    }

    return <NoOrdersText type="tradeHistory" />
  }

  return (
    <Table>
      <TradeHistoryHeader showMarketColumn={showMarketColumn} />
      <TableBody>
        {userTradesToDisplay.map((trade, index) => (
          <TableRow
            key={trade.trade_id}
            onClick={() => window.open(`https://scan.lighter.xyz/tx/${trade.tx_hash}`, '_blank')}
            ref={index === userTradesToDisplay.length - 1 ? lastTradeRef : undefined}
          >
            {showMarketColumn && <MarketCell marketIndex={trade.market_id} />}
            <DateCell timestamp={Math.floor(trade.timestamp / 1000)} />
            <SideCell is_short={trade.ask_account_id === accountIndex} />
            <SizeCell size={trade.size} marketIndex={trade.market_id} />
            <TradePriceCell trade={trade} />
            <TradeRoleCell trade={trade} accountIndex={accountIndex!} />
          </TableRow>
        ))}
        {tradeHistoryQuery.isFetchingNextPage && (
          <TableRow>
            {new Array(selectedMarket ? 5 : 6).fill(null).map((_, i) => (
              <TableCell key={i} className="h-6 p-2">
                <SkeletonRectangle />
              </TableCell>
            ))}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
