import Table from 'js/shared-components/uikit/table/Table'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import TableCell from 'js/shared-components/uikit/table/TableCell'

import { useAccountIndex } from 'js/providers/user-store'
import { useNavigate } from 'react-router-dom'
import {
  usePublicAccounts,
  useSubAccounts,
  useVerifyAndSwitchAccountMutation,
} from 'js/providers/hooks/useAccountQuery'
import { useMemo, useState } from 'react'
import { PageSelector } from '../trade/components/orders/user-orders/PageSelector'
import { formatUSD } from 'js/util/formatting'
import RenameAccount from './RenameAccount'
import { SubAccountType } from 'js/providers/types'
import HeaderCell from 'js/shared-components/HeaderCell'
import { Clickable } from 'js/shared-components/Clickable'
import { isZero } from 'js/util/util'

const PAGE_SIZE = 10

export const SubAccountsList = ({
  openTransferModalOpen,
}: {
  openTransferModalOpen: () => void
}) => {
  const [page, setPage] = useState(0)
  const subAccounts = useSubAccounts()
  const publicAccounts = usePublicAccounts()
  const accountIndex = useAccountIndex()
  const navigate = useNavigate()
  const verifyAndSwitchAccountMutation = useVerifyAndSwitchAccountMutation()
  const allSubAccounts = useMemo(
    () => [...subAccounts, ...publicAccounts],
    [subAccounts, publicAccounts],
  )
  const rows = useMemo(
    () => allSubAccounts.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE),
    [subAccounts, publicAccounts, page],
  )
  const totalPages = useMemo(
    () => Math.ceil(allSubAccounts.length / PAGE_SIZE),
    [allSubAccounts.length],
  )

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderRow className="static border-b bg-transparent shadow-none backdrop-filter-none">
            <HeaderCell title="Name" />
            <HeaderCell title="Type" />
            <HeaderCell title="Portfolio Value" className="justify-end" />
            <HeaderCell />
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {rows.map((account) => (
            <TableRow key={account.index}>
              <TableCell>
                <div className="flex items-center gap-2">
                  <p className="typography-body-2 max-w-full truncate text-white">
                    {account.name || 'Unnamed Account'}
                  </p>
                  <RenameAccount account={account} />
                </div>
              </TableCell>
              <TableCell>
                <p className="typography-body-2 text-white">
                  {account.account_type === SubAccountType.public && 'Public'}
                  {account.account_type === SubAccountType.sub && 'Sub'}
                </p>
              </TableCell>
              <TableCell>
                <p className="typography-body-2 text-end text-white">
                  {formatUSD(Number(account.total_asset_value))}
                </p>
              </TableCell>
              <TableCell>
                {isZero(account.collateral) ? (
                  <Clickable
                    color="blue"
                    onClick={openTransferModalOpen}
                    disabled={verifyAndSwitchAccountMutation.isPending}
                  >
                    Deposit
                  </Clickable>
                ) : account.index !== accountIndex ? (
                  <Clickable
                    color="blue"
                    onClick={() => verifyAndSwitchAccountMutation.mutate(account)}
                    disabled={verifyAndSwitchAccountMutation.isPending}
                  >
                    Switch
                  </Clickable>
                ) : (
                  <Clickable
                    color="blue"
                    onClick={() => navigate('/trade')}
                    disabled={verifyAndSwitchAccountMutation.isPending}
                  >
                    Trade
                  </Clickable>
                )}
              </TableCell>
            </TableRow>
          ))}
          {page + 1 === totalPages &&
            totalPages !== 1 &&
            new Array((PAGE_SIZE - (allSubAccounts.length % PAGE_SIZE)) % PAGE_SIZE)
              .fill(null)
              .map((_, index) => (
                <TableRow key={`${index}-fake`} className="border-b-0 opacity-0">
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              ))}
        </TableBody>
      </Table>
      {totalPages > 1 && (
        <PageSelector
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          hasNextPage={page + 1 !== totalPages}
        />
      )}
    </>
  )
}
