import { PageContainer } from 'js/pages/page-container/PageContainer'

import { AccountDetails } from './AccountDetails'
import { UserTransactions } from './UserTransactions'
import { PnlBalanceCharts } from 'js/shared-components/PnlBalanceCharts'
import { useAccountIndex, useShowOnboarding } from 'js/providers/user-store'
import { ConnectWalletModal } from 'js/shared-components/ConnectWalletModal'

const Portfolio = () => {
  const accountIndex = useAccountIndex()
  const showOnboarding = useShowOnboarding()

  return (
    <PageContainer>
      {showOnboarding && <ConnectWalletModal />}
      <div className="flex flex-col gap-2 p-2 max-mobile:p-0 mobile:min-h-full">
        <div className="flex flex-col gap-2 mobile:flex-row">
          <AccountDetails />
          <div className="flex-[2] overflow-hidden pb-2 mobile:min-h-[300px] mobile:rounded-lg mobile:border">
            <PnlBalanceCharts accountIndex={accountIndex} />
          </div>
        </div>
        <div className="flex-[2] overflow-hidden mobile:rounded-lg mobile:border">
          <UserTransactions />
        </div>
      </div>
    </PageContainer>
  )
}

export default Portfolio
