import { PageContainer } from '../page-container/PageContainer'
import { useShowOnboarding } from 'js/providers/user-store'
import { SubAccountsList } from './SubAccountsList'
import { useState } from 'react'
import { ConnectWalletModal } from 'js/shared-components/ConnectWalletModal'
import { TransferModal } from './TransferModal'
import { usePublicAccounts, useSubAccounts } from 'js/providers/hooks/useAccountQuery'
import CreateSubAccount from './CreateSubAccount'
import MainAccountList from './MainAccountList'
import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'
import { useResponsiveness } from 'js/ResponsivenessProvider'

const SubAccounts = () => {
  const { isMobile } = useResponsiveness()

  return isMobile ? <MobileSubAccounts /> : <DesktopSubAccounts />
}

const DesktopSubAccounts = () => {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false)
  const subAccounts = useSubAccounts()
  const publicAccounts = usePublicAccounts()
  const showOnboarding = useShowOnboarding()
  const allSubAccounts = [...subAccounts, ...publicAccounts]

  return (
    <PageContainer>
      {isTransferModalOpen && <TransferModal closeModal={() => setIsTransferModalOpen(false)} />}
      {showOnboarding && <ConnectWalletModal />}
      <div className="flex flex-col gap-8 px-80 py-20 max-tablet:px-40">
        <div className="flex items-center justify-between">
          <p className="typography-text-9 text-white">Sub-Accounts</p>
          <CreateSubAccount />
        </div>
        <div className="rounded-lg border bg-black p-8">
          <p className="typography-text-6 py-2 text-white">Main Account</p>
          <MainAccountList />
          <div className="flex w-full items-center justify-between pb-2 pt-8">
            <p className="typography-text-6 text-white">
              Sub-Accounts {allSubAccounts.length > 0 && `(${allSubAccounts.length})`}
            </p>
            {subAccounts.length > 0 && (
              <Clickable
                color="blue"
                onClick={() => setIsTransferModalOpen(true)}
                className="flex items-center gap-1"
              >
                <p className="typography-label-1">Transfer</p>
                <Icon icon="swap" className="size-4 rotate-90" />
              </Clickable>
            )}
          </div>
          <SubAccountsList openTransferModalOpen={() => setIsTransferModalOpen(true)} />
        </div>
      </div>
    </PageContainer>
  )
}

const MobileSubAccounts = () => {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false)
  const subAccounts = useSubAccounts()
  const publicAccounts = usePublicAccounts()
  const showOnboarding = useShowOnboarding()
  const allSubAccounts = [...subAccounts, ...publicAccounts]

  return (
    <PageContainer>
      {isTransferModalOpen && <TransferModal closeModal={() => setIsTransferModalOpen(false)} />}
      {showOnboarding && <ConnectWalletModal />}
      <div className="flex flex-col gap-3 p-4">
        <div className="flex flex-col items-start justify-between gap-5">
          <p className="typography-text-9 text-white">Sub-Accounts</p>
          <CreateSubAccount />
        </div>
        <p className="typography-text-6 py-2 text-white">Main Account</p>
        <div className="flex flex-col items-start gap-1 whitespace-nowrap rounded-lg border bg-black-darkest">
          <MainAccountList />
        </div>
        <div className="flex w-full items-center justify-between pb-2 pt-8">
          <p className="typography-text-6 text-white">
            Sub-Accounts {allSubAccounts.length > 0 && `(${allSubAccounts.length})`}
          </p>
          {subAccounts.length > 0 && (
            <Clickable
              color="blue"
              onClick={() => setIsTransferModalOpen(true)}
              className="flex items-center"
            >
              <p className="typography-label-1">Transfer</p>
              <Icon icon="swap" className="size-4 rotate-90" />
            </Clickable>
          )}
        </div>
        <div className="flex flex-col items-start gap-1 overflow-scroll whitespace-nowrap rounded-lg border bg-black-darkest">
          <SubAccountsList openTransferModalOpen={() => setIsTransferModalOpen(true)} />
        </div>
      </div>
    </PageContainer>
  )
}

export default SubAccounts
