import React, { useMemo } from 'react'

import { MarketSelector } from 'js/pages/trade/components/info-box/market-selector/MarketSelector'

import { useMarketsStats } from 'js/providers/order-book-store'
import { USDC_SYMBOL } from 'js/constants/shared'
import { useTradePageParams } from 'js/providers/hooks/useTradePageParams'
import { useCurrentMarketId } from 'js/providers/hooks/order-book-metas-hooks'
import cn from 'js/util/cn'
import Icon from 'js/shared-components/uikit/Icon'

export const MobileMarketSelector = () => {
  const { symbol } = useTradePageParams()
  const currentMarketId = useCurrentMarketId()

  const currentMarketStats = useMarketsStats()[currentMarketId]
  const markPrice = currentMarketStats?.mark_price ?? '-'
  const dayChange = currentMarketStats?.daily_price_change

  const dayVolume0 = useMemo(
    () =>
      currentMarketStats?.daily_base_token_volume !== undefined
        ? currentMarketStats.daily_base_token_volume > 1000000
          ? Intl.NumberFormat('en', { notation: 'compact' }).format(
              currentMarketStats.daily_base_token_volume,
            )
          : currentMarketStats.daily_base_token_volume.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 1,
            })
        : 'N/A',
    [currentMarketStats],
  )
  const dayVolume1 = useMemo(
    () =>
      currentMarketStats?.daily_quote_token_volume !== undefined
        ? currentMarketStats.daily_quote_token_volume > 1000000
          ? Intl.NumberFormat('en', { notation: 'compact' }).format(
              currentMarketStats.daily_quote_token_volume,
            )
          : currentMarketStats.daily_quote_token_volume.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 1,
            })
        : 'N/A',
    [currentMarketId, currentMarketStats, currentMarketStats],
  )

  const roundedDiffPct = parseFloat((dayChange ?? 0).toFixed(2))

  return (
    <div className="flex items-center justify-between gap-3 p-3">
      <MarketSelector />
      <div className="flex gap-3">
        <div className="flex h-fit flex-col border-r pr-4">
          <div className="flex items-center justify-end gap-1">
            <p className="typography-body-2 text-white">{dayVolume0}</p>
            {symbol}
          </div>
          <div className="flex items-center justify-end gap-1">
            <p className="typography-body-2 text-white">${dayVolume1}</p>
            {USDC_SYMBOL}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="typography-body-2 text-white"> ${markPrice}</p>
          <div className="flex items-center gap-1">
            <p
              className={cn('typography-body-2', {
                'text-white': roundedDiffPct === 0,
                'text-green-main': roundedDiffPct > 0,
                'text-red-main': roundedDiffPct < 0,
              })}
            >
              {roundedDiffPct}%
            </p>
            {roundedDiffPct !== 0 && (
              <Icon
                icon="triangle"
                className={cn('size-2', {
                  'text-green-main rotate-180': roundedDiffPct > 0,
                  'text-red-main rotate-0': roundedDiffPct < 0,
                })}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
