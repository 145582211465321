import { MobileMarketRow } from 'js/pages/trade/components/mobile/MobileMarketRow'

import { MarketRow } from './MarketRow'
import {
  useCurrentMarketId,
  useOrderBookMetasQuery,
} from 'js/providers/hooks/order-book-metas-hooks'
import { useMarketsStats } from 'js/providers/order-book-store'
import { SearchBar } from './Search'
import { useState } from 'react'
import { SmallArrow } from 'js/shared-components/SmallArrow'
import { TOKEN_NAMES } from 'js/constants/shared'
import Icon from 'js/shared-components/uikit/Icon'
import { Clickable } from 'js/shared-components/Clickable'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { useResponsiveness } from 'js/ResponsivenessProvider'

const headerCols = {
  symbol: { key: 'symbol', title: 'Market' },
  last_trade_price: { key: 'last_trade_price', title: 'Last Price' },
  daily_price_change: { key: 'daily_price_change', title: '24h' },
  index_price: { key: 'index_price', title: 'Index Price' },
  mark_price: { key: 'mark_price', title: 'Mark Price' },
  daily_quote_token_volume: { key: 'daily_quote_token_volume', title: 'Volume' },
}

const mobileHeaderCols = {
  symbol: { key: 'symbol', title: 'Market' },
  last_trade_price: { key: 'last_trade_price', title: 'Last Price' },
  daily_quote_token_volume: { key: 'daily_quote_token_volume', title: 'Volume' },
}
interface MarketsProps {
  onClickFunc: (marketid: number) => void
  closeSelector: () => void
}

const doesMarketMatch = (name: string, keyword: string) =>
  !keyword.length ||
  name.toLowerCase().includes(keyword.toLowerCase()) ||
  TOKEN_NAMES[name as keyof typeof TOKEN_NAMES].toLowerCase().includes(keyword.toLowerCase())

export const Markets = ({ onClickFunc, closeSelector }: MarketsProps) => {
  const [search, setSearch] = useState('')
  const { isMobile } = useResponsiveness()
  const headerColumns = isMobile ? mobileHeaderCols : headerCols
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
  const [sortedBy, setSortedBy] = useState<
    keyof typeof headerColumns | 'daily_price_change' | 'index_price' | 'mark_price' | null
  >(null)

  const orderBookMetasQuery = useOrderBookMetasQuery()
  const marketsStats = useMarketsStats()

  const markets = Object.values(orderBookMetasQuery.data)
    .map((elem) => ({ ...elem, ...marketsStats[elem.market_id]! }))
    .filter((market) => doesMarketMatch(market.symbol, search))

  const sortedMarkets = sortedBy
    ? markets.sort((a, b) => {
        switch (sortedBy) {
          case 'symbol':
            return sortDirection === 'asc'
              ? b.symbol.localeCompare(a.symbol)
              : a.symbol.localeCompare(b.symbol)
          case 'last_trade_price':
            return sortDirection === 'asc'
              ? Number(a.last_trade_price) - Number(b.last_trade_price)
              : Number(b.last_trade_price) - Number(a.last_trade_price)
          case 'daily_price_change':
            return sortDirection === 'asc'
              ? a.daily_price_change - b.daily_price_change
              : b.daily_price_change - a.daily_price_change
          case 'index_price':
            return sortDirection === 'asc'
              ? Number(a.index_price) - Number(b.index_price)
              : Number(b.index_price) - Number(a.index_price)
          case 'mark_price':
            return sortDirection === 'asc'
              ? Number(a.mark_price) - Number(b.mark_price)
              : Number(b.mark_price) - Number(a.mark_price)
          case 'daily_quote_token_volume':
            return sortDirection === 'asc'
              ? a.daily_quote_token_volume - b.daily_quote_token_volume
              : b.daily_quote_token_volume - a.daily_quote_token_volume
        }
      })
    : markets

  const currentMarketId = useCurrentMarketId()

  return (
    <>
      <div className="flex items-center justify-between border-b bg-[rgba(25,25,30,0.9)] p-3">
        <SearchBar title="Search Markets" onChange={(e) => setSearch(e.target.value)} />
        {isMobile && (
          <Clickable onClick={closeSelector}>
            <Icon icon="x" className="size-5 " />
          </Clickable>
        )}
      </div>
      {markets.length ? (
        <Table cellPadding={isMobile ? 4 : 12} cellSpacing={16}>
          <TableHeader>
            <TableHeaderRow
              style={{
                minHeight: '54px',
                maxHeight: '54px',
                height: '54px',
                background: 'rgba(25, 25, 30, 0.9)',
              }}
            >
              {Object.values(headerColumns).map((key) => (
                <TableCell
                  key={key.title}
                  className="cursor-pointer whitespace-nowrap text-white-opaque first:border-r hover:text-white"
                  onClick={() => {
                    if (sortedBy === key.key) {
                      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                      return
                    }
                    setSortedBy(key.key as keyof typeof headerColumns | 'daily_price_change')
                    setSortDirection('desc')
                  }}
                >
                  <div className="flex w-full items-center justify-center gap-1">
                    <p className="typography-body-2">{key.title}</p>
                    {sortedBy === key.key && (
                      <SmallArrow
                        className="mt-0.5"
                        direction={sortDirection === 'desc' ? 'down' : 'up'}
                      />
                    )}
                  </div>
                </TableCell>
              ))}
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {sortedMarkets.map((elem) =>
              isMobile ? (
                <MobileMarketRow
                  key={`${elem.market_id}`}
                  elem={elem}
                  onClickFunc={onClickFunc}
                  isSelected={elem.market_id === currentMarketId}
                />
              ) : (
                <MarketRow
                  key={`${elem.market_id}`}
                  elem={elem}
                  onClickFunc={onClickFunc}
                  isSelected={elem.market_id === currentMarketId}
                />
              ),
            )}
          </TableBody>
        </Table>
      ) : (
        <div className="flex min-h-[200px] w-full flex-col items-center justify-center">
          <p className="typography-label-1 text-white">No results matching your creteria</p>
        </div>
      )}
    </>
  )
}
