import { type InputHTMLAttributes } from 'react'
import cn from 'js/util/cn'
import Input from './uikit/Input'

const PercentageInput = ({ className, ...rest }: InputHTMLAttributes<HTMLInputElement>) => (
  <Input
    className={cn('h-[unset] overflow-hidden rounded-none border-none indent-0 text-xs', className)}
    {...rest}
  />
)

export default PercentageInput
