import { useState } from 'react'

import { WalletDropdown } from 'js/pages/navbar/wallet/WalletDropdown'
import { Button } from 'js/shared-components'

import { MetamaskLogin } from 'js/shared-components/MetamaskLogin'
import { useAccountIndex, useUserStore } from 'js/providers/user-store'

import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { useAccountsQuery, useUserAddress } from 'js/providers/hooks/useAccountQuery'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipArrow,
} from 'js/shared-components/uikit/Tooltip'
import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'
import { useResponsiveness } from 'js/ResponsivenessProvider'

export const WalletDropdownButton = () => {
  const [isExplanationOpen, setIsExplanationOpen] = useState(true)

  const userAddress = useUserAddress()
  const accountIndex = useAccountIndex()
  const dyn = useDynamicContext()
  const accountsQuery = useAccountsQuery()

  const { isMobile } = useResponsiveness()

  if (!userAddress) {
    return (
      <Tooltip open={dyn.sdkHasLoaded && !accountsQuery.isLoading && isExplanationOpen}>
        <TooltipTrigger asChild>
          <MetamaskLogin />
        </TooltipTrigger>
        <TooltipContent
          align={'end'}
          sideOffset={4}
          alignOffset={-4}
          className="relative max-w-[200px] pr-8"
        >
          <Clickable className="absolute right-2 top-3" onClick={() => setIsExplanationOpen(false)}>
            <Icon icon="x" className="size-4" />
          </Clickable>
          <p className="typography-body-2 text-white">Connect Your Wallet to Receive Test Tokens</p>
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    )
  }

  if (accountsQuery.isLoading) {
    if (isMobile) {
      return <Icon icon="spinner" className="size-5" />
    }

    return (
      <Button isLoading className="w-fit">
        Create Account
      </Button>
    )
  }

  if (accountIndex === null) {
    if (isMobile) {
      return (
        <Clickable onClick={() => useUserStore.setState({ showOnboarding: true })}>
          <Icon icon="wallet" className="size-5" />
        </Clickable>
      )
    }

    return (
      <Button className="w-fit" onClick={() => useUserStore.setState({ showOnboarding: true })}>
        Create Account
      </Button>
    )
  }

  return <WalletDropdown />
}
