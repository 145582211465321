import { useState } from 'react'

import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { PointsModal } from 'js/shared-components/PointsModal'

export const PointsButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { isMobile } = useResponsiveness()

  return (
    <>
      {isMobile ? (
        <Clickable onClick={() => setIsModalOpen(true)}>
          <Icon icon="trophy" className="size-5" />
        </Clickable>
      ) : (
        <Clickable className="flex items-center gap-2" onClick={() => setIsModalOpen(true)}>
          <Icon icon="trophy" className="size-5" />
          <p className="typography-label-1 underline underline-offset-4">Points</p>
        </Clickable>
      )}
      {isModalOpen && <PointsModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />}
    </>
  )
}
