import {
  useCurrentMarket,
  useCurrentMarketId,
  useOrderBookMetasQuery,
} from 'js/providers/hooks/order-book-metas-hooks'
import { useMarketsStats } from 'js/providers/order-book-store'
import { useMemo, useEffect, useCallback } from 'react'
import {
  useUserAccountPositions,
  useUserAccountLoading,
  useUserAccountStats,
} from 'js/providers/accounts-store'

import { Explanation } from 'js/shared-components/ExplanationPopup'
import { formatUSD } from 'js/util/formatting'
import Icon from 'js/shared-components/uikit/Icon'

interface BuyingStatsProps {
  newSize?: number
  isShort?: boolean
  isLimitOrder?: boolean
  setLiquidationWarning: React.Dispatch<React.SetStateAction<boolean>>
}

const useComputeMargin = () => {
  const marketsStats = useMarketsStats()
  const orderBookMetasQuery = useOrderBookMetasQuery()

  return useCallback(
    (size: number, marketId: number) => {
      // this is not entirely correct
      if (!marketsStats[marketId]) {
        return 0
      }

      const { mark_price } = marketsStats[marketId]!
      const { initial_margin_fraction } = orderBookMetasQuery.data[marketId]!

      return (size * Number(mark_price) * initial_margin_fraction) / 10_000
    },
    [marketsStats, orderBookMetasQuery.data],
  )
}

export const BuyingStats = ({
  newSize,
  isShort = false,
  isLimitOrder = false,
  setLiquidationWarning,
}: BuyingStatsProps) => {
  const portfolioStats = useUserAccountStats()
  const { available_balance, portfolio_value } = portfolioStats
  const currentMarket = useCurrentMarket()
  const currentMarketId = useCurrentMarketId()
  const positions = useUserAccountPositions()
  const positionsLoading = useUserAccountLoading()
  // TODO: revisit after BE changes the value to be a percentage
  const initialMargin = currentMarket.initial_margin_fraction / 10_000
  const computeMargin = useComputeMargin()

  const updatedMarginRequirement = useMemo(() => {
    if (positionsLoading || !newSize || isLimitOrder) {
      return null
    }

    const positionsMargin = Object.values(positions).reduce((acc, position) => {
      let updatedPos = Number(position.position) * position.sign

      if (position.market_id === currentMarketId) {
        if (isShort) {
          updatedPos -= newSize
        } else {
          updatedPos += newSize
        }
      }

      return acc + computeMargin(Math.abs(updatedPos), position.market_id)
    }, 0)

    // means newSize has been accounted for already during the .reduce
    if (Object.values(positions).some(({ market_id }) => market_id === currentMarketId)) {
      return positionsMargin
    }

    return positionsMargin + computeMargin(newSize, currentMarketId)
  }, [positionsLoading, isLimitOrder, isShort, currentMarketId, computeMargin, newSize, positions])

  const updatedFreeCollateral =
    updatedMarginRequirement === null ? null : Number(portfolio_value) - updatedMarginRequirement

  useEffect(() => {
    setLiquidationWarning(updatedFreeCollateral !== null && updatedFreeCollateral < 0)
  }, [updatedFreeCollateral, setLiquidationWarning])

  return (
    <div className="mb-3 grid grid-cols-2">
      <Stat
        title="Available Balance"
        explanation="Available Balance is the unused portion of your collateral in a trading account. It can be used for new trades or to cover potential losses, reducing the risk of liquidation."
        value={available_balance === null ? '-' : formatUSD(Number(available_balance))}
        updatedValue={updatedFreeCollateral === null ? '' : formatUSD(updatedFreeCollateral)}
      />
      <Stat
        title="Buying Power"
        explanation="Buying power represents the total USD position you can open using your available balance with leverage."
        value={
          available_balance === null ? '-' : formatUSD(Number(available_balance) / initialMargin)
        }
        updatedValue={
          updatedFreeCollateral === null ? '' : formatUSD(updatedFreeCollateral / initialMargin)
        }
      />
    </div>
  )
}

interface StatProps {
  title: string
  value: string
  explanation: string
  updatedValue: string | null
}

const Stat = ({ title, explanation, value, updatedValue }: StatProps) => (
  <div className="flex flex-col items-start gap-1 px-3 py-2 shadow-[0_0_0_0.5px_#2F343E]">
    <div className="flex items-center gap-2">
      <p className="typography-body-1 text-white">{title}</p>
      <Explanation explanation={explanation} />
    </div>
    <p className="typography-body-2 w-full truncate text-white">{value}</p>
    {updatedValue?.length ? (
      <div className="flex w-full items-center">
        <Icon icon="arrow" className="size-4 text-red-main" />
        <p className="typography-body-1 w-[calc(100%-24px)] truncate text-white">{updatedValue}</p>
      </div>
    ) : (
      <div className="h-4" />
    )}
  </div>
)
