import maintenance from 'img/shared/maintenance.svg'
import { PageContainer } from '../page-container/PageContainer'
import { useState } from 'react'
import { Feedback } from 'js/shared-components/Feedback'
import { Button } from 'js/shared-components'

export const Maintenance = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)

  return (
    <PageContainer>
      <Feedback isOpen={isFeedbackModalOpen} closeModal={() => setIsFeedbackModalOpen(false)} />
      <div className="flex max-w-[600px] flex-col items-center justify-center self-center p-2 py-16 text-center max-mobile:max-w-[100dvh]">
        <p className="typography-text-9 -mb-12 text-white">503 - Service Unavailable</p>
        <img className="w-[600px] max-mobile:w-full" src={maintenance} alt="maintenance" />
        <p className="typography-label-1 mt:[-50px] whitespace-pre-wrap text-white">
          We&apos;re Taking a Power Nap 💤 Our servers are snoozing, but your funds are safe and
          sound! What Happened? We might be updating things or just dealing with some unknown
          hiccups. We&apos;ll be back soon! What Can You Do? {'\n'}• Stay Calm: Your funds are
          secure. {'\n'}• Grab a Coffee: Perfect time for a break. {'\n'}• Try Again Soon:
          We&apos;ll be back faster than a cheetah on espresso! {'\n'}Need Help? Leave feedback and
          we will make sure to resolve the issue.
        </p>
        <Button onClick={() => setIsFeedbackModalOpen(true)} className="mt-3 w-fit">
          Feedback
        </Button>
      </div>
    </PageContainer>
  )
}
