import { USDC_SYMBOL } from 'js/constants/shared'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useIsRegisteredQuery, useUserAccount } from 'js/providers/hooks/useAccountQuery'
import WarningContainer from 'js/shared-components/WarningContainer'

export const Warning = ({
  isAmount0Less,
  isAmount1Less,
}: {
  isAmount0Less: boolean
  isAmount1Less: boolean
}) => {
  const userAccount = useUserAccount()
  const isRegistered = useIsRegisteredQuery().data

  const currentMarket = useCurrentMarket()

  if (userAccount && isRegistered === false) {
    return (
      <WarningContainer>
        <p className="typography-body-2 text-white">
          Please register your wallet on Lighter to trade
        </p>
      </WarningContainer>
    )
  }

  if (isAmount0Less || isAmount1Less) {
    return (
      <WarningContainer>
        <p className="typography-body-2 text-white">
          Your order size is below minimum order size of{' '}
          {isAmount0Less
            ? `${currentMarket.min_base_amount} ${currentMarket.symbol}`
            : `${currentMarket.min_quote_amount} ${USDC_SYMBOL}`}
        </p>
      </WarningContainer>
    )
  } else {
    return null
  }
}
