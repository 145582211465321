import { useAccountStats } from 'js/providers/accounts-store'
import { formatUSD } from 'js/util/formatting'
import { usePublicPoolQuery } from '../utils'

import { Explanation } from 'js/shared-components/ExplanationPopup'
import cn from 'js/util/cn'
import { useMaintenanceMarginReq, useAccountTotalUnrealizedPnL } from 'js/util/positions'

export const Stats = () => {
  const publicPoolQuery = usePublicPoolQuery()
  const publicPoolIndex = publicPoolQuery.data.index
  const publicPoolStats = useAccountStats(publicPoolIndex)

  const maintenanceMarginReq = useMaintenanceMarginReq(publicPoolIndex)
  const unrealizedPnl = useAccountTotalUnrealizedPnL(publicPoolIndex)

  const crossMarginRatio = maintenanceMarginReq / Number(publicPoolStats.portfolio_value)

  return (
    <div className="flex flex-col gap-3 p-4">
      <Stat
        label="Operator Fee"
        value={`
          ${Number(publicPoolQuery.data.pool_info.operator_fee).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          })}%`}
        explanation="Amount of fee public pool operator will receive from profits during shareholder withdrawals."
      />
      <Stat
        label="Minimum Operator Share"
        value={`
          ${Number(publicPoolQuery.data.pool_info.min_operator_share_rate).toLocaleString(
            undefined,
            { maximumFractionDigits: 2, minimumFractionDigits: 0 },
          )}%`}
        explanation="Minimum operator share that will be enforced, deposits that would drop the operator ownership below this configuration will be rejected."
      />
      <Stat
        label="Cross Leverage"
        value={publicPoolStats.leverage === null ? '-' : `${publicPoolStats.leverage}x`}
        explanation="Leverage allows you to control a larger market position with less capital. It can multiply both profits and losses, increasing the potential for higher returns as well as greater risks."
      />
      <Stat
        label="Cross Margin Usage"
        value={publicPoolStats.margin_usage === null ? '-' : `${publicPoolStats.margin_usage}%`}
        explanation="Margin usage refers to the portion of the pool’s equity used to maintain open positions. High margin usage can lead to partial liquidations."
      />
      <Stat
        label="Maintenance Margin Requirement"
        value={formatUSD(maintenanceMarginReq)}
        explanation="The maintenance margin requirement is the amount of collateral that must be maintained in a pool to support open positions. If the pool value falls below the maintenance margin requirement, the pool will be partially liquidated."
      />
      <Stat
        label="Cross Margin Ratio"
        value={
          crossMarginRatio === null
            ? '-'
            : `${crossMarginRatio.toLocaleString(undefined, { maximumFractionDigits: 6 })}%`
        }
        explanation="The cross margin ratio is the ratio of the maintenance margin requirement to the total pool value. A higher ratio indicates a higher risk of liquidation."
      />
      <Stat
        label="Unrealized PnL"
        value={formatUSD(unrealizedPnl, { signDisplay: 'exceptZero' })}
        explanation="Unrealized profit and loss is the potential profit or loss on open positions. It is calculated by the difference between the current market price and the average entry price of the position."
        valueClassName={cn({
          'text-green-main': unrealizedPnl > 0,
          'text-red-main': unrealizedPnl < 0,
        })}
      />
    </div>
  )
}

interface StatProps {
  label: string
  value: string
  explanation: string
  valueClassName?: string
}

const Stat = ({ label, value, explanation, valueClassName }: StatProps) => (
  <div className="flex w-full items-center justify-between">
    <Explanation
      explanation={explanation}
      trigger={<p className="typography-label-1 text-white-opaque underline">{label}</p>}
    />
    <p className={cn('typography-body-2 text-white', valueClassName)}>{value}</p>
  </div>
)
