import { useCallback, useMemo, useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'

import { type OrderSide } from 'js/constants/trades'

import Table from 'js/shared-components/uikit/table/Table'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { useAccountInactiveOrders, useAccountLoading } from 'js/providers/accounts-store'
import MarketCell from 'js/shared-components/cells/MarketCell'
import OrderTypeCell from 'js/shared-components/cells/orders/OrderTypeCell'
import SideCell from 'js/shared-components/cells/SideCell'
import OrderAmountFilledCell from 'js/shared-components/cells/orders/OrderAmountFilledCell'
import OrderPriceCell from 'js/shared-components/cells/orders/OrderPriceCell'
import OrderAveragePriceCell from 'js/shared-components/cells/orders/OrderAveragePriceCell'
import OrderStatusCell from 'js/shared-components/cells/orders/OrderStatusCell'
import { orderApi } from 'js/util/api/sdk'
import { NoItemsInMarket } from './NoItemsInMarket'
import { NoOrdersText } from './NoOrdersText'
import DateCell from 'js/shared-components/cells/DateCell'
import HeaderCell from 'js/shared-components/HeaderCell'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'
import type { OrderBookDetail } from 'zklighter-perps'

interface OrderHistoryTableHeaderProps {
  showMarketColumn: boolean
}

const OrderHistoryTableHeader = ({ showMarketColumn }: OrderHistoryTableHeaderProps) => (
  <TableHeader>
    <TableHeaderRow>
      {showMarketColumn && <HeaderCell title="Name" />}
      <HeaderCell title="Date" />
      <HeaderCell title="Type" />
      <HeaderCell title="Side" />
      <HeaderCell title="Amount" subTitle="Filled" className="justify-end" />
      <HeaderCell title="Price" className="justify-end" />
      <HeaderCell title="Average" className="justify-end" />
      <HeaderCell title="Status" className="justify-end" />
    </TableHeaderRow>
  </TableHeader>
)

interface OrderHistoryTableProps {
  accountIndex: number
  selectedSide?: OrderSide
  selectedMarket?: OrderBookDetail | null
  setSelectedMarket?: (market: OrderBookDetail | null) => void
}

const PAGE_SIZE = 20

const getAskFilter = (selectedSide: OrderSide) => {
  switch (selectedSide) {
    case 'asks': {
      return 1
    }
    case 'bids': {
      return 0
    }
    case 'all':
    default: {
      return -1
    }
  }
}

export const OrderHistoryTable = ({
  accountIndex,
  selectedSide = 'all',
  selectedMarket = null,
  setSelectedMarket = () => {},
}: OrderHistoryTableProps) => {
  const observer = useRef<IntersectionObserver | null>(null)
  const wsInactiveOrdersLoading = useAccountLoading(accountIndex)
  const userInactiveOrders = useAccountInactiveOrders(accountIndex)
  const showMarketColumn = !selectedMarket

  const params = useMemo(
    () =>
      ({
        limit: PAGE_SIZE,
        account_index: accountIndex,
        market_id: selectedMarket?.market_id,
        ask_filter: getAskFilter(selectedSide),
      } as const),
    [selectedMarket, selectedSide, accountIndex],
  )

  const inactiveOrdersQuery = useInfiniteQuery({
    queryKey: ['inactiveOrdersQuery', params],
    queryFn: ({ pageParam }) => orderApi.accountInactiveOrders({ cursor: pageParam, ...params }),
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage) => lastPage.next_cursor,
    enabled: !!accountIndex,
  })

  const wsInactiveOrders = useMemo(
    () =>
      (selectedMarket
        ? userInactiveOrders[selectedMarket.market_id] ?? []
        : Object.values(userInactiveOrders).flat()
      ).filter((order) => {
        switch (selectedSide) {
          case 'asks':
            return order.is_ask
          case 'bids':
            return !order.is_ask
          case 'all':
          default:
            return true
        }
      }),
    [selectedMarket, selectedSide, userInactiveOrders],
  )

  const restApiInactiveOrders = useMemo(
    () =>
      (inactiveOrdersQuery.data?.pages ?? [])
        .map((page) => page.orders)
        .flat()
        .filter(
          (order) => !wsInactiveOrders.some((wsOrder) => wsOrder.order_index === order.order_index),
        ),
    [inactiveOrdersQuery.data, wsInactiveOrders],
  )

  const inactiveOrdersToDisplay = useMemo(
    () => [...wsInactiveOrders, ...restApiInactiveOrders].sort((a, b) => b.timestamp - a.timestamp),
    [restApiInactiveOrders, wsInactiveOrders],
  )

  const lastOrderRef = useCallback(
    (node: HTMLTableRowElement) => {
      if (inactiveOrdersQuery.isFetchingNextPage || !inactiveOrdersQuery.hasNextPage) {
        return
      }

      if (observer.current) {
        observer.current.disconnect()
      }

      observer.current = new IntersectionObserver(([entry]) => {
        if (entry?.isIntersecting) {
          inactiveOrdersQuery.fetchNextPage()
        }
      })

      if (node) {
        observer.current.observe(node)
      }
    },
    [
      inactiveOrdersQuery.isFetchingNextPage,
      inactiveOrdersQuery.hasNextPage,
      inactiveOrdersQuery.fetchNextPage,
    ],
  )

  if (inactiveOrdersQuery.isLoading || wsInactiveOrdersLoading) {
    return (
      <Table>
        <OrderHistoryTableHeader showMarketColumn={showMarketColumn} />
        <TableBody>
          {Array(4)
            .fill(null)
            .map((_, i) => (
              <TableRow key={i}>
                {new Array(7 + Number(showMarketColumn)).fill(null).map((_, j) => (
                  <TableCell key={j} className="h-6 p-2">
                    <SkeletonRectangle />
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    )
  }

  if (selectedMarket && !inactiveOrdersToDisplay.length) {
    return <NoOrdersText type={'orderHistory'} />
  }

  if (!inactiveOrdersToDisplay.length && !inactiveOrdersQuery.hasNextPage) {
    return (
      <NoItemsInMarket
        type={'orderHistory'}
        buttonOnClick={setSelectedMarket ? () => setSelectedMarket(null) : undefined}
      />
    )
  }

  return (
    <Table>
      <OrderHistoryTableHeader showMarketColumn={showMarketColumn} />
      <TableBody>
        {inactiveOrdersToDisplay.map((order) => (
          <TableRow key={order.order_index}>
            {showMarketColumn && <MarketCell marketIndex={order.market_index} />}
            <DateCell timestamp={order.timestamp} />
            <OrderTypeCell order={order} />
            <SideCell is_short={order.is_ask} />
            <OrderAmountFilledCell order={order} />
            <OrderPriceCell order={order} />
            <OrderAveragePriceCell order={order} />
            <OrderStatusCell order={order} />
          </TableRow>
        ))}
        <TableRow ref={lastOrderRef} />
        {inactiveOrdersQuery.isFetchingNextPage && (
          <TableRow>
            {new Array(showMarketColumn ? 8 : 7).fill(null).map((_, i) => (
              <TableCell key={i} className="h-6 p-2">
                <SkeletonRectangle />
              </TableCell>
            ))}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
