import {
  type WsPortfolioStats,
  type WsTrade,
  type WsOrderbookItem,
  type WsPosition,
  type WsOrder,
  type WsLiquidation,
  type WsShare,
  type WsTx,
  type WsPoolInfo,
  type WsFundingHistoryItem,
} from './orderBookWorker/types'

// don't use Ws models directly in case we want to modify any fields
export type Trade = WsTrade
export type OrderbookItem = WsOrderbookItem
export type Position = WsPosition & {
  liquidation_price?: string
}
export type Liquidation = WsLiquidation
export type Share = WsShare
export type Order = WsOrder
export type PortfolioStats = {
  [key in keyof WsPortfolioStats]: WsPortfolioStats[key] | null
}
export type PoolInfo = WsPoolInfo
export type FundingHistoryItem = WsFundingHistoryItem
export type Tx = WsTx

export interface AccountStoreData {
  trades: Record<string, Trade[]>
  activeOrders: Record<string, Order[]>
  inactiveOrders: Record<string, Order[]>
  positions: Record<string, Position>
  liquidations: Liquidation[]
  shares: Share[]
  fundingHistory: Record<string, FundingHistoryItem[]>
}

export interface AccountsState {
  accounts: Record<number, AccountStoreData>
  portfolioStats: Record<number, PortfolioStats>
  poolInfo: Record<number, PoolInfo>
}

export enum SubAccountType {
  main = 0,
  sub = 1,
  public = 2,
  lighterPublic = 3,
}
