import cn from 'js/util/cn'
import Icon from 'js/shared-components/uikit/Icon'
import { Clickable } from 'js/shared-components/Clickable'

interface PageSelectorProps {
  className?: string
  page: number
  setPage: (newPage: number) => void
  totalPages: number
  hasNextPage: boolean
}

export const PageSelector = ({
  className,
  page,
  setPage,
  totalPages,
  hasNextPage,
}: PageSelectorProps) => (
  <div
    className={cn(
      'bottom-0 z-[1] flex w-full flex-row items-center justify-between border-t p-2',
      className,
    )}
  >
    {page > 0 ? (
      <Clickable onClick={() => setPage(page - 1)}>
        <Icon icon="chevron" className="size-3 rotate-90" />
      </Clickable>
    ) : (
      <div className="h-1 w-5" />
    )}
    {totalPages > 1 && (
      <p className="typography-body-1 text-white">
        {page + 1} of {totalPages}
      </p>
    )}
    {hasNextPage ? (
      <Clickable onClick={() => setPage(page + 1)}>
        <Icon icon="chevron" className="size-3 -rotate-90" />
      </Clickable>
    ) : (
      <div className="h-1 w-5" />
    )}
  </div>
)
