import cn from 'js/util/cn'
import { forwardRef, type TdHTMLAttributes } from 'react'

const TableCell = forwardRef<HTMLTableCellElement, TdHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...rest }, forwardedRef) => (
    <td ref={forwardedRef} className={cn('max-h-10 px-2 last:text-right', className)} {...rest} />
  ),
)

TableCell.displayName = 'TableCell'

export default TableCell
