import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { SubAccountType } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { useMemo } from 'react'
import { type PublicPool } from 'zklighter-perps'

interface PublicPoolLeaderCellProps {
  publicPool: PublicPool
}

const PublicPoolLeaderCell = ({ publicPool }: PublicPoolLeaderCellProps) => {
  const userAddress = useUserAddress()

  const address = useMemo(() => {
    if (publicPool.account_type !== SubAccountType.public) {
      return '-'
    }

    if (publicPool.l1_address === userAddress) {
      return 'You'
    }

    return publicPool.l1_address
      .substring(0, 6)
      .concat('...')
      .concat(publicPool.l1_address.slice(-4))
  }, [])

  return (
    <TableCell>
      <p className="typography-body-1 text-white">{address}</p>
    </TableCell>
  )
}

export default PublicPoolLeaderCell
