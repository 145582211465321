import { Button } from 'js/shared-components'

const noOrdersTexts = {
  positions: {
    title: 'You don’t have any open positions in the selected market',
    buttonText: 'View All Positions',
  },
  activeOrders: {
    title: 'You don’t have any open orders in the selected market',
    buttonText: 'View All Orders',
  },
  orderHistory: {
    title: 'You don’t have any orders in the selected market',
    buttonText: 'View All Orders',
  },
  tradeHistory: {
    title: 'You don’t have any trades in the selected market',
    buttonText: 'View All Trades',
  },
  fundingHistory: {
    title: 'You don’t have any funding histories in the selected market',
    buttonText: 'View All Funding Histories',
  },
} as const

interface NoItemsInMarketProps {
  buttonOnClick?: () => void
  type: 'positions' | 'activeOrders' | 'orderHistory' | 'tradeHistory' | 'fundingHistory'
}

export const NoItemsInMarket = ({ buttonOnClick, type }: NoItemsInMarketProps) => (
  <div className="flex h-full flex-col items-center justify-center gap-6 max-mobile:my-6 max-mobile:px-6 max-mobile:text-center">
    <p className="typography-label-1 text-white">{noOrdersTexts[type].title}</p>
    {!!buttonOnClick && <Button onClick={buttonOnClick}>{noOrdersTexts[type].buttonText}</Button>}
  </div>
)
