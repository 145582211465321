import type { Position } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatUSD } from 'js/util/formatting'

interface PositionAvgEntryPriceCellProps {
  position: Position
}

const PositionAvgEntryPriceCell = ({ position }: PositionAvgEntryPriceCellProps) => (
  <TableCell>
    <p className="typography-body-1 text-white">{formatUSD(Number(position.avg_entry_price))}</p>
  </TableCell>
)

export default PositionAvgEntryPriceCell
