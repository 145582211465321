import { useMarketsStats } from 'js/providers/order-book-store'
import type { Position } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatUSD } from 'js/util/formatting'

interface PositionMarkPriceCellProps {
  position: Position
}

const PositionMarkPriceCell = ({ position }: PositionMarkPriceCellProps) => {
  const marketsStats = useMarketsStats()

  return <TableCell>{formatUSD(Number(marketsStats[position.market_id]?.mark_price))}</TableCell>
}

export default PositionMarkPriceCell
