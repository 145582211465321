import type { Trade } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatUSD } from 'js/util/formatting'

interface TradePriceCellProps {
  trade: Trade
}

const TradePriceCell = ({ trade }: TradePriceCellProps) => (
  <TableCell>
    <p className="typography-body-1 text-end text-white">{formatUSD(Number(trade.price))}</p>
  </TableCell>
)

export default TradePriceCell
