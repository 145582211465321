import type { FundingHistoryItem } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface RateChangeCellProps {
  funding: FundingHistoryItem
}

const RateChangeCell = ({ funding }: RateChangeCellProps) => (
  <TableCell>
    <p className="typography-body-1 text-white">{funding.change}%</p>
  </TableCell>
)

export default RateChangeCell
