import UIKitCurrencyInput from 'js/shared-components/uikit/CurrencyInput'
import { useOrderInputStore } from '../PlaceOrder'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'

export const TriggerPriceInput = () => {
  const orderInputs = useOrderInputStore()
  const currentMarket = useCurrentMarket()
  const value = orderInputs.triggerPrice
  const onChange = (e: string) => orderInputs.setStopPrice(e)
  const decimal = currentMarket.price_decimals + currentMarket.size_decimals
  const syntaxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/,/g, '.').replace(/^0+(?=\d)/, '')
    const split = inputValue.split('.')

    const regex = /^[0-9]*\.?[0-9]*$/
    if (inputValue.length <= 12 && regex.test(inputValue) && split.length <= 2) {
      if (split.length === 2 && split[1]!.length > decimal) {
        return false
      }
      e.target.value = inputValue
      return true
    }

    return false
  }

  return (
    <UIKitCurrencyInput
      label="Trigger Price"
      helperText="When the mark price of this market crosses your trigger price, your stop/take profit order will either convert to a limit order or execute as a market order."
      value={value}
      symbol="USD"
      onChange={(e) => {
        if (syntaxCheck(e)) onChange(e.target.value)
      }}
      placeholder={Number(0).toFixed(decimal)}
    />
  )
}
