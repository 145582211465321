import { Backdrop } from './Backdrop'
import { Button } from './Button'
import { Modal } from './Modal'
import { useState } from 'react'
import { postWaitlist } from 'js/util/api/api'
import Input from './uikit/Input'
import { toast } from 'sonner'
import Toast from './uikit/Toast'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'

export const PointsModal = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean
  closeModal: () => void
}) => {
  const [telegram, setTelegram] = useState('')
  const [l1address, setL1Address] = useState('')

  const onSubmit = async () => {
    const res = await postWaitlist({ telegram, l1address })
    if (res) {
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Request submitted successfully"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
      setTelegram('')
      setL1Address('')
      closeModal()
    } else {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
    }
  }

  const onCancel = () => {
    setTelegram('')
    setL1Address('')
    closeModal()
  }

  return (
    <>
      <Backdrop fullScreen isVisible={isOpen} onClick={closeModal} />
      <Modal modalTitle="Lighter Points" isOpen={isOpen} onClose={closeModal}>
        <div className="flex max-w-full flex-col gap-6">
          <p className="typography-label-1 text-white">
            Submit your request to join our points program and we’ll get back to you on telegram.
            Only submit this form once.
          </p>
          <Input
            value={telegram}
            placeholder="Telegram"
            onChange={(e) => setTelegram(e.target.value)}
            required
            className="bg-white-transparent"
          />
          <Input
            value={l1address}
            placeholder="L1 Address"
            onChange={(e) => setL1Address(e.target.value)}
            className="bg-white-transparent"
          />
          <div className="flex justify-end gap-4">
            <Tooltip>
              <TooltipTrigger asChild>
                <Button disabled={!l1address || !telegram} onClick={onSubmit} className="w-fit">
                  Submit
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p className="typography-body-2 text-white">Please fill out all fields</p>
              </TooltipContent>
            </Tooltip>
            <Button color="grey" className="w-fit" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
