import { useAccountIndex } from 'js/providers/user-store'
import { usePublicPoolQuery, usePublicPoolSharePrice } from '../utils'
import { useMutation } from '@tanstack/react-query'
import { burnShares } from 'js/zklighter-js-sdk/sdk'
import { Button } from 'js/shared-components'
import { useState, type ChangeEvent } from 'react'
import { usePublicPoolInfo, useUserAccountShares } from 'js/providers/accounts-store'
import CurrencyInput from 'js/shared-components/uikit/CurrencyInput'
import { formatUSD } from 'js/util/formatting'
import { useAccountsQuery } from 'js/providers/hooks/useAccountQuery'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import { toast } from 'sonner'
import Toast from 'js/shared-components/uikit/Toast'

const MINIMUM_WITHDRAWAL_AMOUNT = 10

const useAvailableSharesToBurn = () => {
  const accountsQuery = useAccountsQuery()
  const publicPoolQuery = usePublicPoolQuery()
  const shares = useUserAccountShares()
  const publicPoolSharePrice = usePublicPoolSharePrice()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)

  // catch all for loading state
  if (publicPoolSharePrice === null || !poolInfo || !accountsQuery.data) {
    return 0
  }

  const isOperator = accountsQuery.data.accounts.some(
    (account) => account.index === publicPoolQuery.data.index,
  )

  const ownedShares =
    shares.find((share) => share.public_pool_index === publicPoolQuery.data.index)?.shares_amount ??
    0

  // operator_share can only increase if the non-operators are withdrawing
  if (!isOperator || poolInfo.status === 1) {
    return ownedShares
  }

  // coming from BE as '5.000' -> 5% and needs to be converted to 0.05
  const minOperatorShareRate = Number(poolInfo.min_operator_share_rate) / 100

  /*
    min_operator_share_rate <= (operator_shares - delta) / (total_shares - delta)
    min_operator_share_rate * (total_shares - delta) <= operator_shares - delta
    min_operator_share_rate * total_shares - min_operator_share_rate * delta <= operator_shares - delta
    min_operator_share_rate * total_shares <= operator_shares - delta + min_operator_share_rate * delta
    min_operator_share_rate * total_shares - operator_shares <= delta * (min_operator_share_rate - 1)
    (min_operator_share_rate * total_shares - operator_shares) / (min_operator_share_rate - 1) <= delta
  */
  return Math.abs(
    Math.floor(
      (minOperatorShareRate * poolInfo.total_shares - poolInfo.operator_shares) /
        (minOperatorShareRate - 1),
    ),
  )
}

const WithdrawForm = () => {
  const shares = useUserAccountShares()
  const accountIndex = useAccountIndex()!
  const publicPoolQuery = usePublicPoolQuery()
  const [value, setValue] = useState('')
  const publicPoolSharePrice = usePublicPoolSharePrice()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)
  const burnSharesMutation = useMutation({
    mutationFn: burnShares,
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Successfully withdrawn funds"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => setValue(''),
  })
  const availableSharesToBurn = useAvailableSharesToBurn()
  const availableToWithdraw = availableSharesToBurn * (publicPoolSharePrice ?? 0)

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/,/g, '.').replace(/^0+(?=[0-9])/, '')

    if (newValue === '') {
      return setValue(newValue)
    }

    if (!/^[0-9]*\.?[0-9]{0,2}$/.test(newValue)) {
      return
    }

    if (Number(newValue) > availableToWithdraw) {
      return setValue(availableToWithdraw.toFixed(2))
    }

    setValue(newValue)
  }

  const isEmptyField = !value
  const tooLittleWithdraw = Number(value) < MINIMUM_WITHDRAWAL_AMOUNT
  const isDisabled = isEmptyField || tooLittleWithdraw
  const ownedSharesAmount =
    shares.find((share) => share.public_pool_index === publicPoolQuery.data.index)?.shares_amount ??
    0

  return (
    <div className="flex h-full flex-col justify-between gap-10">
      <div className="flex flex-col gap-2">
        <p className="typography-body-2 text-white">Withdraw Funds</p>
        <CurrencyInput
          label="Amount"
          value={value}
          onChange={onInputChange}
          symbol="USDC"
          placeholder={'0.00'}
        />
        <div className="flex items-center justify-between">
          <p className="typography-body-1 text-white">Available to withdraw</p>
          <div className="flex items-center gap-2">
            <p className="typography-body-1 text-white">{formatUSD(availableToWithdraw)}</p>
            {availableSharesToBurn < ownedSharesAmount && (
              <Explanation
                explanation={`You can only withdraw up to ${formatUSD(
                  availableToWithdraw,
                )} in order for the minimum operating share to be above ${Number(
                  poolInfo?.min_operator_share_rate,
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}%.`}
              />
            )}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <p className="typography-body-1 text-white">Minimum withdraw amount</p>
          <p className="typography-body-1 text-white">$10</p>
        </div>
      </div>
      <div className="flex gap-1">
        {availableToWithdraw >= MINIMUM_WITHDRAWAL_AMOUNT && (
          <Button
            className="w-full"
            disabled={isDisabled}
            isLoading={burnSharesMutation.isPending || publicPoolSharePrice === null}
            onClick={() =>
              burnSharesMutation.mutate({
                accountIndex,
                publicPoolIndex: publicPoolQuery.data.index,
                shareAmount: Math.min(
                  Math.floor(Number(value) / publicPoolSharePrice!),
                  availableSharesToBurn,
                ),
              })
            }
          >
            Withdraw
          </Button>
        )}
        {availableToWithdraw < MINIMUM_WITHDRAWAL_AMOUNT && (
          <Button
            className="w-full"
            disabled={availableSharesToBurn === 0}
            isLoading={burnSharesMutation.isPending}
            onClick={() =>
              burnSharesMutation.mutate({
                accountIndex,
                publicPoolIndex: publicPoolQuery.data.index,
                shareAmount: Math.min(availableSharesToBurn, ownedSharesAmount),
              })
            }
          >
            Withdraw All
          </Button>
        )}
      </div>
    </div>
  )
}

export default WithdrawForm
