import { create } from 'zustand'

interface AppState {
  isClosingPosition: boolean
}

export const useAppState = create<AppState>(() => ({ isClosingPosition: false }))

const selectIsClosingPosition = (state: AppState) => state.isClosingPosition
export const useIsClosingPosition = () => useAppState(selectIsClosingPosition)
