import { useMemo } from 'react'
import { type OrderSide } from 'js/constants/trades'

import TableRow from 'js/shared-components/uikit/table/TableRow'
import Table from 'js/shared-components/uikit/table/Table'
import { NoItemsInMarket } from '../NoItemsInMarket'
import { useAccountLoading } from 'js/providers/accounts-store'
import { usePositionsWithLiqPrices } from 'js/util/positions'
import MarketCell from 'js/shared-components/cells/MarketCell'
import SideCell from 'js/shared-components/cells/SideCell'
import SizeCell from 'js/shared-components/cells/SizeCell'
import PositionLiquidationPriceCell from 'js/shared-components/cells/positions/PositionLiquidationPriceCell'
import PositionMarkPriceCell from 'js/shared-components/cells/positions/PositionMarkPriceCell'
import PositionUnrealizedPnlCell from 'js/shared-components/cells/positions/PositionUnrealizedPnlCell'
import PositionAvgEntryPriceCell from 'js/shared-components/cells/positions/PositionAvgEntryPriceCell'
import {
  useCurrentMarketId,
  useOrderBookMetasQuery,
} from 'js/providers/hooks/order-book-metas-hooks'
import { NoOrdersText } from '../NoOrdersText'
import CancelPositionButton from './CancelPositionButton'
import HeaderCell from 'js/shared-components/HeaderCell'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'
import type { OrderBookDetail } from 'zklighter-perps'
import cn from 'js/util/cn'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableBody from 'js/shared-components/uikit/table/TableBody'

interface PositionsTableHeaderProps {
  showCloseColumn: boolean
}

const PositionsTableHeader = ({ showCloseColumn }: PositionsTableHeaderProps) => (
  <TableHeader>
    <TableHeaderRow>
      <HeaderCell title="Market" />
      <HeaderCell title="Side" />
      <HeaderCell title="Size" />
      <HeaderCell title="Liq Price" />
      <HeaderCell title="Mark Price" />
      <HeaderCell title="Unrealized PnL" />
      <HeaderCell title="Avg Entry" className={cn({ 'justify-end': !showCloseColumn })} />
      {showCloseColumn && <HeaderCell title="Close" className="justify-end" />}
    </TableHeaderRow>
  </TableHeader>
)

interface PositionsTableProps {
  accountIndex: number
  showCloseColumn?: boolean
  selectedSide?: OrderSide
  selectedMarket?: OrderBookDetail | null
  setSelectedMarket?: (market: OrderBookDetail | null) => void
}

export const PositionsTable = ({
  accountIndex,
  showCloseColumn = false,
  selectedSide = 'all',
  selectedMarket = null,
  setSelectedMarket = () => {},
}: PositionsTableProps) => {
  const orderBookMetasQuery = useOrderBookMetasQuery()
  const currentMarketId = useCurrentMarketId()
  const positionsLoading = useAccountLoading(accountIndex)
  const positionsWithLiqPrices = usePositionsWithLiqPrices(accountIndex)

  const selectedPositions = selectedMarket
    ? [positionsWithLiqPrices[selectedMarket.market_id]].filter(Boolean)
    : Object.values(positionsWithLiqPrices)

  const positionsToDisplay = useMemo(() => {
    switch (selectedSide) {
      case 'asks': {
        return selectedPositions.filter((position) => position.sign === -1)
      }
      case 'bids': {
        return selectedPositions.filter((position) => position.sign === 1)
      }
      case 'all':
      default: {
        return selectedPositions
      }
    }
  }, [selectedSide, selectedPositions])

  if (positionsLoading) {
    return (
      <Table>
        <PositionsTableHeader showCloseColumn={showCloseColumn} />
        <TableBody>
          {Array(4)
            .fill(null)
            .map((_, i) => (
              <TableRow key={i}>
                {new Array(8).fill(null).map((_, j) => (
                  <TableCell key={j} className="h-6 p-2">
                    <SkeletonRectangle />
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    )
  }

  if (!Object.values(positionsWithLiqPrices).flat().length) {
    return <NoOrdersText type="positions" />
  }

  if (selectedMarket && !positionsWithLiqPrices[selectedMarket.market_id]) {
    return <NoItemsInMarket type="positions" buttonOnClick={() => setSelectedMarket(null)} />
  }

  return (
    <Table>
      <PositionsTableHeader showCloseColumn={showCloseColumn} />
      <TableBody>
        {positionsToDisplay.map((position) => (
          <TableRow
            key={position.market_id}
            onClick={
              position.market_id !== currentMarketId
                ? () => setSelectedMarket(orderBookMetasQuery.data[position.market_id]!)
                : undefined
            }
          >
            <MarketCell marketIndex={position.market_id} />
            <SideCell is_short={position.sign === -1} />
            <SizeCell size={position.position} marketIndex={position.market_id} />
            <PositionLiquidationPriceCell position={position} />
            <PositionMarkPriceCell position={position} />
            <PositionUnrealizedPnlCell position={position} />
            <PositionAvgEntryPriceCell position={position} />
            {showCloseColumn && <CancelPositionButton position={position} />}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
