import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'
import { useState } from 'react'

import { usePublicPoolQuery } from '../utils'
import { TradeHistoryTable } from 'js/shared-components/tables/TradeHistoryTable'
import { FundingHistoryTable } from 'js/shared-components/tables/FundingHistoryTable'
import { PositionsTable } from 'js/shared-components/tables/PositionsTable'
import { ActiveOrderTable } from 'js/shared-components/tables/ActiveOrderTable'
import { OrderHistoryTable } from 'js/shared-components/tables/OrderHistoryTable'
import { Backdrop } from 'js/shared-components/Backdrop'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import { SmallArrow } from 'js/shared-components/SmallArrow'
import cn from 'js/util/cn'
import { useResponsiveness } from 'js/ResponsivenessProvider'

const tabs = [
  { title: 'Positions', id: 0 },
  { title: 'Open Orders', id: 1 },
  { title: 'Order History', id: 2 },
  { title: 'Trade History', id: 3 },
  { title: 'Funding History', id: 4 },
]

const PublicPoolTables = () => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState(tabs[0]!)

  const publicPoolQuery = usePublicPoolQuery()
  const { isMobile } = useResponsiveness()

  return (
    <div className="flex-1 overflow-hidden rounded-lg border bg-black">
      <Backdrop
        fullScreen
        className="top-0 h-full"
        isVisible={isSelectorOpen}
        onClick={() => setIsSelectorOpen(false)}
      />
      {!isMobile && (
        <TabBar>
          <SelectorIndicator numButtons={tabs.length} selectedElemIdx={selectedTab.id} />
          {tabs.map((tab) => (
            <TabButton
              key={tab.title}
              isSelected={selectedTab.id === tab.id}
              onClick={() => setSelectedTab(tab)}
            >
              {tab.title}
            </TabButton>
          ))}
        </TabBar>
      )}
      {isMobile && (
        <TabBar className="justify-start">
          <div className="flex gap-6 px-3">
            <div>
              <div
                className="flex items-center gap-2"
                onClick={() => setIsSelectorOpen((prev) => !prev)}
              >
                <TabButton isSelected={false}>
                  <p className="typography-label-1 text-white">{selectedTab.title}</p>
                </TabButton>
                <SmallArrow direction="down" />
              </div>
              <BottomAnimatedDropdown isOpen={isSelectorOpen} className="z-[101]">
                {Object.values(tabs).map((tab) => (
                  <TabButton
                    key={tab.title}
                    className="h-[unset] py-4"
                    isSelected={false}
                    onClick={() => {
                      setSelectedTab(tab)
                      setIsSelectorOpen(false)
                    }}
                  >
                    <p
                      className={cn('typography-label-1', {
                        'text-white': selectedTab.id === tab.id,
                        'text-white-opaque': selectedTab.id !== tab.id,
                      })}
                    >
                      {tab.title}
                    </p>
                  </TabButton>
                ))}
              </BottomAnimatedDropdown>
            </div>
          </div>
        </TabBar>
      )}
      <div className="h-[400px] overflow-scroll max-mobile:h-[200px]">
        {selectedTab.id === 0 && <PositionsTable accountIndex={publicPoolQuery.data.index} />}
        {selectedTab.id === 1 && <ActiveOrderTable accountIndex={publicPoolQuery.data.index} />}
        {selectedTab.id === 2 && <OrderHistoryTable accountIndex={publicPoolQuery.data.index} />}
        {selectedTab.id === 3 && <TradeHistoryTable accountIndex={publicPoolQuery.data.index} />}
        {selectedTab.id === 4 && <FundingHistoryTable accountIndex={publicPoolQuery.data.index} />}
      </div>
    </div>
  )
}
export default PublicPoolTables
