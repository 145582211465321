import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

import { copyStringToClipboard } from 'js/util/util'

import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { useWsSubStore } from 'js/providers/wsStore'
import { Backdrop } from 'js/shared-components/Backdrop'
import { useState } from 'react'
import { FaucetModal } from 'js/shared-components/FaucetModal'
import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { SmallArrow } from 'js/shared-components/SmallArrow'
import SubAccountsList from './SubAccountsList'
import { useUserAccountStats } from 'js/providers/accounts-store'
import RequestFundsButton from './RequestFundsButton'
import { formatUSD } from 'js/util/formatting'
import { useQueryClient } from '@tanstack/react-query'
import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'

import { Button } from 'js/shared-components'
import { isEthereumWallet } from '@dynamic-labs/ethereum'
import { Popover, PopoverContent, PopoverTrigger } from 'js/shared-components/uikit/Popover'
import { toast } from 'sonner'
import Toast from 'js/shared-components/uikit/Toast'
import { CreateSubAccountModal } from 'js/pages/sub-accounts/CreateSubAccount'
import { TransferModal } from 'js/pages/sub-accounts/TransferModal'
import { useResponsiveness } from 'js/ResponsivenessProvider'

export const WalletDropdown = () => {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false)
  const [subAccountModalOpen, setSubAccountModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [isFaucetOpen, setIsFaucetOpen] = useState(false)
  const userAddress = useUserAddress()
  const { portfolio_value } = useUserAccountStats()
  const [isSubAccountsOpen, setIsSubAccountsOpen] = useState(false)
  const { handleLogOut, primaryWallet } = useDynamicContext()

  const { isMobile, isTablet } = useResponsiveness()

  const disconnectWallet = async () => {
    if (!primaryWallet) {
      return
    }
    if (isEthereumWallet(primaryWallet)) {
      const walletClient = await primaryWallet?.getWalletClient()

      if (!walletClient) {
        return
      }

      // See: https://docs.metamask.io/wallet/reference/wallet_revokepermissions
      await walletClient.request({
        method: 'wallet_revokePermissions',
        params: [{ eth_accounts: {} }],
      })
    }
  }

  const links = [
    {
      icon: 'address',
      text: 'Address',
      onClick: () => {
        copyStringToClipboard(userAddress)
        toast.custom((toastId) => (
          <Toast
            level="success"
            description="Address copied to clipboard"
            onClose={() => toast.dismiss(toastId)}
          />
        ))
      },
    },
    {
      icon: 'explorer',
      text: 'Explorer',
      onClick: () => window.open(`https://scan.lighter.xyz/account/${userAddress}`, '_blank'),
    },
    {
      icon: 'logout',
      text: 'Log Out',
      onClick: () => {
        queryClient.removeQueries({ queryKey: ['isRegistered'] })
        window.localStorage.removeItem('signature')
        window.localStorage.removeItem('lastAccountIndex')
        useWsSubStore.getState().actions.logout()
        handleLogOut()
        disconnectWallet()
        setPopoverOpen(false)
      },
    },
  ] as const

  return (
    <>
      {isTransferModalOpen && <TransferModal closeModal={() => setIsTransferModalOpen(false)} />}
      <CreateSubAccountModal
        modalOpen={subAccountModalOpen}
        closeModal={() => setSubAccountModalOpen(false)}
      />
      <FaucetModal isOpen={isFaucetOpen} closeModal={() => setIsFaucetOpen(false)} />
      <Backdrop
        isVisible={(isMobile || isTablet) && popoverOpen}
        onClick={() => setPopoverOpen(false)}
      />
      <Popover>
        <PopoverTrigger asChild onClick={() => setPopoverOpen((prev) => !prev)}>
          {isMobile ? (
            <Clickable>
              <Icon icon="wallet" className="size-5" />
            </Clickable>
          ) : (
            <Button className="w-fit items-center gap-3">
              <Icon icon="wallet" className="size-5" />
              Wallet
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent
          className="w-80 bg-black-darkest/30 p-4 backdrop-blur-xl max-mobile:w-screen max-mobile:rounded-none max-mobile:border-0"
          align="end"
          sideOffset={20}
        >
          {isSubAccountsOpen ? (
            <>
              <button
                onClick={() => setIsSubAccountsOpen(false)}
                className="sticky flex w-full items-center justify-center gap-1 p-2"
              >
                <SmallArrow className="absolute left-0 w-2.5" direction="left" />
                <p className="typography-text-7 text-white">Sub-accounts</p>
              </button>
              <div className="flex max-h-[400px] flex-col gap-4 overflow-y-auto">
                <SubAccountsList openTransferModalOpen={() => setIsTransferModalOpen(true)} />
                <Button className="w-full" onClick={() => setSubAccountModalOpen(true)}>
                  Create Sub-Account
                </Button>
              </div>
            </>
          ) : (
            <div className="flex flex-col gap-3">
              <div className="flex w-full flex-col items-center gap-2 py-4">
                <div className="flex w-full justify-center">
                  <div className="flex w-full items-center justify-around">
                    <div className="flex items-center gap-1">
                      <Jazzicon diameter={20} seed={jsNumberForAddress(userAddress)} />
                      <Clickable
                        onClick={() => {
                          copyStringToClipboard(userAddress)
                          toast.custom((toastId) => (
                            <Toast
                              level="success"
                              description="Address copied to clipboard"
                              onClose={() => toast.dismiss(toastId)}
                            />
                          ))
                        }}
                      >
                        <p className="typography-body-2 relative max-w-[190px] overflow-hidden truncate text-white">
                          {userAddress.length > 13
                            ? userAddress
                                .substring(0, 6)
                                .concat('...')
                                .concat(userAddress.slice(-4))
                            : userAddress}
                        </p>
                      </Clickable>
                    </div>
                    <Clickable
                      color="blue"
                      onClick={() => setIsSubAccountsOpen(true)}
                      className="flex items-center gap-1"
                    >
                      <p className="typography-label-1">Sub-Accounts</p>
                      <Icon icon="chevron" className="size-3 -rotate-90" />
                    </Clickable>
                  </div>
                </div>

                <p className="max-w-full self-center overflow-hidden break-words p-1 text-center text-2xl font-light">
                  {formatUSD(Number(portfolio_value ?? 0))}
                </p>
                <div className="flex gap-6 self-center">
                  {links.map(({ text, onClick, icon }) => (
                    <Clickable key={text} onClick={onClick} className="flex flex-col gap-2">
                      <Icon icon={icon} className="size-10 rounded-full bg-grey-main" />
                      <p className="typography-body-1 text-white">{text}</p>
                    </Clickable>
                  ))}
                </div>
              </div>
              <RequestFundsButton
                openModal={() => setIsFaucetOpen(true)}
                closeDropdown={() => setPopoverOpen(false)}
              />
            </div>
          )}
        </PopoverContent>
      </Popover>
    </>
  )
}
