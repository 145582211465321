import React, { Suspense, type ReactNode } from 'react'

import { DynamicContextProvider, DynamicWidget } from '@dynamic-labs/sdk-react-core'

import { EthereumWalletConnectors } from '@dynamic-labs/ethereum'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import useQueryClientConfig from './util/api/useQueryClientConfig'
import { useSiteTitleSetter } from './providers/hooks/useSiteTitleSetter'
import { useAccount } from './providers/hooks/useAccountQuery'
import { LoadingSpinner } from './shared-components'
import { useInitWs } from './providers/hooks/useInitWs'
import { Maintenance } from './pages/maintenance'
import { useHealthQuery } from './util/queries'
import { useUserStore } from './providers/user-store'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import { Toaster } from 'sonner'
import cn from './util/cn'
import ResponsivenessProvider from './ResponsivenessProvider'
import { Outlet } from 'react-router-dom'

const HealthCheck = ({ children }: { children: ReactNode }) => {
  const healthQuery = useHealthQuery()

  if (healthQuery.isLoading) {
    return <LoadingSpinner />
  }

  if (!healthQuery.data) {
    return <Maintenance />
  }

  return <>{children}</>
}

const DataSync = () => {
  useSiteTitleSetter()
  useAccount()
  useInitWs()
  return null
}

const Wrapper = () => {
  const queryClient = useQueryClientConfig()

  return (
    <ResponsivenessProvider>
      <TooltipProvider delayDuration={0} skipDelayDuration={0} disableHoverableContent>
        <DynamicContextProvider
          settings={{
            mobileExperience: 'redirect',
            walletConnectors: [EthereumWalletConnectors],
            appLogoUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Examplelogo.svg',
            initialAuthenticationMode: 'connect-only',
            appName: 'Lighter',
            environmentId: 'de15f0f9-44b8-47c0-871d-6ca54c5e6a54',
            events: {
              onWalletAdded: () => useUserStore.setState({ showOnboarding: true }),
            },
          }}
        >
          <div className="absolute opacity-0">
            <DynamicWidget />
          </div>
          <Suspense fallback={<LoadingSpinner />}>
            <QueryClientProvider client={queryClient}>
              <HealthCheck>
                <DataSync />
                <Outlet />
                <ReactQueryDevtools buttonPosition="bottom-left" position="bottom" />
              </HealthCheck>
              <Toaster cn={cn} className="mobile:![--width:240px]" />
            </QueryClientProvider>
          </Suspense>
        </DynamicContextProvider>
      </TooltipProvider>
    </ResponsivenessProvider>
  )
}

export default Wrapper
