import Table from 'js/shared-components/uikit/table/Table'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { useNavigate } from 'react-router-dom'
import {
  useMainAccount,
  useVerifyAndSwitchAccountMutation,
} from 'js/providers/hooks/useAccountQuery'
import { useAccountIndex } from 'js/providers/user-store'
import { formatUSD } from 'js/util/formatting'
import HeaderCell from 'js/shared-components/HeaderCell'
import { Clickable } from 'js/shared-components/Clickable'

const MainAccountList = () => {
  const navigate = useNavigate()
  const verifyAndSwitchAccountMutation = useVerifyAndSwitchAccountMutation()
  const mainAccount = useMainAccount()
  const accountIndex = useAccountIndex()!

  return (
    <Table>
      <TableHeader>
        <TableHeaderRow className="static border-b bg-transparent shadow-none backdrop-filter-none">
          <HeaderCell title="Name" />
          <HeaderCell title="Type" />
          <HeaderCell title="Portfolio Value" className="justify-end" />
          <HeaderCell />
        </TableHeaderRow>
      </TableHeader>
      {!!mainAccount && (
        <TableBody>
          <TableRow>
            <TableCell>
              <p className="typography-body-2 text-white">Main Account</p>
            </TableCell>
            <TableCell>
              <p className="typography-body-2 text-white">Main</p>
            </TableCell>
            <TableCell>
              <p className="typography-body-2 text-end text-white">
                {formatUSD(Number(mainAccount.total_asset_value ?? 0))}
              </p>
            </TableCell>
            <TableCell>
              {accountIndex === mainAccount.index && (
                <Clickable
                  color="blue"
                  onClick={() => navigate('/trade')}
                  disabled={verifyAndSwitchAccountMutation.isPending}
                >
                  Trade
                </Clickable>
              )}
              {accountIndex !== mainAccount.index && (
                <Clickable
                  color="blue"
                  onClick={() => verifyAndSwitchAccountMutation.mutate(mainAccount)}
                  disabled={!mainAccount || verifyAndSwitchAccountMutation.isPending}
                >
                  Switch
                </Clickable>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </Table>
  )
}

export default MainAccountList
