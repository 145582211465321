export enum TxTypes {
  TxTypeL2ChangePubKey = 7,
  TxTypeL2CreateSubAccount = 8,
  TxTypeL2CreatePublicPool = 9,
  TxTypeL2UpdatePublicPool = 10,
  TxTypeL2Transfer = 11,
  TxTypeL2Withdraw = 12,
  TxTypeL2CreateOrder = 13,
  TxTypeL2CancelOrder = 14,
  TxTypeL2CancelAllOrders = 15,
  TxTypeL2ModifyOrder = 16,
  TxTypeL2MintShares = 17,
  TxTypeL2BurnShares = 18,
}

export enum TxOrderTypes {
  OrderTypeLimit = 0,
  OrderTypeMarket = 1,
  OrderTypeStopLoss = 2,
  OrderTypeStopLossLimit = 3,
  OrderTypeTakeProfit = 4,
  OrderTypeTakeProfitLimit = 5,
  OrderTypeTWAP = 6,
  OrderTypeTWAPSubMarket = 7,
  OrderTypeLiquidation = 8,
}

export enum TxTimeInForceTypes {
  OrderImmediateOrCancel = 0,
  OrderGoodTillTime = 1,
  OrderPostOnly = 2,
}

export enum LiquidationType {
  Partial = 0,
  Full = 1,
  MarginCall = 2,
}
