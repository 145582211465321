import { OrderDirections } from 'js/constants/shared'
import { TabBar } from 'js/shared-components'
import { Clickable } from 'js/shared-components/Clickable'
import cn from 'js/util/cn'

export const OrderDirectionSelector = ({
  orderDirection,
  setOrderDirectionFromButton,
}: {
  orderDirection: OrderDirections
  setOrderDirectionFromButton: (orderDirection: OrderDirections) => void
}) => (
  <div className="px-2">
    <TabBar className="my-3 gap-0 rounded-lg border p-0 max-mobile:relative">
      <Clickable
        className={cn('z-[1] h-full w-1/2', {
          'text-white': orderDirection === OrderDirections.Long,
        })}
        onClick={() => setOrderDirectionFromButton(OrderDirections.Long)}
      >
        Buy / Long
      </Clickable>
      <Clickable
        className={cn('z-[1] h-full w-1/2', {
          'text-white': orderDirection === OrderDirections.Short,
        })}
        onClick={() => setOrderDirectionFromButton(OrderDirections.Short)}
      >
        Sell / Short
      </Clickable>
      <div
        className={cn('absolute h-full w-1/2 rounded-lg transition-all', {
          'bg-green-main left-0': orderDirection === OrderDirections.Long,
          'bg-red-main left-1/2': orderDirection === OrderDirections.Short,
        })}
      />
    </TabBar>
  </div>
)
