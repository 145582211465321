import { PageContainer } from '../page-container/PageContainer'
import { useState, useMemo } from 'react'
import { PageSelector } from '../trade/components/orders/user-orders/PageSelector'
import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import cn from 'js/util/cn'
import { accountApi } from 'js/util/api/sdk'
import { useQuery } from '@tanstack/react-query'
import type { LeaderboardRequest } from 'zklighter-perps'
import Table from 'js/shared-components/uikit/table/Table'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import TableBody from 'js/shared-components/uikit/table/TableBody'

const PAGE_SIZE = 10

type TimelineType = 'weekly' | 'all'

const Leaderboard = () => {
  const [page, setPage] = useState(0)
  const [timeline, setTimeline] = useState<TimelineType>('weekly')

  const userAddress = useUserAddress()

  const params: LeaderboardRequest = useMemo(
    () => ({ l1_address: userAddress, type: timeline }),
    [userAddress, timeline],
  )

  const leaderboardQuery = useQuery({
    queryKey: ['leaderboard', params],
    queryFn: () => accountApi.leaderboard(params),
    select: (data) => data.entries,
  })

  const leaderboard = useMemo(() => leaderboardQuery.data ?? [], [leaderboardQuery.data])

  const userPoints = useMemo(
    () => leaderboard.find((d) => d.l1_address === userAddress)?.points,
    [leaderboard, userAddress],
  )

  const rows = useMemo(
    () => leaderboard.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE),
    [leaderboard, page],
  )

  const totalPages = useMemo(() => Math.ceil(leaderboard.length / PAGE_SIZE), [leaderboard.length])

  return (
    <PageContainer>
      <div className="flex flex-col gap-8 px-80 py-20 max-tablet:px-40 max-mobile:p-4">
        <div className="flex items-center justify-between">
          <p className="typography-text-9 text-white">Leaderboard</p>
          <div className="flex flex-col items-end gap-2">
            <p className="typography-body-2 text-white">Your Points</p>
            <p className="typography-text-6 text-white">{userPoints}</p>
          </div>
        </div>
        <div className="flex flex-col gap-2 rounded-lg border bg-black p-8">
          <div className="flex items-center justify-end">
            <div className="flex gap-3">
              <p
                className={cn('typography-body-2 cursor-pointer transition-all', {
                  'text-primary-blue-main': timeline === 'weekly',
                  'text-white-opaque': timeline !== 'weekly',
                  'hover:text-white': timeline !== 'weekly',
                  'hover:text-primary-blue-main': timeline === 'weekly',
                })}
                onClick={() => setTimeline('weekly')}
              >
                7D
              </p>
              <p
                className={cn('typography-body-2 cursor-pointer transition-all', {
                  'text-primary-blue-main': timeline === 'all',
                  'text-white-opaque': timeline !== 'all',
                  'hover:text-white': timeline !== 'all',
                  'hover:text-primary-blue-main': timeline === 'all',
                })}
                onClick={() => setTimeline('all')}
              >
                All-time
              </p>
            </div>
          </div>
          <Table>
            <TableHeader>
              <TableHeaderRow>
                <TableCell>
                  <p className="typography-body-2 text-white">Rank</p>
                </TableCell>
                <TableCell>
                  <p className="typography-body-2 text-white">Trader</p>
                </TableCell>
                <TableCell>
                  <p className="typography-body-2 text-white">Points</p>
                </TableCell>
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {rows.map(({ entry, points, l1_address, entryId }) => (
                <TableRow key={entryId}>
                  <TableCell>
                    <p className="typography-body-2 text-white">{entry}</p>
                  </TableCell>
                  <TableCell>
                    <p className="typography-body-2 text-white">
                      {l1_address.substring(0, 6).concat('...').concat(l1_address.slice(-4))}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p className="typography-body-2 text-white">{points}</p>
                  </TableCell>
                </TableRow>
              ))}
              {page + 1 === totalPages &&
                new Array((PAGE_SIZE - ((leaderboard.length % PAGE_SIZE) % PAGE_SIZE)) % PAGE_SIZE)
                  .fill(null)
                  .map((_, index) => (
                    <TableRow key={`${index}-fake`} className="opacity-0">
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {totalPages > 1 && (
            <PageSelector
              page={page}
              setPage={setPage}
              totalPages={totalPages}
              hasNextPage={page + 1 !== totalPages}
            />
          )}
        </div>
      </div>
    </PageContainer>
  )
}

export default Leaderboard
