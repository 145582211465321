import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import PercentageInput from 'js/shared-components/PercentageInput'
import { Button } from 'js/shared-components'
import { type ChangeEvent, useMemo, useState } from 'react'
import { Backdrop } from 'js/shared-components/Backdrop'
import { Modal } from 'js/shared-components/Modal'
import { readSSUserSelections, writeSSUserSelection } from 'js/util/localStorage'
import { useMarketsStats } from 'js/providers/order-book-store'
import { useUserAccountPositions, useUserAccountStats } from 'js/providers/accounts-store'
import { formatUSD } from 'js/util/formatting'
import cn from 'js/util/cn'
import Icon from 'js/shared-components/uikit/Icon'

import { Explanation } from 'js/shared-components/ExplanationPopup'
import { useQuery } from '@tanstack/react-query'
import { accountApi } from 'js/util/api/sdk'
import { useUserAccount } from 'js/providers/hooks/useAccountQuery'

export const PriceImpact = ({
  isLimit,
  isClosingPosition,
  slippage,
  isLongOrder,
  newPositionSize,
  maxSlippage,
  setMaxSlippage,
  estPrice,
  orderValue,
  orderSize,
}: {
  isLimit: boolean
  isClosingPosition: boolean
  slippage: number
  isLongOrder?: boolean
  newPositionSize: string | undefined
  maxSlippage?: number
  setMaxSlippage?: (value: number) => void
  estPrice?: string
  orderValue?: string
  orderSize?: string
}) => {
  const [isSlippageModalOpen, setIsSlippageModalOpen] = useState(false)
  const [inputSlippage, setInputSlippage] = useState<string>(maxSlippage?.toString() ?? '0')
  const userAccount = useUserAccount()
  const params = useMemo(() => ({ account_index: userAccount?.index ?? 0 }), [userAccount])
  const feeBucketQuery = useQuery({
    queryKey: ['feeBucket', params],
    queryFn: () => accountApi.feeBucket(params),
    enabled: !!userAccount,
  })
  const currentMarket = useCurrentMarket()
  const initialMargin = currentMarket.initial_margin_fraction / 10_000
  const portfolioStats = useUserAccountStats()
  const marketStats = useMarketsStats()[currentMarket.market_id]
  const markPrice = marketStats?.mark_price ?? 0

  const { portfolio_value } = portfolioStats

  const { symbol } = currentMarket

  const positions = useUserAccountPositions()
  const position = positions[currentMarket.market_id]
  const currPositionSize = position?.position
  const isCurrPosLong = position?.sign === 1
  const getUpdatedPositionSize = () => {
    if (!currPositionSize && newPositionSize) {
      return Number(newPositionSize)
    }
    if (isLongOrder === isCurrPosLong) {
      return Number(currPositionSize) + Number(newPositionSize)
    }

    return Number(currPositionSize) - Number(newPositionSize)
  }

  const currPositionMargin = Number(currPositionSize ?? 0) * initialMargin * Number(markPrice)
  const updatedPositionSize = Number(getUpdatedPositionSize()) * initialMargin * Number(markPrice)

  const currPositionLeverage =
    (Number(currPositionSize ?? 0) * Number(markPrice ?? 0)) / Number(portfolio_value ?? 0)

  const getUpdatedPositionLeverage = () => {
    const newPositionLeverage =
      (Number(newPositionSize ?? 0) * Number(markPrice ?? 0)) / Number(portfolio_value ?? 0)

    if (currPositionLeverage === 0) {
      return newPositionLeverage
    }

    if (isLongOrder === isCurrPosLong) {
      return currPositionLeverage + newPositionLeverage
    }

    return currPositionLeverage - newPositionLeverage
  }
  const orderMargin = Number(orderValue) * initialMargin

  const onMaxSlippageChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value
    inputValue = inputValue.replace(/,/g, '.')

    const regex = /^[0-9]*\.?[0-9]*$/
    if (
      inputValue.length <= 6 &&
      regex.test(inputValue) &&
      inputValue.split('.').length <= 2 &&
      (inputValue.split('.').length !== 2 || inputValue.split('.')[1]!.length <= 2)
    ) {
      setInputSlippage(inputValue)
    }
  }

  const onConfirmMaxSlippage = () => {
    if (!setMaxSlippage || !inputSlippage) return
    setMaxSlippage(Number(inputSlippage))
    const { data: userSelections = {} } = readSSUserSelections()

    writeSSUserSelection({ ...userSelections, maxSlippage: inputSlippage })
    setIsSlippageModalOpen(false)
  }

  const marketSummary: {
    title: string
    value: string
    updatedValue?: string | null
    onClick?: () => void
    explanation?: string
    buttonExplanation?: string
  }[] = [
    {
      title: 'Order Size',
      value: orderSize ? `${orderSize} ${symbol}` : '-',
    },
    {
      title: 'Order Value',
      value: orderValue ? `$${orderValue}` : '-',
    },
    {
      title: 'Position',
      value: `${
        Number(currPositionSize || 0).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) ?? 0
      } ${symbol ?? ''}`,
      updatedValue: `${getUpdatedPositionSize().toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })} ${symbol ?? ''}`,
    },
    {
      title: 'Position Margin',
      value: formatUSD(currPositionMargin),
      updatedValue: formatUSD(updatedPositionSize),
    },
    {
      title: 'Position Leverage',
      value: isNaN(currPositionLeverage)
        ? '-'
        : `${currPositionLeverage.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}x`,
      updatedValue: `${getUpdatedPositionLeverage().toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}x`,
      explanation: `Leverage allows you to control a larger market position with less capital. It can multiply both profits and losses, increasing the potential for higher returns as well as greater risks.`,
    },
    {
      title: 'Est. Price',
      value: estPrice ? `$${estPrice}` : '-',
    },
    {
      title: 'Slippage',
      value: `Est: ${(isNaN(slippage) ? 0 : slippage).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}% | Max: ${maxSlippage}%`,
      onClick: () => setIsSlippageModalOpen(true),
      buttonExplanation: 'Adjust maximum slippage',
    },
    {
      title: 'Fees',
      value: feeBucketQuery.data
        ? `Taker: ${feeBucketQuery.data.taker_fee / 10 ** 6}% | Maker: ${
            feeBucketQuery.data.maker_fee / 10 ** 6
          }%`
        : 'Taker: 0% | Maker: 0%',
    },
  ]

  const limitSummary: {
    title: string
    value: string
    updatedValue?: string | null
    onClick?: () => void
    explanation?: string
    buttonExplanation?: string
  }[] = [
    {
      title: 'Margin Required',
      value: orderMargin
        ? `${orderMargin.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}`
        : '-',
    },
    {
      title: 'Order Size',
      value: orderSize ? `${orderSize} ${symbol}` : '-',
    },
    {
      title: 'Order Value',
      value: orderValue ? `$${orderValue}` : '-',
    },
    {
      title: 'Fees',
      value: feeBucketQuery.data
        ? `Taker: ${feeBucketQuery.data.taker_fee / 10 ** 6}% | Maker: ${
            feeBucketQuery.data.maker_fee / 10 ** 6
          }%`
        : 'Taker: 0% | Maker: 0%',
    },
  ]

  const closePositionSummary: {
    title: string
    value: string
    updatedValue?: string | null
    onClick?: () => void
    explanation?: string
    buttonExplanation?: string
  }[] = [
    {
      title: 'Slippage',
      value: `${(isNaN(slippage) ? 0 : slippage).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}%`,
    },
    {
      title: 'Position',
      value: `${Number(currPositionSize || 0).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })} ${symbol}`,
      updatedValue: `${getUpdatedPositionSize().toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })} ${symbol}`,
    },
    {
      title: 'Fees',
      value: feeBucketQuery.data
        ? `Taker: ${feeBucketQuery.data.taker_fee / 10 ** 6}% | Maker: ${
            feeBucketQuery.data.maker_fee / 10 ** 6
          }%`
        : 'Taker: 0% | Maker: 0%',
    },
  ]

  const summary = isClosingPosition ? closePositionSummary : isLimit ? limitSummary : marketSummary

  return (
    <>
      <Backdrop fullScreen isVisible={isSlippageModalOpen} />
      <Modal
        modalTitle="Adjust Max Slippage"
        isOpen={isSlippageModalOpen}
        onClose={() => setIsSlippageModalOpen(false)}
      >
        <div className="flex w-full flex-col items-center gap-6">
          <p className="typography-label-1 text-white">
            Set the maximum slippage you are willing to accept. If the price changes more than this
            percentage, your order will not be executed.
          </p>

          <div className="flex w-full items-center justify-between rounded-lg border bg-white-transparent p-2">
            <PercentageInput
              type="text"
              value={inputSlippage}
              onChange={onMaxSlippageChange}
              className="w-full text-left text-sm"
            />
            <p className="typography-body-2 text-white">%</p>
          </div>
          <Button
            disabled={!Number(inputSlippage)}
            className="w-full"
            onClick={onConfirmMaxSlippage}
          >
            {!Number(inputSlippage) ? 'Please enter a valid number' : 'Confirm'}
          </Button>
        </div>
      </Modal>

      <div className="flex w-full flex-col gap-3 rounded-lg border bg-white-transparent p-3">
        {summary.map(({ title, explanation, value, updatedValue, onClick, buttonExplanation }) => (
          <div key={title} className="flex w-full justify-between">
            {explanation ? (
              <Explanation
                explanation={explanation}
                trigger={<p className="typography-body-2 text-white-opaque underline">{title}:</p>}
              />
            ) : (
              <p className="typography-body-2 text-white-opaque">{title}:</p>
            )}
            <div className="flex items-center gap-1">
              {buttonExplanation ? (
                <Explanation
                  explanation={buttonExplanation}
                  trigger={
                    <div
                      className={cn('flex gap-2', {
                        'cursor-pointer': !!onClick,
                        'text-primary-blue-main': !!onClick,
                        'text-white': !onClick,
                        'font-normal': !!onClick,
                      })}
                      onClick={() => onClick?.()}
                    >
                      <p className="typography-body-2">{value}</p>
                      <Icon icon="edit" className="size-4" />
                    </div>
                  }
                />
              ) : (
                <p
                  className={cn('typography-body-2', {
                    'cursor-pointer': !!onClick,
                    'text-primary-blue-main': !!onClick,
                    'text-white': !onClick,
                    'font-normal': !!onClick,
                  })}
                  onClick={() => onClick?.()}
                >
                  {value}
                </p>
              )}

              {updatedValue && Number(newPositionSize ?? 0) !== 0 && (
                <>
                  <Icon icon="arrow" className="size-4 text-white" />
                  <p className="typography-body-2 text-white">{updatedValue}</p>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
