import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react'
import * as RadixPopover from '@radix-ui/react-popover'

export const MobileTooltipArrow = forwardRef<
  ElementRef<typeof RadixPopover.Arrow>,
  ComponentPropsWithoutRef<typeof RadixPopover.Arrow>
>(({ width = 15, height = 9, ...rest }, forwardedRef) => (
  <RadixPopover.Arrow
    ref={forwardedRef}
    width={width}
    height={height}
    className="fill-blue-grey"
    {...rest}
  />
))

MobileTooltipArrow.displayName = 'MobileTooltipArrow'
