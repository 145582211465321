import { Backdrop } from './Backdrop'
import { Modal } from './Modal'
import { useEffect, useRef, useState } from 'react'
import { useAccountIndex, useShowOnboarding, useUserStore } from 'js/providers/user-store'
import { changePubKey, createClient, getNonce, initWASM } from 'js/zklighter-js-sdk/sdk'
import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { Button } from './Button'
import { Explanation } from './ExplanationPopup'
import {
  useAccountsQuery,
  useSetIsRegistered,
  useUserAccount,
  useUserAddress,
} from 'js/providers/hooks/useAccountQuery'
import { type WalletClient } from 'viem'

import WarningContainer from './WarningContainer'
import { accountApi } from 'js/util/api/sdk'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ToggleSwitch } from './ToggleSwitch'
import {
  clientRegistrationDataSchema,
  readLSSignature,
  writeLSSignature,
} from 'js/util/localStorage'
import type { AccountRequest } from 'zklighter-perps'
import Icon from './uikit/Icon'
import { isEthereumWallet } from '@dynamic-labs/ethereum'

const wait = () => new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))

const useChangePubKeyMutation = ({
  onSuccess,
  shouldRemember,
}: {
  onSuccess: () => void
  shouldRemember: boolean
}) => {
  const setIsRegistered = useSetIsRegistered()
  const { primaryWallet } = useDynamicContext()

  return useMutation({
    mutationFn: async ({
      accountIndex,
      message,
      nonce,
      pk,
    }: {
      accountIndex: number
      message: string
      nonce: number
      pk: string
      seed: string
    }) => {
      const apiKey = await accountApi
        .apikeys({ api_key_index: 0, account_index: accountIndex })
        .then((a) => a.api_keys[0]?.public_key)
        .catch(() => '')

      // verify if the public keys match; if so, consider this a successful recover; if not, change the public key
      if (apiKey === pk) {
        return
      }

      // public key generated from the seed does not match with the one from the server
      // this happens the first time the users logs in (public key is null)
      // or when the user uses changed the PK by force
      // performing a changePubKey replaces the old PK with the one generated by the FE
      const signature = '' + (await primaryWallet!.signMessage(message))
      await changePubKey({ accountIndex, signature, nonce })
    },
    onError: (_, { accountIndex }) => setIsRegistered(accountIndex, false),
    onSuccess: (_, { accountIndex, pk, seed }) => {
      setIsRegistered(accountIndex, true)

      if (shouldRemember) {
        writeLSSignature({
          ...(readLSSignature()?.data ?? {}),
          [accountIndex]: { pk, seed },
        })
      }
      onSuccess()
    },
  })
}

const useSignMessagesMutation = ({
  onSuccess,
}: {
  onSuccess: (
    mutationResult: {
      pk: string
      body: string
      nonce: number
      seed: string
    },
    accountIndex: number,
  ) => void
}) => {
  const userAddress = useUserAddress()
  const { primaryWallet } = useDynamicContext()

  return useMutation({
    mutationFn: async (accountIndex: number) => {
      // The seed is deterministically generated, and it'll serve as the private key of the FE
      const seed = await primaryWallet!.signMessage(
        'Access zklighter account.\n\nOnly sign this message for a trusted client!\nChain ID: 300.',
      )

      if (!seed) {
        throw new Error('Something went wrong. Please try again later.')
      }

      const nonce = await getNonce({ accountIndex }).catch(() => null)

      if (nonce === null) {
        throw new Error('Something went wrong. Please try again later.')
      }

      // initialize WASM with private key
      await initWASM()
      const clientResData = await createClient({
        url: '', // url is not used by wasm
        seed,
        address: userAddress,
        chainId: 300,
        accountIndex,
        nonce,
      })

      const { data, error } = clientRegistrationDataSchema.safeParse(clientResData)

      if (error) {
        throw new Error('Something went wrong. Please refresh the page to try again.')
      }

      return { ...data, seed, nonce }
    },
    onSuccess,
  })
}

const useCreateAccountMutation = ({ onSuccess }: { onSuccess: () => void }) => {
  const userAddress = useUserAddress()

  return useMutation({
    mutationFn: async () => {
      await accountApi.faucet({ l1_address: userAddress })
      const params: AccountRequest = { by: 'l1_address', value: userAddress }
      let newAccount = await accountApi.account(params).catch(() => null)

      while (!newAccount || newAccount.accounts.length === 0) {
        await wait()
        newAccount = await accountApi.account(params).catch(() => null)
      }
    },
    onSuccess,
  })
}

export const ConnectWalletModal = () => {
  const [shouldRemember, setShouldRemember] = useState(true)
  const queryClient = useQueryClient()
  const accountsQuery = useAccountsQuery()
  const userAccount = useUserAccount()
  const accountIndex = useAccountIndex()
  const showOnboarding = useShowOnboarding()

  const closeModal = () => useUserStore.setState({ showOnboarding: false })

  const { primaryWallet } = useDynamicContext()

  const changePubKeyMutation = useChangePubKeyMutation({
    shouldRemember,
    onSuccess: () => setTimeout(closeModal, 1000),
  })
  const signMessagesMutation = useSignMessagesMutation({
    onSuccess: ({ body, pk, nonce, seed }, accountIndex) =>
      changePubKeyMutation.mutate({
        accountIndex,
        nonce,
        message: body,
        pk,
        seed,
      }),
  })
  const createAccountMutation = useCreateAccountMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  })

  const error = (
    createAccountMutation.error ||
    signMessagesMutation.error ||
    changePubKeyMutation.error
  )?.message
    ? 'Something went wrong. Please try again later.'
    : undefined

  const patchyRef = useRef(false)

  useEffect(() => {
    if (showOnboarding && accountIndex !== null && !patchyRef.current) {
      patchyRef.current = true
      setTimeout(() => signMessagesMutation.mutate(accountIndex), 0)
    }
  }, [showOnboarding, accountIndex])

  const Steps = [
    createAccountMutation.isPending ||
    createAccountMutation.isSuccess ||
    (!accountsQuery.isPending && !userAccount)
      ? {
          id: 0,
          title: 'Create account',
          description: 'Your account will be credited with 500 USDC',
          warning: createAccountMutation.isPending
            ? "This might take a few seconds. Please don't close or refresh the window."
            : undefined,
          isLoading: createAccountMutation.isPending,
          isSigned: !!userAccount || createAccountMutation.isSuccess,
        }
      : {
          id: 0,
          title: 'Checking for an existing account',
          description: 'We are checking if there is any account associated to this wallet.',
          isLoading: accountsQuery.isPending,
          isSigned: accountsQuery.isSuccess,
        },
    {
      id: 1,
      title: 'Access Lighter account',
      description: 'Please sign this message to create your Lighter key and access your account.',
      isLoading: signMessagesMutation.isPending,
      isSigned: signMessagesMutation.isSuccess,
    },
    {
      id: 2,
      title: 'Verify ownership',
      description: 'Sign this message to verify ownership of your Lighter account.',
      isLoading: changePubKeyMutation.isPending,
      isSigned: changePubKeyMutation.isSuccess,
    },
  ]

  const disconnectWallet = async () => {
    let walletClient: WalletClient | undefined
    if (!primaryWallet) {
      return
    }
    if (isEthereumWallet(primaryWallet)) {
      walletClient = await primaryWallet.getWalletClient()
    }
    if (!walletClient) {
      return
    }

    // See: https://docs.metamask.io/wallet/reference/wallet_revokepermissions
    await walletClient.request({
      method: 'wallet_revokePermissions',
      params: [{ eth_accounts: {} }],
    })

    closeModal()
  }

  return (
    <>
      <Backdrop fullScreen isVisible={showOnboarding} />
      <Modal
        isOpen={showOnboarding}
        modalTitle={userAccount ? 'Recover Keys' : 'Create Account'}
        onClose={
          !createAccountMutation.isPending &&
          !signMessagesMutation.isPending &&
          !changePubKeyMutation.isPending
            ? () => closeModal()
            : undefined
        }
      >
        <div className="flex w-full flex-col gap-4">
          <p className="typography-body-2 text-white">
            Signatures are used to access and verify your Lighter account. New users will receive
            two requests: one for generating a Lighter authentication key and another for
            registering with the Lighter exchange.
          </p>
          {Steps.map((step) => (
            <Step key={step.title} {...step} />
          ))}
          <div className="flex w-full justify-between">
            <div className="flex items-center gap-2">
              <p className="typography-label-1 text-white">Remember me</p>
              <Explanation
                explanation={`Select this option only if you’re using a secure device you own. “Remember me” stores Lighter authentication key locally in your browser. The Lighter authentication key is different from the wallet private key, which never leaves the wallet itself. Using “Remember me” on a public computer may expose your Lighter key to others.`}
              />
            </div>
            <ToggleSwitch
              onClick={() => setShouldRemember((prev) => !prev)}
              isOn={shouldRemember}
            />
          </div>
          {!!error && (
            <WarningContainer>
              <p className="typography-body-2 text-white">{error}</p>
            </WarningContainer>
          )}
          <div className="flex w-full gap-4">
            {!userAccount && (
              <Button
                className="w-full"
                onClick={() => createAccountMutation.mutate()}
                disabled={accountsQuery.isPending}
                isLoading={createAccountMutation.isPending}
              >
                {createAccountMutation.isError ? 'Try again' : 'Send request'}
              </Button>
            )}
            {userAccount && (
              <Button
                isLoading={
                  signMessagesMutation.isPending ||
                  changePubKeyMutation.isPending ||
                  changePubKeyMutation.isSuccess
                }
                className="w-full"
                onClick={() => signMessagesMutation.mutate(accountIndex!)}
              >
                {signMessagesMutation.isError || changePubKeyMutation.isError
                  ? 'Try again'
                  : 'Send request'}
              </Button>
            )}
            <Button
              color="grey"
              disabled={
                createAccountMutation.isPending ||
                signMessagesMutation.isPending ||
                changePubKeyMutation.isPending
              }
              className="w-full"
              onClick={disconnectWallet}
            >
              Disconnect
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const Step = ({
  title,
  description,
  warning,
  isLoading,
  isSigned,
}: {
  title: string
  description: string
  warning?: string
  isLoading: boolean
  isSigned: boolean
}) => (
  <div className="flex items-center gap-3 rounded-lg border bg-black-darkest p-4">
    {isLoading ? (
      <Icon icon="spinner" className="size-5" />
    ) : isSigned ? (
      <Icon icon="check" className="size-4 rounded-full border border-green-main text-green-main" />
    ) : (
      <Icon icon="check" className="size-4 rounded-full border-2 text-grey-main" />
    )}
    <div className="flex flex-col">
      <p className="typography-label-1 text-white">{title}</p>
      <p className="typography-label-1 text-white">{description}</p>
      {warning && <p className="typography-body-2 text-red-main">{warning}</p>}
    </div>
  </div>
)
