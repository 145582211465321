import cn from 'js/util/cn'
import { forwardRef, type TableHTMLAttributes } from 'react'

const Table = forwardRef<HTMLTableElement, TableHTMLAttributes<HTMLTableElement>>(
  ({ className, ...rest }, forwardedRef) => (
    <table
      ref={forwardedRef}
      className={cn('w-full table-auto border-spacing-0', className)}
      {...rest}
    />
  ),
)

Table.displayName = 'Table'

export default Table
