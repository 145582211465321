import { useEffect, useRef } from 'react'
import { useWsSubStore } from '../wsStore'
import { useAccountIndex } from '../user-store'
import { usePrevious } from 'js/util/util'
import { useMaybeCurrentMarketId } from './order-book-metas-hooks'

export const useInitWs = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const lastMessageAt = useWsSubStore((state) => state.lastMessageAt)
  const socketHealthy = useWsSubStore((state) => state.healthy)
  const prevHealthStatus = usePrevious(socketHealthy)

  const accountIndex = useAccountIndex()
  const currentMarketId = useMaybeCurrentMarketId()

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      useWsSubStore.getState().ws?.close()
    }, 10 * 1000)
  }, [lastMessageAt])

  useEffect(() => {
    const handleOffline = () => useWsSubStore.getState().ws?.close()

    window.addEventListener('offline', handleOffline)

    return () => window.removeEventListener('offline', handleOffline)
  }, [])

  // initial WS connection
  useEffect(() => {
    return useWsSubStore.getState().actions.init()
  }, [])

  // WS re-connection
  useEffect(() => {
    if (prevHealthStatus && !socketHealthy) {
      useWsSubStore.getState().actions.init()
    }
  }, [socketHealthy])

  useEffect(() => {
    if (!socketHealthy || currentMarketId === undefined) {
      return
    }

    useWsSubStore.getState().actions.switchMarket(currentMarketId)
  }, [currentMarketId, socketHealthy])

  useEffect(() => {
    if (!socketHealthy) {
      return
    }

    useWsSubStore.getState().actions.switchAccount(accountIndex)
  }, [socketHealthy, accountIndex])
}
