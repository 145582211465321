import { PageContainer } from '../page-container/PageContainer'
import PublicPoolList from './PublicPoolList'
import { useShowOnboarding } from 'js/providers/user-store'
import { ConnectWalletModal } from 'js/shared-components/ConnectWalletModal'
import CreatePublicPool from './CreatePublicPool'

const PublicPools = () => {
  const showOnboarding = useShowOnboarding()

  return (
    <PageContainer>
      {showOnboarding && <ConnectWalletModal />}
      <div className="flex flex-col gap-8 px-40 py-20 max-tablet:px-20 max-mobile:p-4">
        <div className="flex items-center justify-between gap-4 max-mobile:flex-col max-mobile:items-start">
          <p className="typography-text-9 whitespace-nowrap text-white">Public Pools</p>
          <CreatePublicPool />
        </div>
        <PublicPoolList />
      </div>
    </PageContainer>
  )
}

export default PublicPools
