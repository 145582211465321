import React, { useMemo } from 'react'

import { ProgressBar } from './ProgressBar'

import { type OrderbookItem } from 'js/providers/types'
import { useOrderInputStore } from 'js/pages/trade/components/place-order-panel/PlaceOrder'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useMidPrice } from 'js/providers/order-book-store'
import cn from 'js/util/cn'
import { useResponsiveness } from 'js/ResponsivenessProvider'

export const OrderBookList: React.FC<{
  rows: OrderbookItem[]
  isAsk: boolean
  reverseCols?: boolean
  maxTotalSize: number
  isSizeInUsd: boolean
  isTotalSelected?: boolean
  className?: string
  dataTestId?: string
}> = React.memo(
  ({
    rows,
    isAsk,
    maxTotalSize,
    className,
    reverseCols,
    isSizeInUsd,
    isTotalSelected,
    dataTestId,
  }) => {
    const cumulativeSizes = useMemo(() => {
      const elems = isAsk && !reverseCols ? rows.toReversed() : rows
      const r = elems.reduce<number[]>((acc, row) => {
        acc.push((acc[acc.length - 1] ?? 0) + Number(row.size))

        return acc
      }, [])
      return isAsk && !reverseCols ? r.toReversed() : r
    }, [rows, reverseCols, isAsk])

    return (
      <div
        className={cn(
          'flex flex-col gap-0.5 max-tablet:flex-[1] tablet:min-h-[328px]',
          {
            'max-tablet:flex-col max-tablet:justify-end': reverseCols,
            'max-tablet:flex-col-reverse max-tablet:justify-start': !reverseCols,
          },
          className,
        )}
        data-testid={dataTestId}
      >
        {rows.map((row, i) => {
          const currentTotal = cumulativeSizes[i]!
          const total = (currentTotal / maxTotalSize) * 100
          const progress = (Number(row.size) / currentTotal) * 100

          return (
            <OrderRow
              key={i}
              isAsk={isAsk}
              order={row}
              total={total}
              currentTotal={currentTotal}
              progress={progress}
              reversed={reverseCols}
              isSizeInUsd={isSizeInUsd}
              isTotalSelected={isTotalSelected}
            />
          )
        })}
      </div>
    )
  },
)

OrderBookList.displayName = 'OrderBookList'

interface OrderRowProps {
  order: OrderbookItem
  isAsk: boolean
  showDate?: boolean
  reversed?: boolean
  total: number
  progress: number
  currentTotal: number
  isSizeInUsd: boolean
  isTotalSelected?: boolean
}

const formatOrderBookUSD = (value: number) =>
  value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

const formatSize = (value: number, sizeDecimals: number) =>
  value.toLocaleString(undefined, {
    minimumFractionDigits: sizeDecimals,
    maximumFractionDigits: sizeDecimals,
  })

const OrderRow = ({
  order,
  isAsk,
  reversed,
  total,
  progress,
  currentTotal,
  isSizeInUsd,
  isTotalSelected,
}: OrderRowProps) => {
  const currentMarket = useCurrentMarket()
  const { isMobile, isTablet } = useResponsiveness()
  const midPrice = useMidPrice()

  const getSize = () => {
    if (!isTotalSelected || (!isMobile && !isTablet)) {
      return isSizeInUsd ? formatOrderBookUSD(Number(order.size) * Number(order.price)) : order.size
    }

    return isSizeInUsd
      ? formatOrderBookUSD(currentTotal * midPrice)
      : formatSize(currentTotal, currentMarket.supported_size_decimals)
  }

  return (
    <div
      className={cn(
        'relative grid h-5 min-h-5 w-full cursor-pointer grid-cols-[25%_35%_40%] items-center px-2 text-right text-white-opaque hover:bg-grey-dark',
        'max-tablet:grid-cols-2 max-mobile:h-[25px] max-mobile:min-h-[25px]',
      )}
    >
      <ProgressBar reversed={reversed} progress={progress} total={total} isGreen={!isAsk} />
      <p
        className={cn('typography-body-1 z-[1] text-white', {
          'order-1 text-end max-tablet:order-2': !reversed,
          'order-3 text-end max-tablet:order-1 max-tablet:text-start': reversed,
        })}
        onClick={(e) => {
          e.stopPropagation()
          useOrderInputStore.getState().update('limitPrice', order.price)
        }}
      >
        {order.price}
      </p>
      <p
        className={cn('typography-body-1 z-[1] text-white', {
          'order-2 text-end max-tablet:order-1 max-tablet:text-start': !reversed,
          'order-2 text-end max-tablet:order-2': reversed,
        })}
        onClick={(e) => {
          e.stopPropagation()
          useOrderInputStore.getState().update('size', order.size)
        }}
      >
        {getSize()}
      </p>
      {!isMobile && !isTablet && (
        <p
          className={cn('typography-body-1 z-[1] text-white', {
            'order-3': !reversed,
            'order-1': reversed,
          })}
        >
          {isSizeInUsd
            ? formatOrderBookUSD(Number(currentTotal) * Number(order.price))
            : formatSize(currentTotal, currentMarket.supported_size_decimals)}
        </p>
      )}
    </div>
  )
}
