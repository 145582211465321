import { useEffect, useMemo, useState } from 'react'

import { OrderDirections } from 'js/constants/shared'
import { isZero } from 'js/util/util'

import { OrderTypeSelector } from './components/OrderTypeSelector'
import { Warning } from './components/Warning'
import { PriceImpact } from './components/PriceImpact'
import { OrderDirectionSelector } from './components/OrderDirectionSelector'
import { AdvancedLimitDropdown } from './components/AdvancedLimitDropdown'
import { useMarketsStats, useOrderBook } from 'js/providers/order-book-store'
import {
  getMarketOrderFromAmount0,
  getMarketOrderFromAmount1,
  useCurrentMarket,
  useCurrentMarketId,
} from 'js/providers/hooks/order-book-metas-hooks'
import { BuyingStats } from './components/BuyingStats'
import { LeverageSlider } from './components/LeverageSlider'
import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import { ClosePosition } from './components/ClosePosition'
import { readSSUserSelections, writeSSUserSelection } from 'js/util/localStorage'
import { useIsClosingPosition } from 'js/providers/base-store'
import { validateAndParseTIFValue, type TimeInForce, type TimeInForceUnit } from './utils'
import { useUserAccountStats } from 'js/providers/accounts-store'
import { PriceInput } from './components/PriceInput'
import { OrderSizeInput } from './components/OrderSizeInput'
import { RunningTime } from './components/RunningTime'
import { TwapStats } from './components/TwapStats'
import { ReduceOnly } from './components/ReduceOnly'
import { TriggerPriceInput } from './components/TriggerPriceInput'
import { OrderTypeEnum } from 'zklighter-perps'
import WarningContainer from 'js/shared-components/WarningContainer'
import { AccountOverview } from './components/AccountOverview'
import PlaceOrderButton from './PlaceOrderButton'

type OrderPlacementInput = 'size' | 'usd' | 'leverage'

export const useOrderInputStore = create(
  combine(
    {
      kind: OrderTypeEnum.Market as OrderTypeEnum,
      pinnedInput: null as OrderPlacementInput | null,
      value: '',
      limitPrice: '',
      triggerPrice: '',
      orderDirection: OrderDirections.Long,
      timeInForce: 'gtd' as TimeInForce,
      timeInForceValue: '28',
      timeInForceUnit: 'd' as TimeInForceUnit,
      postOnly: false,
      selectedInputCoin: '',
      reduceOnly: false,
      runtimeHours: '',
      runtimeMinutes: '30',
    },
    (set, get) => ({
      isShort() {
        return get().orderDirection === OrderDirections.Short
      },
      isLimit() {
        const orderType = get().kind

        return (
          orderType === OrderTypeEnum.Limit ||
          orderType === OrderTypeEnum.StopLossLimit ||
          orderType === OrderTypeEnum.TakeProfitLimit
        )
      },
      isStop() {
        const orderType = get().kind

        return (
          orderType === OrderTypeEnum.StopLoss ||
          orderType === OrderTypeEnum.StopLossLimit ||
          orderType === OrderTypeEnum.TakeProfit ||
          orderType === OrderTypeEnum.TakeProfitLimit
        )
      },
      isTwap() {
        return get().kind === OrderTypeEnum.Twap
      },
      update(type: OrderPlacementInput | 'limitPrice', value: string | undefined) {
        if (value === undefined) {
          return
        }

        if (type === 'limitPrice') {
          set({ limitPrice: value })
          return
        }

        set({ pinnedInput: type, value: value })
      },
      setStopPrice(value: string) {
        set({ triggerPrice: value })
      },
      changeOrderKind(newKind: OrderTypeEnum) {
        if (newKind === get().kind) {
          return
        }

        set({
          kind: newKind,
          pinnedInput: 'size',
          value: '',
          limitPrice: '',
          triggerPrice: '',
        })
      },
      changeDirection(newDirection: OrderDirections) {
        set({ orderDirection: newDirection })
      },
      changeTimeInForce(newTimeInForce: TimeInForce) {
        set({ timeInForce: newTimeInForce })
      },
      changeTimeInForceValue(newTimeInForceValue: string) {
        set({
          timeInForceValue: validateAndParseTIFValue(newTimeInForceValue, get().timeInForceUnit),
        })
      },
      changeTimeInForceUnit(newTimeInForceUnit: TimeInForceUnit) {
        set({
          timeInForceUnit: newTimeInForceUnit,
          timeInForceValue: validateAndParseTIFValue(get().timeInForceValue, newTimeInForceUnit),
        })
      },
      changePostOnly(newPostOnly: boolean) {
        set({ postOnly: newPostOnly })
      },
      changeSelectedInputCoin(newCoin: string) {
        set({ selectedInputCoin: newCoin })
      },
      changeRuntimeMinutes(newRuntimeMinutes: string) {
        set({ runtimeMinutes: newRuntimeMinutes })
      },
      changeRuntimeHours(newRuntimeHours: string) {
        set({ runtimeHours: newRuntimeHours })
      },
      changeReduceOnly(newReduceOnly: boolean) {
        set({ reduceOnly: newReduceOnly })
      },
    }),
  ),
)

const useOrderBookOrders = (isShort: boolean) => {
  const orderBook = useOrderBook()

  if (!orderBook) {
    return []
  }

  return isShort ? orderBook.asks : orderBook.bids
}

const useBestPrice = (isShort: boolean) => {
  const orders = useOrderBookOrders(isShort)

  return Number(orders[0]?.price)
}

const useOrderToPlaceUpdater = (maxSlippage: number) => {
  const portfolioStats = useUserAccountStats()
  const currentMarket = useCurrentMarket()
  const orderInputStore = useOrderInputStore()
  const isShort = orderInputStore.isShort()
  const orderBookOrders = useOrderBookOrders(isShort)

  useEffect(() => {
    useOrderInputStore.setState({ value: '', limitPrice: '', triggerPrice: '' })
  }, [currentMarket.market_id])

  useEffect(() => {
    orderInputStore.changeSelectedInputCoin(currentMarket.symbol)
  }, [currentMarket])

  const leverageParams = useMemo(
    () => ({
      initialMargin: currentMarket.initial_margin_fraction / 10_000,
      availableBalance: Number(portfolioStats.available_balance),
    }),
    [currentMarket.initial_margin_fraction, portfolioStats?.available_balance],
  )

  const matchInfo = useMemo(() => {
    if (orderInputStore.pinnedInput === null) {
      return { fullyFilled: false, estPrice: '0.00', amount0: '', amount1: '' }
    }

    if (orderInputStore.kind === OrderTypeEnum.Limit) {
      switch (orderInputStore.pinnedInput) {
        case 'size': {
          return {
            fullyFilled: true,
            estPrice: '0.00',
            amount0: orderInputStore.value,
            amount1: isZero(orderInputStore.limitPrice)
              ? ''
              : (Number(orderInputStore.value) * Number(orderInputStore.limitPrice)).toFixed(2),
          }
        }
        case 'usd': {
          return {
            fullyFilled: true,
            estPrice: '0.00',
            amount0: isZero(orderInputStore.limitPrice)
              ? ''
              : (Number(orderInputStore.value) / Number(orderInputStore.limitPrice)).toFixed(
                  currentMarket.supported_size_decimals,
                ),
            amount1: orderInputStore.value,
          }
        }
        // leverage is not a used field on limit orders
        case 'leverage': {
          return null as never
        }
      }
    }

    switch (orderInputStore.pinnedInput) {
      case 'size':
        return getMarketOrderFromAmount0(currentMarket, orderInputStore.value, orderBookOrders)
      case 'usd':
        return getMarketOrderFromAmount1(currentMarket, orderInputStore.value, orderBookOrders)
      case 'leverage': {
        const usd = (
          ((leverageParams.availableBalance * (Number(orderInputStore.value) / 100)) /
            leverageParams.initialMargin) *
          (1 - maxSlippage)
        ).toFixed(2)
        return getMarketOrderFromAmount1(currentMarket, usd, orderBookOrders)
      }
    }
  }, [currentMarket, orderBookOrders, orderInputStore, leverageParams])

  const derivedLeverage = useMemo(() => {
    if (orderInputStore.pinnedInput === 'leverage') {
      return orderInputStore.value
    }

    return leverageParams.availableBalance > 0
      ? (
          (Number(matchInfo.amount1) * leverageParams.initialMargin * 100) /
          leverageParams.availableBalance
        ).toFixed(0)
      : '0'
  }, [orderInputStore.pinnedInput, orderInputStore.value, matchInfo, leverageParams])

  const isSizeValid = useMemo(
    () => Number(matchInfo.amount0) >= Number(currentMarket.min_base_amount),
    [matchInfo, currentMarket.min_base_amount],
  )

  const isPriceValid = useMemo(
    () => Number(matchInfo.amount1) >= Number(currentMarket.min_quote_amount),
    [matchInfo, currentMarket.min_quote_amount],
  )

  const slippage = useMemo(() => {
    if (!matchInfo.fullyFilled || Number(matchInfo.amount0) === 0 || orderBookOrders.length === 0) {
      return 0
    }

    const initialPrice = Number(orderBookOrders[0]!.price)

    return Math.abs((Number(matchInfo.estPrice) - initialPrice) / initialPrice) * 100
  }, [orderBookOrders, matchInfo, isShort])

  return { derivedLeverage, matchInfo, slippage, isSizeValid, isPriceValid }
}

export const PlaceOrder = () => {
  const [maxSlippage, setMaxSlippage] = useState(() => {
    const storageMaxSlippage = readSSUserSelections().data?.maxSlippage

    return typeof storageMaxSlippage === 'string' ? Number(storageMaxSlippage) : 2
  })

  const [liquidationWarning, setLiquidationWarning] = useState(false)

  const currentMarketId = useCurrentMarketId()
  const orderInputs = useOrderInputStore()
  const orderType = orderInputs.kind

  const orderDirection = orderInputs.orderDirection
  const marketsStats = useMarketsStats()
  const marketStats = marketsStats[currentMarketId]

  const orderData = useOrderToPlaceUpdater(maxSlippage / 100)
  const isClosingPosition = useIsClosingPosition()

  const markPrice = Number(marketStats?.mark_price)

  const isShort = orderInputs.isShort()
  const isLimit = orderInputs.isLimit()
  const isStop = orderInputs.isStop()
  const isMarket = orderType === OrderTypeEnum.Market
  const isTwap = orderType === OrderTypeEnum.Twap
  const bestPrice = useBestPrice(isShort)
  const isSLTriggerPriceInvalid =
    !!orderInputs.triggerPrice &&
    (orderType === OrderTypeEnum.StopLoss || orderType === OrderTypeEnum.StopLossLimit) &&
    (isShort
      ? Number(orderInputs.triggerPrice) > markPrice
      : Number(orderInputs.triggerPrice) < markPrice)

  const isTPtriggerPriceInvalid =
    !!orderInputs.triggerPrice &&
    (orderType === OrderTypeEnum.TakeProfit || orderType === OrderTypeEnum.TakeProfitLimit) &&
    (isShort
      ? Number(orderInputs.triggerPrice) < markPrice
      : Number(orderInputs.triggerPrice) > markPrice)

  const sizeValue =
    orderInputs.selectedInputCoin === 'USD'
      ? orderData.matchInfo.amount1
      : orderData.matchInfo.amount0

  const passesLimitOrderChecks = isLimit && orderData.isSizeValid && orderData.isPriceValid

  const setOrderDirectionFromButton = (dir: OrderDirections) => orderInputs.changeDirection(dir)

  const setOrderTypeFromButton = (type: OrderTypeEnum) => {
    orderInputs.changeOrderKind(type)
    const { data: userSelections = {} } = readSSUserSelections()
    writeSSUserSelection({ ...userSelections, orderType: type })
  }

  return (
    <div className="relative size-full overflow-y-hidden">
      <div className="relative size-full flex-[3] overflow-y-auto max-tablet:max-h-[calc(100dvh-60px-16px)] max-tablet:min-h-[calc(100dvh-60px-16px)] max-tablet:overflow-scroll max-mobile:max-h-dvh max-mobile:overflow-y-scroll">
        {isClosingPosition ? (
          <ClosePosition
            setLiquidationWarning={setLiquidationWarning}
            slippage={orderData.slippage}
            maxSlippage={maxSlippage}
          />
        ) : (
          <div className="flex h-full flex-col">
            <OrderTypeSelector
              orderType={orderType}
              setOrderTypeFromButton={setOrderTypeFromButton}
            />
            <OrderDirectionSelector
              orderDirection={orderInputs.orderDirection}
              setOrderDirectionFromButton={setOrderDirectionFromButton}
            />
            <BuyingStats
              newSize={Number(orderData.matchInfo.amount0)}
              isShort={isShort}
              isLimitOrder={isLimit}
              setLiquidationWarning={setLiquidationWarning}
            />
            <div className="flex size-full flex-col gap-2 px-3 max-tablet:my-4">
              {isStop && <TriggerPriceInput />}
              <OrderSizeInput value={sizeValue} />
              {isLimit ? (
                <PriceInput />
              ) : (
                <LeverageSlider
                  side={orderDirection}
                  leverage={Number(orderData.derivedLeverage)}
                  onChange={(value) =>
                    orderInputs.update('leverage', Math.min(Math.max(value, 0), 100).toFixed(0))
                  }
                  setIsLeverageInput={() =>
                    orderInputs.update('leverage', orderData.derivedLeverage)
                  }
                />
              )}
              {isTwap && <RunningTime />}
              {(!isLimit || isTwap) && <ReduceOnly />}
              <div className="flex w-full flex-col gap-2 pb-4">
                {isLimit && <AdvancedLimitDropdown />}
                <PlaceOrderButton
                  notEnoughLiquidity={!orderData.matchInfo.fullyFilled}
                  liquidationWarning={liquidationWarning}
                  triggerPriceInvalid={isSLTriggerPriceInvalid || isTPtriggerPriceInvalid}
                  passesLimitOrderChecks={passesLimitOrderChecks}
                  worstExecutionPrice={bestPrice * ((100 + maxSlippage * (isShort ? -1 : 1)) / 100)}
                  amount0={orderData.matchInfo.amount0}
                  estimatedPrice={orderData.matchInfo.estPrice}
                />
                {(!isMarket
                  ? orderInputs.value !== '' && orderInputs.limitPrice !== ''
                  : orderInputs.value !== '') && (
                  <Warning
                    isAmount0Less={isLimit && !orderData.isSizeValid}
                    isAmount1Less={isLimit && !orderData.isPriceValid}
                  />
                )}
                {isSLTriggerPriceInvalid && (
                  <WarningContainer>
                    <p className="typography-body-2 text-white">
                      Your trigger price must be {isShort ? 'below' : 'above'} the current mark
                      price.
                    </p>
                  </WarningContainer>
                )}
                {isTPtriggerPriceInvalid && (
                  <WarningContainer>
                    <p className="typography-body-2 text-white">
                      Your trigger price must be {isShort ? 'above' : 'below'} the current mark
                      price.
                    </p>
                  </WarningContainer>
                )}
                {!isTwap && (
                  <PriceImpact
                    isLimit={isLimit}
                    isClosingPosition={false}
                    slippage={orderData.slippage}
                    isLongOrder={!isShort}
                    maxSlippage={maxSlippage}
                    setMaxSlippage={setMaxSlippage}
                    newPositionSize={orderData.matchInfo.amount0}
                    estPrice={orderData.matchInfo.estPrice}
                    orderSize={orderData.matchInfo.amount0}
                    orderValue={orderData.matchInfo.amount1}
                  />
                )}
                {isTwap && <TwapStats orderSize={orderData.matchInfo.amount0} />}
                <AccountOverview />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
