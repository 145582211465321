import type { Order } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { OrderTypeEnum } from 'zklighter-perps'

const ORDER_TYPES = {
  [OrderTypeEnum.Limit]: 'Limit',
  [OrderTypeEnum.Market]: 'Market',
  [OrderTypeEnum.StopLoss]: 'S/L Market',
  [OrderTypeEnum.StopLossLimit]: 'S/L Limit',
  [OrderTypeEnum.TakeProfit]: 'T/P Market',
  [OrderTypeEnum.TakeProfitLimit]: 'T/P Limit',
  [OrderTypeEnum.Twap]: 'TWAP',
  [OrderTypeEnum.TwapSub]: 'TWAP Sub',
  [OrderTypeEnum.Liquidation]: 'Liquidation',
} as const

interface OrderTypeCellProps {
  order: Order
}

const OrderTypeCell = ({ order }: OrderTypeCellProps) => (
  <TableCell>
    <p className="typography-body-1 text-white">{ORDER_TYPES[order.type]}</p>
  </TableCell>
)

export default OrderTypeCell
