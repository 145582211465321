import Table from 'js/shared-components/uikit/table/Table'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableBody from 'js/shared-components/uikit/table/TableBody'

import {
  useMainAccount,
  usePublicAccounts,
  useSubAccounts,
  useVerifyAndSwitchAccountMutation,
} from 'js/providers/hooks/useAccountQuery'
import { SubAccountType } from 'js/providers/types'
import { useAccountIndex } from 'js/providers/user-store'
import { Clickable } from 'js/shared-components/Clickable'
import { useNavigate } from 'react-router-dom'
import { isZero } from 'js/util/util'

const SubAccountsList = ({ openTransferModalOpen }: { openTransferModalOpen: () => void }) => {
  const navigate = useNavigate()
  const accountIndex = useAccountIndex()
  const mainAccount = useMainAccount()!
  const subAccounts = useSubAccounts()
  const publicAccounts = usePublicAccounts()
  const verifyAndSwitchAccountMutation = useVerifyAndSwitchAccountMutation()

  return (
    <Table className="!table-fixed">
      <TableHeader>
        <TableHeaderRow className="static border-b bg-transparent shadow-none backdrop-filter-none">
          <TableCell>
            <p className="typography-body-2 text-white">Name</p>
          </TableCell>
          <TableCell className="w-20">
            <p className="typography-body-2 text-white">Type</p>
          </TableCell>
          <TableCell className="w-20" />
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {[mainAccount, ...subAccounts, ...publicAccounts].map((account) => (
          <TableRow key={account.index}>
            <TableCell>
              <p className="typography-body-2 max-w-full truncate text-white">
                {account.account_type === SubAccountType.main
                  ? 'Main Account'
                  : account.name || 'Unnamed Account'}
              </p>
            </TableCell>
            <TableCell className="w-20">
              <p className="typography-body-2 text-white">
                {account.account_type === SubAccountType.main && 'Main'}
                {account.account_type === SubAccountType.sub && 'Sub'}
                {account.account_type === SubAccountType.public && 'Public'}
              </p>
            </TableCell>
            <TableCell className="w-20">
              <div className="flex justify-end">
                {isZero(account.collateral) ? (
                  <Clickable
                    color="blue"
                    onClick={openTransferModalOpen}
                    disabled={verifyAndSwitchAccountMutation.isPending}
                  >
                    Deposit
                  </Clickable>
                ) : account.index !== accountIndex ? (
                  <Clickable
                    color="blue"
                    onClick={() => verifyAndSwitchAccountMutation.mutate(account)}
                    disabled={verifyAndSwitchAccountMutation.isPending}
                  >
                    Switch
                  </Clickable>
                ) : (
                  <Clickable
                    color="blue"
                    onClick={() => navigate('/trade')}
                    disabled={verifyAndSwitchAccountMutation.isPending}
                  >
                    Trade
                  </Clickable>
                )}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default SubAccountsList
