import { PageContainer } from '../page-container/PageContainer'
import PublicPoolHeader from './PublicPoolHeader'
import PublicPoolStats from './PublicPoolStats'
import PublicPoolPerformance from './PublicPoolPerformance'
import PublicPoolPnl from './PublicPoolPnl'
import PublicPoolTables from './PublicPoolTables'
import { useShowOnboarding } from 'js/providers/user-store'
import { ConnectWalletModal } from 'js/shared-components/ConnectWalletModal'
import PublicPoolActions from './PublicPoolActions'
import { Suspense, useEffect } from 'react'
import { LoadingSpinner } from 'js/shared-components'
import { useWsSubStore } from 'js/providers/wsStore'
import { usePublicPoolQuery } from './utils'

const PublicPool = () => {
  const showOnboarding = useShowOnboarding()

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <PageContainer>
        {showOnboarding && <ConnectWalletModal />}
        <PublicPoolContent />
      </PageContainer>
    </Suspense>
  )
}

const PublicPoolContent = () => {
  const publicPoolQuery = usePublicPoolQuery()
  const socketHealthy = useWsSubStore((state) => state.healthy)

  useEffect(() => {
    if (!socketHealthy) {
      return
    }

    useWsSubStore.getState().actions.subscribePublicPool(publicPoolQuery.data.index)

    return () => useWsSubStore.getState().actions.unsubscribePublicPool(publicPoolQuery.data.index)
  }, [socketHealthy, publicPoolQuery.data.index])

  return (
    <div className="flex justify-center px-4 py-16 max-tablet:py-4">
      <div className="flex w-full max-w-screen-tablet flex-col justify-center gap-8">
        <PublicPoolHeader />
        <PublicPoolStats />
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 max-tablet:flex-col">
            <PublicPoolActions />
            <PublicPoolPerformance />
            <PublicPoolPnl />
          </div>
          <PublicPoolTables />
        </div>
      </div>
    </div>
  )
}

export default PublicPool
