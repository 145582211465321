import { useMutation } from '@tanstack/react-query'
import { useMainAccount } from 'js/providers/hooks/useAccountQuery'
import { Button } from 'js/shared-components'
import { Backdrop } from 'js/shared-components/Backdrop'
import { Modal } from 'js/shared-components/Modal'
import { updatePublicPool } from 'js/zklighter-js-sdk/sdk'
import { usePublicPoolQuery } from '../utils'
import WarningContainer from 'js/shared-components/WarningContainer'
import { toast } from 'sonner'
import Toast from 'js/shared-components/uikit/Toast'

interface FreezePublicPoolModalProps {
  open: boolean
  onClose: () => void
}

const FreezePublicPoolModal = ({ open, onClose }: FreezePublicPoolModalProps) => {
  const mainAccount = useMainAccount()!
  const publicPoolQuery = usePublicPoolQuery()
  const freezePublicPoolMutation = useMutation({
    mutationFn: () =>
      updatePublicPool({
        accountIndex: mainAccount.index,
        publicPoolIndex: publicPoolQuery.data.index,
        status: 1,
        operatorFee: Number(publicPoolQuery.data.pool_info.operator_fee) * 10000,
        minOperatorShareRate: Number(publicPoolQuery.data.pool_info.min_operator_share_rate) * 100,
      }),
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Successfully frozen pool"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: onClose,
  })

  return (
    <>
      <Backdrop fullScreen isVisible={open} onClick={onClose} />
      <Modal isOpen={open} modalTitle="Freeze Public Pool" onClose={onClose}>
        <div className="flex w-full flex-col gap-4">
          <WarningContainer>You are about to freeze this public pool</WarningContainer>
          <div className="flex justify-end gap-2">
            <Button className="w-fit" color="grey" onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="w-fit"
              color="red"
              onClick={() => freezePublicPoolMutation.mutate()}
              isLoading={freezePublicPoolMutation.isPending}
            >
              Freeze Public Pool
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default FreezePublicPoolModal
