import { Tooltip, TooltipContent, TooltipTrigger } from './uikit/Tooltip'
import Icon from './uikit/Icon'
import { MobileTooltip, MobileTooltipContent, MobileTooltipTrigger } from './uikit/MobileTooltip'
import { useResponsiveness } from 'js/ResponsivenessProvider'

interface ExplanationProps {
  explanation: string
  side?: 'left' | 'right' | 'top' | 'bottom'
  trigger?: React.ReactNode
}

export const Explanation = ({ explanation, side, trigger }: ExplanationProps) => {
  const { isMobile } = useResponsiveness()

  if (isMobile) {
    return (
      <MobileTooltip>
        <MobileTooltipTrigger>
          {trigger ?? <Icon icon="question" className="size-3.5 text-white-opaque" />}
        </MobileTooltipTrigger>
        <MobileTooltipContent>
          <p className="typography-body-2 text-white">{explanation}</p>
        </MobileTooltipContent>
      </MobileTooltip>
    )
  }
  return (
    <Tooltip>
      <TooltipTrigger className="cursor-default">
        {trigger ?? <Icon icon="question" className="size-3.5 text-white-opaque" />}
      </TooltipTrigger>
      <TooltipContent side={side}>
        <p className="typography-body-2 text-white">{explanation}</p>
      </TooltipContent>
    </Tooltip>
  )
}
