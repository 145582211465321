import React, { useEffect } from 'react'

import cn from 'js/util/cn'

interface BackdropProps {
  isVisible: boolean
  fullScreen?: boolean
  onClick?: () => void
  className?: string
}

export const Backdrop = ({ isVisible, fullScreen = false, onClick, className }: BackdropProps) => {
  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [isVisible])

  return (
    <div
      onClick={onClick}
      className={cn(
        'animate-[fadeIn_150ms_ease-in-out_forwards] transition-all',
        'fixed inset-x-0 bottom-0 ',
        {
          'visible backdrop-blur-sm': isVisible,
          'hidden backdrop-blur-0': !isVisible,
          'z-10 top-0 h-screen max-mobile:z-[100]': fullScreen,
          'z-2 top-[60px] h-[calc(100vh-60px)] max-mobile:z-[3]': !fullScreen,
        },
        className,
      )}
    />
  )
}
