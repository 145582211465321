import {
  useAccountsStore,
  useUserAccountLiquidations,
  useUserAccountLoading,
  useUserAccountStats,
} from 'js/providers/accounts-store'
import { useAccountIndex } from 'js/providers/user-store'
import { NotificationPopup } from 'js/shared-components/NotificationPopup'
import { LiquidationType } from 'js/types/user'
import { notifRead } from 'js/zklighter-js-sdk/sdk'
import { useEffect } from 'react'
import { toast } from 'sonner'

const notifCopy = {
  [LiquidationType.Partial]: {
    title: 'Partial Liquidation',
    text: 'You have been partially liquidated. Please add more funds to your account or decrease the size of your open positions.',
  },
  [LiquidationType.Full]: {
    title: 'Full Liquidation',
    text: 'You have been fully liquidated.',
  },
  [LiquidationType.MarginCall]: {
    title: 'Margin Call',
    text: 'You are at risk of liquidation. Please add more funds to your account or decrease the size of your open positions.',
  },
} as const

export const LiquidationNotifications = () => {
  const liquidationsLoading = useUserAccountLoading()
  const liquidations = useUserAccountLiquidations()
  const portfolioStats = useUserAccountStats()
  const { available_balance, margin_usage } = portfolioStats
  const accountIndex = useAccountIndex()
  const isAtRisk =
    (margin_usage !== null && Number(margin_usage) > 100) ||
    (available_balance !== null && Number(available_balance) < 0)

  useEffect(() => {
    if (accountIndex === null || liquidationsLoading || !isAtRisk) {
      return
    }

    toast.custom(
      (toastId) => (
        <NotificationPopup closePopup={() => toast.dismiss(toastId)}>
          <div className="flex flex-col gap-2">
            <p className="typography-label-1 text-white">
              {notifCopy[LiquidationType.MarginCall].title}
            </p>
            <p className="typography-body-1 text-white">
              {notifCopy[LiquidationType.MarginCall].text}
            </p>
          </div>
        </NotificationPopup>
      ),
      { duration: Infinity, id: 'margin-call', position: 'top-right' },
    )
  }, [isAtRisk, accountIndex, liquidationsLoading])

  useEffect(() => {
    if (!accountIndex) {
      return
    }

    liquidations.forEach(({ liquidation_id, liquidation_type }) =>
      toast.custom(
        (toastId) => (
          <NotificationPopup
            closePopup={() => {
              notifRead({
                notifType: 'liquidation',
                accountIndex: accountIndex,
                liquidationId: liquidation_id,
              }).catch(() => {})

              useAccountsStore.setState((state) => ({
                ...state,
                accounts: {
                  ...state.accounts,
                  [accountIndex]: {
                    ...state.accounts[accountIndex]!,
                    liquidations: liquidations.filter(
                      (notification) => notification.liquidation_id !== liquidation_id,
                    ),
                  },
                },
              }))

              toast.dismiss(toastId)
            }}
          >
            <div className="flex flex-col gap-2">
              <p className="typography-label-1 text-white">{notifCopy[liquidation_type].title}</p>
              <p className="typography-body-1 text-white">{notifCopy[liquidation_type].text}</p>
            </div>
          </NotificationPopup>
        ),
        { duration: Infinity, id: liquidation_id, position: 'top-right' },
      ),
    )
  }, [accountIndex, liquidations])

  return null
}
