import { useUserAccountStats } from 'js/providers/accounts-store'
import { formatUSD } from 'js/util/formatting'
import { useState } from 'react'
import { FaucetModal } from 'js/shared-components/FaucetModal'
import RequestFundsButton from '../navbar/wallet/RequestFundsButton'

import { Explanation } from 'js/shared-components/ExplanationPopup'

export const AccountDetails = () => {
  const [isFaucetOpen, setIsFaucetOpen] = useState(false)
  const { portfolio_value, leverage, available_balance, margin_usage } = useUserAccountStats()

  const Stats = {
    stat1: {
      title: 'Portfolio Value',
      value: formatUSD(Number(portfolio_value ?? 0)),
      explanation: `Buying power represents the total USD position you can open using your available balance with leverage .`,
    },
    stat2: {
      title: 'Available Balance',
      value: available_balance === null ? '-' : formatUSD(Number(available_balance)),
      explanation:
        'Available balance is the unused portion of your collateral in a trading account. It can be used for new trades or to cover potential losses, reducing the risk of liquidation.',
    },
    stat3: {
      title: 'Leverage',
      value: leverage === null ? '-' : `${leverage}x`,
      explanation: `Leverage allows you to control a larger market position with less capital. It can multiply both profits and losses, increasing the potential for higher returns as well as greater risks.`,
    },
    stat4: {
      title: 'Margin Usage',
      value: margin_usage === null ? '-' : `${margin_usage}%`,
      explanation: `Margin usage refers to the portion of your account’s equity used to maintain open positions. High margin usage can lead to margin calls or liquidation.`,
    },
  }

  return (
    <>
      <FaucetModal isOpen={isFaucetOpen} closeModal={() => setIsFaucetOpen(false)} />
      <div className="flex flex-1 flex-col gap-3 px-6">
        <p className="typography-text-9 text-white">Portfolio</p>
        <div className="mb-2 grid grid-cols-[1fr_1fr] gap-4">
          {Object.values(Stats).map((stat) => (
            <div
              className="flex flex-col items-start gap-1 whitespace-nowrap rounded-lg border bg-black-darkest px-4 py-3"
              key={stat.title}
            >
              <Explanation
                explanation={stat.explanation}
                trigger={
                  <p className="typography-label-1 border-b border-white-opaque text-white-opaque">
                    {stat.title}
                  </p>
                }
              />
              <p className="typography-label-1 text-white">{stat.value}</p>
            </div>
          ))}
        </div>
        <RequestFundsButton openModal={() => setIsFaucetOpen(true)} closeDropdown={() => {}} />
      </div>
    </>
  )
}
