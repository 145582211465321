import { type RefObject, useEffect, useRef } from 'react'

import { BigNumber, type ethers } from 'ethers'

export const copyStringToClipboard = (str: string) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('id', 'toBeCopied')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  // handle iOS as a special case
  if (navigator.userAgent.match(/ipad|ipod|iphone/i) != null) {
    copyElementToClipboard('toBeCopied')
    document.body.removeChild(el)
  } else {
    const selection = document.getSelection()
    if (selection != null) {
      const selected = selection.rangeCount > 0 ? selection.getRangeAt(0) : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        selection.removeAllRanges()
        selection.addRange(selected)
      }
    }
  }
}

const copyElementToClipboard = (elementId: string) => {
  const el = document.getElementById(elementId)
  const range = document.createRange()
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  el.contentEditable = true
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  el.readOnly = false
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
  range.selectNodeContents(el)
  const s = window.getSelection()
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  s.removeAllRanges()
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  s.addRange(range)
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  el.setSelectionRange(0, 999999) // A big number, to cover anything that could be inside the element.
  document.execCommand('copy')
}

export const useOutsideAlerter = (ref: RefObject<HTMLDivElement>, handleClick: () => void) => {
  const handleClickOutside = (e: MouseEvent) => {
    if (ref?.current && !ref.current.contains(e.target as Node)) handleClick()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
}

export const sendTransaction = async ({
  web3,
  transaction,
  txnHashCallback,
}: {
  web3: ethers.providers.JsonRpcSigner
  transaction: Record<string, string | BigNumber>
  txnHashCallback?: (hash: string) => void
}) => {
  try {
    const tx = await web3.sendTransaction({
      ...transaction,
    })
    const hash = tx.hash
    if (txnHashCallback) {
      txnHashCallback(hash)
    }
    const rec = await tx.wait()
    return rec
  } catch {
    return undefined
  }
}

export const isZero = (value: string | undefined) => {
  if (value === undefined) return true
  if (Number(value) === 0) return true
  return false
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
