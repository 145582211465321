import { copyStringToClipboard } from 'js/util/util'
import { useNavigate } from 'react-router-dom'
import { getPublicPoolName, usePublicPoolQuery } from './utils'
import PublicPoolOperatorActions from './PublicPoolOperatorActions'
import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'
import { toast } from 'sonner'
import Toast from 'js/shared-components/uikit/Toast'

const PublicPoolHeader = () => {
  const navigate = useNavigate()
  const publicPoolQuery = usePublicPoolQuery()
  const l1Address = publicPoolQuery.data.l1_address

  return (
    <div className="flex items-center justify-between max-mobile:flex-col max-mobile:items-start max-mobile:gap-2">
      <div>
        <div className="flex items-center gap-2">
          <Clickable onClick={() => navigate('/public-pools')}>
            <p className="typography-body-2 whitespace-nowrap text-white">Public Pools</p>
          </Clickable>
          <Icon icon="chevron" className="size-3 -rotate-90 text-white" />
          <p className="typography-body-2 line-clamp-1 text-white">
            {getPublicPoolName(publicPoolQuery.data)}
          </p>
        </div>
        <p className="typography-text-9 line-clamp-1 text-white">
          {getPublicPoolName(publicPoolQuery.data)}
        </p>
        {!!l1Address.trim() && (
          <Clickable
            onClick={() => {
              copyStringToClipboard(l1Address)
              toast.custom((toastId) => (
                <Toast
                  level="success"
                  description="Address copied to clipboard"
                  onClose={() => toast.dismiss(toastId)}
                />
              ))
            }}
          >
            <p className="typography-body-2 text-white">
              {l1Address.substring(0, 6).concat('...').concat(l1Address.slice(-4))}
            </p>
          </Clickable>
        )}
      </div>
      <PublicPoolOperatorActions />
    </div>
  )
}

export default PublicPoolHeader
