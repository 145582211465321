import { Explanation } from 'js/shared-components/ExplanationPopup'
import { type ChangeEventHandler } from 'react'
import Input from '../Input'
import { Clickable } from 'js/shared-components/Clickable'

// this should forwardRef and reuse the default input types
// but styled-components doesn't allow for that :/
interface PriceInputProps {
  // input props
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  placeholder: string
  setMidPrice: () => void

  // container props
  label: string
  helperText?: string
}

const PriceInput = ({ label, helperText, setMidPrice, ...rest }: PriceInputProps) => (
  <div className="flex flex-col gap-1">
    <div className="flex items-center gap-2">
      <p className="text-sm text-white">{label}</p>
      {!!helperText && <Explanation explanation={helperText} />}
    </div>
    <div className="flex w-full items-center justify-between rounded-lg border bg-white-transparent p-2">
      <Input
        {...rest}
        className="h-[unset] overflow-hidden rounded-none border-0 indent-0 text-sm placeholder:indent-0 placeholder:text-sm"
      />
      <Clickable color="blue" onClick={setMidPrice}>
        <p className="typography-body-1 font-bold">Mid</p>
      </Clickable>
    </div>
  </div>
)

export default PriceInput
