import { useAccountPositions, useAccountStats } from 'js/providers/accounts-store'
import { useOrderBookMetasQuery } from 'js/providers/hooks/order-book-metas-hooks'
import { useMarketsStats } from 'js/providers/order-book-store'
import type { Position } from 'js/providers/types'
import { useMemo } from 'react'

export const usePositionsWithLiqPrices = (accountIndex: number) => {
  const positions = useAccountPositions(accountIndex)
  const orderBookMetasQuery = useOrderBookMetasQuery()
  const marketStats = useMarketsStats()
  const totalAccountValue = useTotalAccountValue(accountIndex)
  const maintenanceMarginReq = useMaintenanceMarginReq(accountIndex)

  return useMemo(
    () =>
      Object.values(positions)
        .map((position) => {
          const market = marketStats[position.market_id]

          if (!market) {
            return position
          }

          return {
            ...position,
            liquidation_price: getPositionLiquidationPrice(
              position,
              Number(market.mark_price),
              orderBookMetasQuery.data[position.market_id]!.maintenance_margin_fraction / 10_000,
              totalAccountValue,
              maintenanceMarginReq,
            ),
          }
        })
        .reduce((acc, pos) => ({ ...acc, [pos.market_id]: pos }), {} as Record<string, Position>),
    [marketStats, orderBookMetasQuery.data, positions, totalAccountValue, maintenanceMarginReq],
  )
}

export const getPositionLiquidationPrice = (
  position: Position,
  markPrice: number,
  maintenanceMarginRate: number,
  totalAccountValue: number,
  maintenanceMarginReq: number,
) => {
  const positionSize = Number(position.position)
  /*
    MMR: maintenance margin requirement
    TAV: total account value
    liquidationPrice: Mark price which user needs to get liquidated, assuming everything else stays the same for cross margin
    maintenanceMarginFraction: 5%, for partial liquidation
    positionSize: size of the open position (positive if long, negative if short)
    S: max(positionSize + bid size, ask size - positionSize)
    
    newMMR = MMR + S * liquidationPrice * maintenanceMarginFraction - S * markPrice * maintenanceMarginFraction
    newTAV = TAV + positionSize * (liquidationPrice - avgEntryPrice) - positionSize * (markPrice - avgEntryPrice)

    newMMR = MMR + S * maintenanceMarginFraction * (liquidationPrice - markPrice)
    newTAV = TAV + positionSize * (liquidationPrice - markPrice)

    newMMR = newTAV
    MMR + S * maintenanceMarginFraction * (liquidationPrice - markPrice) = TAV + positionSize * (liquidationPrice - markPrice)
    MMR - TAV = positionSize * (liquidationPrice - markPrice) - S * maintenanceMarginFraction * (liquidationPrice - markPrice)
    MMR - TAV = (positionSize - S * maintenanceMarginFraction) * (liquidationPrice - markPrice)
    (MMR - TAV) / (positionSize - S * maintenanceMarginFraction) = liquidationPrice - markPrice
    liquidationPrice = markPrice - (TAV - MMR) / (positionSize - S * maintenanceMarginFraction)
  */

  const numerator = totalAccountValue - maintenanceMarginReq
  const denominator = positionSize * position.sign - positionSize * maintenanceMarginRate

  if (numerator <= 0) {
    // If numerator is negative, TAV <= MMR, which means the account needs to be liquidated
    return markPrice.toFixed(2)
  }

  if (denominator === 0) {
    return undefined
  }

  const liquidationPrice = markPrice - numerator / denominator

  if (isNaN(liquidationPrice) || !isFinite(liquidationPrice) || liquidationPrice < 0) {
    return undefined
  }

  if (
    (position.sign === 1 && liquidationPrice >= markPrice) ||
    (position.sign === -1 && liquidationPrice <= markPrice)
  ) {
    return markPrice.toFixed(2)
  }

  return liquidationPrice.toFixed(2)
}

export const useTotalAccountValue = (accountIndex: number) => {
  const totalUnrealizedPnl = useAccountTotalUnrealizedPnL(accountIndex)
  const portfolio_value = useAccountStats(accountIndex)?.portfolio_value ?? '0'

  return Number(portfolio_value) + totalUnrealizedPnl
}

export const useMaintenanceMarginReq = (accountIndex: number) => {
  const positions = useAccountPositions(accountIndex)
  const orderBookMetasQuery = useOrderBookMetasQuery()
  const marketsStats = useMarketsStats()

  return Object.values(positions).reduce((marginReq, position) => {
    const market = marketsStats[position.market_id]

    if (!market) {
      return marginReq
    }

    const maintenanceMarginRate =
      orderBookMetasQuery.data[position.market_id]!.maintenance_margin_fraction / 10_000
    const positionSize = Number(position.position)
    const markPrice = Number(market.mark_price)

    return marginReq + positionSize * markPrice * maintenanceMarginRate
  }, 0)
}

export const useAccountTotalUnrealizedPnL = (accountIndex: number) => {
  const positions = useAccountPositions(accountIndex)
  const marketsStats = useMarketsStats()

  return Object.values(positions).reduce((pnl, position) => {
    const market = marketsStats[position.market_id]

    if (!market) {
      return pnl
    }

    return pnl + getPositionPnl(position, Number(market.mark_price))
  }, 0)
}

export const getPositionPnl = (position: Position, markPrice: number) => {
  const positionSize = Number(position.position)
  const avgEntryPrice = Number(position.avg_entry_price)

  return positionSize * (markPrice - avgEntryPrice) * position.sign
}
