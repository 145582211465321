import { useRef, useState, useEffect, useMemo } from 'react'

import { MarketSelector } from 'js/pages/trade/components/info-box/market-selector/MarketSelector'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'

import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { isBefore, differenceInMilliseconds, add as addDuration, formatDate } from 'date-fns'
import { useMarketsStats } from 'js/providers/order-book-store'
import { USDC_SYMBOL } from 'js/constants/shared'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import cn from 'js/util/cn'
import Icon from 'js/shared-components/uikit/Icon'

type InfoItem = {
  key: string
  title: string
  value: string
  explanation?: string
}

export const InfoBox = () => {
  const currentMarket = useCurrentMarket()
  const infoBoxRef = useRef<HTMLDivElement>(null)
  const marketsStats = useMarketsStats()
  const marketStatsLoading = Object.keys(marketsStats).length === 0
  const marketStats = marketsStats[currentMarket.market_id]

  const indexPrice = useMemo(
    () =>
      marketStats?.index_price
        ? Number(marketStats.index_price).toLocaleString('en-US', {
            maximumFractionDigits: currentMarket.supported_price_decimals,
            minimumFractionDigits: currentMarket.supported_price_decimals,
            style: 'currency',
            currency: 'USD',
          })
        : '-',
    [marketStats?.index_price, currentMarket.supported_price_decimals],
  )
  const markPrice = useMemo(
    () =>
      marketStats?.mark_price
        ? Number(marketStats.mark_price).toLocaleString('en-US', {
            maximumFractionDigits: currentMarket.supported_price_decimals,
            minimumFractionDigits: currentMarket.supported_price_decimals,
            style: 'currency',
            currency: 'USD',
          })
        : '-',
    [marketStats?.mark_price, currentMarket.supported_price_decimals],
  )
  const funding_rate = useMemo(
    () => (marketStats?.funding_rate ? `${marketStats?.funding_rate}%` : '-'),
    [marketStats?.funding_rate],
  )

  const dayLow = useMemo(
    () =>
      marketStats?.daily_price_low.toLocaleString('en-US', {
        maximumFractionDigits: currentMarket.supported_price_decimals,
        minimumFractionDigits: currentMarket.supported_price_decimals,
        style: 'currency',
        currency: 'USD',
      }) ?? '-',
    [marketStats?.daily_price_low, currentMarket.supported_price_decimals],
  )
  const dayHigh = useMemo(
    () =>
      marketStats?.daily_price_high.toLocaleString('en-US', {
        maximumFractionDigits: currentMarket.supported_price_decimals,
        minimumFractionDigits: currentMarket.supported_price_decimals,
        style: 'currency',
        currency: 'USD',
      }) ?? '-',
    [marketStats?.daily_price_high, currentMarket.supported_price_decimals],
  )

  const dayVolume0 = useMemo(
    () =>
      marketStats?.daily_base_token_volume.toLocaleString('en-US', {
        minimumFractionDigits: marketStats.daily_base_token_volume > 1000 ? 0 : 2,
        maximumFractionDigits: 2,
      }) ?? '-',
    [marketStats?.daily_base_token_volume],
  )
  const dayVolume1 = useMemo(
    () =>
      marketStats?.daily_quote_token_volume.toLocaleString('en-US', {
        minimumFractionDigits: marketStats.daily_base_token_volume > 1000 ? 0 : 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'USD',
      }) ?? '-',
    [marketStats?.daily_quote_token_volume],
  )
  const roundedDiffPct = parseFloat((marketStats?.daily_price_change ?? 0).toFixed(2))
  const [currentTimestamp, setCurrentTimestamp] = useState(new Date())
  const nextFunding = useMemo(() => {
    const fundingTime = addDuration(
      typeof marketStats?.funding_timestamp === 'number'
        ? new Date(marketStats.funding_timestamp)
        : new Date(),
      { hours: 1 },
    )

    if (isBefore(currentTimestamp, fundingTime)) {
      return formatDate(differenceInMilliseconds(fundingTime, currentTimestamp), 'mm:ss')
    }

    return '00:00'
  }, [marketStats?.funding_timestamp, currentTimestamp])

  useEffect(() => {
    const t = setInterval(() => setCurrentTimestamp(new Date()), 1000)

    return () => clearInterval(t)
  }, [])

  const infoItems: InfoItem[] = [
    {
      key: 'markPrice',
      title: 'Mark Price',
      value: markPrice,
      explanation: `Mark price is the fair price of a perpetual contract, based on the order book state and index price. It is used for calculating margin requirements, unrealized PnL, and liquidations.`,
    },
    {
      key: 'indexPrice',
      title: 'Index Price',
      value: indexPrice,
      explanation: `Index price represents the real-time market price of the underlying asset in spot markets.`,
    },
    { key: 'high', title: '24h High', value: dayHigh },
    { key: 'low', title: '24h Low', value: dayLow },
    {
      key: 'change',
      title: '24h Change',
      value: `${roundedDiffPct}%`,
    },
    { key: 'volumeToken0', title: `24h Volume (${currentMarket.symbol})`, value: dayVolume0 },

    { key: 'volumeToken1', title: `24h Volume (${USDC_SYMBOL})`, value: dayVolume1 },
    {
      key: 'fundingRate',
      title: '1hr Funding',
      value: funding_rate,
      explanation: `Funding payments occur hourly based on the contract and index price difference. Longs pay shorts if the rate is positive, and shorts pay longs if negative. Funding is peer-to-peer, with no fees taken by the exchange.`,
    },
    {
      key: 'nextFunding',
      title: 'Next Funding',
      value: nextFunding,
      explanation: `Remaining time until the next funding payment, which occurs hourly.`,
    },
  ]

  return (
    <div
      className="flex w-full items-center gap-6 py-2 max-tablet:grid-cols-[[col1]_minmax(140px,_300px)_[col2]_minmax(400px,_100%)]"
      ref={infoBoxRef}
    >
      <MarketSelector />
      <div className="w-full max-tablet:max-w-full max-tablet:overflow-x-hidden">
        <div
          className={cn(
            'flex h-10 w-full flex-[3] justify-between overflow-x-scroll max-tablet:justify-start',
          )}
          data-testid="stats"
        >
          {infoItems.map((item) => (
            <InfoItem
              item={item}
              key={item.title}
              isLoading={marketStatsLoading}
              roundedDiffPct={roundedDiffPct}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

interface InfoItemProps {
  item: InfoItem
  isLoading: boolean
  roundedDiffPct: number
}

const InfoItem = ({ item, isLoading, roundedDiffPct }: InfoItemProps) => (
  <div className="flex max-h-full items-center gap-3 px-4 py-1 max-tablet:w-fit">
    <div className="flex max-h-full min-w-14 flex-col whitespace-nowrap">
      <Tooltip open={!item.explanation ? false : undefined}>
        <TooltipTrigger asChild>
          <p
            className={cn('typography-body-1 text-white-opaque', {
              'underline decoration-dotted': !!item.explanation,
            })}
          >
            {item.title}
          </p>
        </TooltipTrigger>
        <TooltipContent>
          <p className="typography-body-2 text-white">{item.explanation}</p>
        </TooltipContent>
      </Tooltip>

      <div className="flex items-center gap-1">
        {!isLoading ? (
          <p className="typography-body-1 text-white">{item.value}</p>
        ) : (
          <SkeletonRectangle className="h-3.5 w-16 min-w-16 rounded-sm" />
        )}
        {!isLoading && item.key === 'change' && roundedDiffPct !== 0 && (
          <Icon
            icon="triangle"
            className={cn('size-2', {
              'text-green-main rotate-180': roundedDiffPct > 0,
              'text-red-main rotate-0': roundedDiffPct < 0,
            })}
          />
        )}
      </div>
    </div>
  </div>
)
