export const USER_ORDERS_TABS = {
  positions: { title: 'Positions' },
  activeOrders: { title: 'Open Orders' },
  orderHistory: { title: 'Order History' },
  tradeHistory: { title: 'Trade History' },
} as const

export const ORDER_SIDE_TABS = {
  all: { title: 'All', icon: 'allOrders' },
  asks: { title: 'Asks', icon: 'asks' },
  bids: { title: 'Bids', icon: 'bids' },
} as const

export type OrderSide = keyof typeof ORDER_SIDE_TABS
