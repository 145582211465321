import { ORDER_SIDE_TABS, type OrderSide } from 'js/constants/trades'
import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'
import { useState, useRef, useEffect } from 'react'

import { MarketSelector } from './MarketSelector'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import { Backdrop } from 'js/shared-components/Backdrop'
import { SmallArrow } from 'js/shared-components/SmallArrow'

import { OrderHistoryTable } from 'js/shared-components/tables/OrderHistoryTable'
import { ActiveOrderTable } from 'js/shared-components/tables/ActiveOrderTable'
import { PositionsTable } from 'js/shared-components/tables/PositionsTable'
import { FundingHistoryTable } from 'js/shared-components/tables/FundingHistoryTable'
import { OrderListContainer } from '../trade/components/orders/styles'
import TradeTablesBlocker from '../trade/components/orders/user-orders/TradeTablesBlocker'
import { useAccountIndex } from 'js/providers/user-store'
import cn from 'js/util/cn'
import type { OrderBookDetail } from 'zklighter-perps'
import { useIsRegisteredQuery } from 'js/providers/hooks/useAccountQuery'
import { SelectorDropdown } from 'js/shared-components/SelectorDropdown'
import { useResponsiveness } from 'js/ResponsivenessProvider'

const TABS = {
  positions: { title: 'Positions' },
  activeOrders: { title: 'Open Orders' },
  orderHistory: { title: 'Order History' },
  fundingHistory: { title: 'Funding History' },
} as const

type TabType = keyof typeof TABS

export const UserTransactions = () => {
  const accountIndex = useAccountIndex()
  const [selectedTab, setSelectedTab] = useState<TabType>('positions')
  const [selectedSide, setSelectedSide] = useState<OrderSide>('all')
  const [selectedMarket, setSelectedMarket] = useState<OrderBookDetail | null>(null)
  const [indicatorLeft, setIndicatorLeft] = useState(12)
  const [indicatorWidth, setIndicatorWidth] = useState(56)
  const [isSelectorOpen, setIsSelectorOpen] = useState(false)

  const { isMobile } = useResponsiveness()
  const isRegistered = useIsRegisteredQuery().data

  const useRefs = () => {
    const refsByKey = useRef<Record<string, HTMLElement | null>>({})

    const setRef = (element: HTMLElement | null, key: number) => {
      refsByKey.current[key] = element
    }

    return { refsByKey: refsByKey.current, setRef }
  }

  const { refsByKey, setRef } = useRefs()

  const refs = Object.values(refsByKey).filter(Boolean)

  const selectedTabIndex = Object.keys(TABS).findIndex((tab) => tab === selectedTab)!

  const sideOptions = Object.entries(ORDER_SIDE_TABS).map(([side, { icon, title }]) => ({
    key: side,
    title,
    icon,
    onClick: () => setSelectedSide(side as OrderSide),
  }))

  useEffect(() => {
    if (refs[selectedTabIndex]) {
      const rect = refs[selectedTabIndex]!.getBoundingClientRect()
      setIndicatorLeft(refs[selectedTabIndex]!.offsetLeft)
      setIndicatorWidth(rect.width)
    }
  }, [selectedTabIndex, refs[selectedTabIndex]])

  return (
    <>
      <Backdrop
        fullScreen
        className="top-0 h-full"
        isVisible={isSelectorOpen}
        onClick={() => setIsSelectorOpen(false)}
      />
      <div className="flex size-full max-h-full flex-col justify-start max-tablet:min-h-[300px] mobile:relative">
        <TabBar className="h-[unset] min-h-[unset] items-center justify-between max-mobile:p-0">
          {!isMobile && (
            <div className="relative flex gap-6 px-3">
              <SelectorIndicator
                left={indicatorLeft}
                width={indicatorWidth}
                numButtons={Object.values(TABS).length}
                selectedElemIdx={selectedTabIndex}
              />
              {Object.entries(TABS).map(([tab, { title }], index) => (
                <TabButton
                  setRef={setRef}
                  id={index}
                  key={tab}
                  isSelected={false}
                  onClick={() => setSelectedTab(tab as TabType)}
                  className={cn({ 'text-white': selectedTab === tab })}
                >
                  {title}
                </TabButton>
              ))}
            </div>
          )}
          {isMobile && (
            <div className="flex gap-6 px-3">
              <div>
                <div
                  className="flex items-center gap-2"
                  onClick={() => setIsSelectorOpen((prev) => !prev)}
                >
                  <TabButton isSelected={false}>
                    <p className="typography-label-1 text-white">{TABS[selectedTab].title}</p>
                  </TabButton>
                  <SmallArrow direction="down" />
                </div>
                <BottomAnimatedDropdown isOpen={isSelectorOpen} className="z-[101]">
                  {Object.entries(TABS).map(([tab, { title }]) => (
                    <TabButton
                      key={tab}
                      className="h-[unset] py-4"
                      isSelected={false}
                      onClick={() => {
                        setSelectedTab(tab as TabType)
                        setIsSelectorOpen(false)
                      }}
                    >
                      <p className="typography-label-1 text-white">{title}</p>
                    </TabButton>
                  ))}
                </BottomAnimatedDropdown>
              </div>
            </div>
          )}
          <div className="flex h-full items-center gap-2 pr-3">
            <MarketSelector selectedMarket={selectedMarket} setSelectedMarket={setSelectedMarket} />
            <SelectorDropdown options={sideOptions} selectedOption={selectedSide} />
          </div>
        </TabBar>
        <OrderListContainer>
          <TradeTablesBlocker>
            {selectedTab === 'positions' && (
              <PositionsTable
                accountIndex={accountIndex!}
                showCloseColumn={!!isRegistered}
                selectedMarket={selectedMarket}
                setSelectedMarket={setSelectedMarket}
                selectedSide={selectedSide}
              />
            )}
            {selectedTab === 'activeOrders' && (
              <ActiveOrderTable
                accountIndex={accountIndex!}
                showCancelColumn
                selectedSide={selectedSide}
                selectedMarket={selectedMarket}
                setSelectedMarket={setSelectedMarket}
              />
            )}
            {selectedTab === 'orderHistory' && (
              <OrderHistoryTable
                accountIndex={accountIndex!}
                selectedSide={selectedSide}
                selectedMarket={selectedMarket}
                setSelectedMarket={setSelectedMarket}
              />
            )}
            {selectedTab === 'fundingHistory' && (
              <FundingHistoryTable
                accountIndex={accountIndex!}
                selectedSide={selectedSide}
                selectedMarket={selectedMarket}
                setSelectedMarket={setSelectedMarket}
              />
            )}
          </TradeTablesBlocker>
        </OrderListContainer>
      </div>
    </>
  )
}
