import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './uikit/DropdownMenu'
import type { IconType } from './uikit/Icon'
import Icon from './uikit/Icon'

interface SelectorDropdownProps {
  selectedOption: string
  options: { key: string; title: string; onClick: () => void; icon?: IconType }[]
}

export const SelectorDropdown = ({ selectedOption, options }: SelectorDropdownProps) => {
  const selectedItem = options.find(({ key }) => key === selectedOption)!

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={
          'flex items-center gap-2 rounded-full bg-semi-transparent px-3 py-1 hover:bg-grey-main data-[state=open]:bg-grey-main'
        }
      >
        {selectedItem.icon && <Icon icon={selectedItem.icon} className="size-5" />}
        {selectedItem.title}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-0 bg-semi-transparent">
        {options.map((option) => (
          <DropdownMenuItem
            key={option.title}
            onClick={option.onClick}
            className="flex items-center gap-2 px-2 py-1"
          >
            {option.icon && <Icon icon={option.icon} className="size-5" />}
            {option.title}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
