// TODO: export these from tailwind config and use if needed
export const fonts = {
  satoshi: 'Satoshi-Variable, -apple-system, BlinkMacSystemFont, sans-serif;',
}

export const colors = {
  black: '#06060C',
  blackDarkest: '#0d0e16',
  blackTransparent: 'rgba(6, 6, 12, 0.3)',

  greyDark: '#1f2128',
  blueGrey: '#262D44',
  greyMain: '#2F343E',
  greyMedium: '#39393B',
  greyLight: '#444554',

  whiteMain: '#FFFFFF',
  whiteOpaque: '#B0B7C3',
  whiteTrasparent: '#FFFFFF0D',
  semiTransparent: '#FFFFFF1A',

  greenMain: '#4AB683',
  greenPale: '#03f78528',
  greenDark: '#31502F',

  redMain: '#E85866',
  redPale: '#fa506147',
  redDark: '#513134',

  primaryBlueMain: '#5698FA',
  primaryBluePale: '#3a68ac',

  secondaryBlueMain: '#1A34A6',
  secondaryBluePale: '#18255B',

  purple: '#5B27F0',
} as const
