import { Backdrop } from 'js/shared-components/Backdrop'
import { Modal } from 'js/shared-components/Modal'
import { AccountSelector } from './AccountSelector'
import { useState } from 'react'
import { Button } from 'js/shared-components'
import { AmountInput } from './AmountInput'
import { transfer } from 'js/zklighter-js-sdk/sdk'
import { useMainAccount, useSubAccounts, useUserAccount } from 'js/providers/hooks/useAccountQuery'
import { useMutation } from '@tanstack/react-query'
import { SubAccountType } from 'js/providers/types'
import WarningContainer from 'js/shared-components/WarningContainer'
import { toast } from 'sonner'
import Toast from 'js/shared-components/uikit/Toast'

const AMOUNT_DECIMALS = 6

interface TransferModalProps {
  closeModal: () => void
}

export const TransferModal = ({ closeModal }: TransferModalProps) => {
  const [toAccountIndex, setToAccountIndex] = useState<number | null>(null)
  const [selectedAmount, setSelectedAmount] = useState('')
  const subAccounts = useSubAccounts().filter(
    ({ account_type }) => account_type === SubAccountType.sub,
  )
  const userAccount = useUserAccount()!
  const mainAccount = useMainAccount()!
  const isButtonDisabled =
    !userAccount || !toAccountIndex || !selectedAmount || Number(selectedAmount) < 10

  const transferMutation = useMutation({
    mutationFn: () =>
      transfer({
        accountIndex: userAccount.index,
        toAccountIndex: toAccountIndex!,
        collateralAmount: Number(selectedAmount) * 10 ** AMOUNT_DECIMALS,
      }),
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Transfer successful"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => closeModal(),
  })

  return (
    <>
      <Backdrop isVisible fullScreen />
      <Modal isOpen modalTitle="Transfer" onClose={closeModal}>
        <div className="flex w-full flex-col gap-3">
          <div className="flex w-full flex-col gap-1">
            <p className="text-sm text-white">Transfer from</p>
            <div className="w-full rounded-lg border bg-white-transparent p-2">
              <p className="typography-label-1 text-white">
                {mainAccount.index === userAccount?.index ? 'Main Account' : userAccount.name}
              </p>
            </div>
          </div>
          <AccountSelector
            accountsList={[mainAccount, ...subAccounts].filter(
              (account) => account.index !== userAccount.index,
            )}
            onAccountChange={setToAccountIndex}
            selectedAccountIndex={toAccountIndex}
            direction="to"
          />
          <AmountInput
            value={selectedAmount}
            onChange={(amount) => setSelectedAmount(amount)}
            decimal={2}
          />
          <Button
            disabled={isButtonDisabled}
            isLoading={transferMutation.isPending && !transferMutation.isError}
            className="w-full"
            onClick={() => transferMutation.mutate()}
          >
            Transfer
          </Button>
          {Number(selectedAmount) !== 0 && Number(selectedAmount) < 10 && (
            <WarningContainer>
              <p className="typography-body-2 text-white">Minimum transfer amount is 10</p>
            </WarningContainer>
          )}
        </div>
      </Modal>
    </>
  )
}
