import { useEffect, useMemo, useState } from 'react'
import { fromUnixTime, getUnixTime, formatDate, sub as subDuration } from 'date-fns'

import { colors, fonts } from 'css/css'

import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'
import { useEchart } from 'js/shared-components/useEchart'
import { useQuery } from '@tanstack/react-query'
import { accountApi } from 'js/util/api/sdk'
import { useAccountsQuery, useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { ChartLoader } from '../pages/trade/components/chart/ChartLoader'
import cn from 'js/util/cn'

const timelines = {
  '1d': { name: '1D', value: 1, timeMeasure: 'days', resolution: '1h' },
  '1w': { name: '1W', value: 1, timeMeasure: 'weeks', resolution: '1h' },
  '1m': { name: '1M', value: 1, timeMeasure: 'months', resolution: '4h' },
} as const

interface PnlBalanceChartsProps {
  accountIndex: number | null
}

export const PnlBalanceCharts = ({ accountIndex }: PnlBalanceChartsProps) => {
  const [activeChart, setActiveChart] = useState<'pnl' | 'balance'>('balance')
  const [timeline, setTimeline] = useState<keyof typeof timelines>('1d')

  const userAddress = useUserAddress()
  const dyn = useDynamicContext()
  const accountsQuery = useAccountsQuery()
  const isAccountLoading = accountsQuery.isLoading || !dyn.sdkHasLoaded

  const startTime = useMemo(
    () =>
      getUnixTime(
        subDuration(new Date(), {
          [timelines[timeline].timeMeasure]: timelines[timeline].value,
        }),
      ),
    [timeline],
  )
  const endTime = useMemo(() => getUnixTime(new Date()), [])

  const params = useMemo(
    () => ({
      by: 'index' as const,
      value: accountIndex?.toString() as string,
      resolution: timelines[timeline].resolution,
      start_timestamp: startTime,
      end_timestamp: endTime,
      count_back: 0,
    }),
    [accountIndex, timeline, startTime, endTime],
  )
  const pnlQuery = useQuery({
    queryKey: ['pnl', params],
    queryFn: () => accountApi.pnl(params),
    enabled: accountIndex !== null,
    staleTime: 1000000,
  })

  const userBalance: [number, number][] = useMemo(
    () => (pnlQuery.data?.pnl ?? []).map((d) => [d.timestamp, d.value]),
    [pnlQuery.data],
  )
  const pnl: [number, number][] = useMemo(
    () =>
      userBalance
        .map((d, i) => [d[0], (userBalance[i]?.at(1) ?? 0) - (userBalance[i - 1]?.at(1) ?? 0)])
        .slice(1) as [number, number][],
    [userBalance],
  )

  const chartData = useMemo(
    () => (activeChart === 'pnl' ? pnl : userBalance).map((d) => [fromUnixTime(d[0]), d[1]]),
    [activeChart, pnl, userBalance],
  )

  const chartOpts = useMemo(
    () => ({
      textStyle: { fontFamily: fonts.satoshi },
      grid: { left: 12, right: 60, top: 10, bottom: 60 },
      tooltip: {
        trigger: 'axis',
        backgroundColor: colors.greyMain,
        borderWidth: 0,
        padding: 3,
        formatter: (d: { data: [number, number] }[]) => {
          if (d.length === 0) {
            return null
          }

          const [ts, value] = d[0]!.data
          const containerClassName = cn(
            'relative flex min-w-[250px] flex-col gap-1 p-4',
            'before:absolute before:left-0 before:top-0 before:h-full before:w-0.5',
            { 'before:bg-green-main': value > 0, 'before:bg-red-main': value < 0 },
          )
          const formattedPnl = value.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })

          return `
            <div class="${containerClassName}">
              <div class="text-white"">
                ${formatDate(ts, "dd MMM''yy HH:mm")}
              </div>
              <div class="flex justify-between">
                <span class="text-white-opaque">${activeChart === 'pnl' ? 'PnL' : 'Balance'}</span>
                <span class="text-white">$${formattedPnl}</span>
              </div>
            </div>
          `
        },
      },
      xAxis: {
        type: 'time',
        show: true,
        min: chartData[0]?.[0],
        max: chartData[chartData.length - 1]?.[0],
        axisLine: { onZero: false },
      },
      yAxis: {
        show: true,
        position: 'right',
        min: Number(Math.min(...chartData.map((d) => Number(d[1]))))?.toFixed(2),
        max: Number(Math.max(...chartData.map((d) => Number(d[1]))))?.toFixed(2),
        axisLine: { onZero: false },
      },
      series: [
        {
          name: 'PnL',
          encode: { x: 'timestamp', y: 'value' },
          type: 'line',
          data: chartData,
          lineStyle: { color: colors.greenMain, width: 0.8 },
        },
      ],
    }),
    [chartData, activeChart],
  )

  const { chartContainerRef, disposeRef } = useEchart(chartOpts, isAccountLoading)

  useEffect(() => {
    return () => {
      disposeRef.current()
    }
  }, [])

  return (
    <>
      <TabBar>
        <SelectorIndicator numButtons={2} selectedElemIdx={activeChart === 'balance' ? 0 : 1} />
        <TabButton isSelected={activeChart === 'balance'} onClick={() => setActiveChart('balance')}>
          Balance
        </TabButton>
        <TabButton isSelected={activeChart === 'pnl'} onClick={() => setActiveChart('pnl')}>
          PnL
        </TabButton>
      </TabBar>
      <div className="relative h-full max-w-full">
        <div className="size-full">
          <div className="flex h-full flex-col">
            <div className="flex gap-3 self-end p-3">
              {Object.entries(timelines).map(([key, value]) => (
                <p
                  className={cn(
                    'typography-body-1 cursor-pointer transition-all hover:text-white',
                    {
                      'text-primary-blue-main': timeline === key,
                      'text-white-opaque': timeline !== key,
                    },
                  )}
                  key={key}
                  // Object.keys/entries/values/etc don't play well with const objects
                  onClick={() => setTimeline(key as typeof timeline)}
                >
                  {value.name}
                </p>
              ))}
            </div>
            {isAccountLoading ? (
              <ChartLoader />
            ) : (
              <div className="flex size-full min-h-[200px] flex-col">
                {accountIndex !== null ? (
                  <div className="absolute inset-x-0 bottom-0 top-10" ref={chartContainerRef} />
                ) : (
                  <div className="flex size-full flex-col items-center justify-center">
                    <p className="typography-text-6 text-white">
                      {!userAddress
                        ? 'Please connect your wallet'
                        : 'Request funds to create an account'}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
