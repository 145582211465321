import { useMarketsStats } from 'js/providers/order-book-store'
import type { Position } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import cn from 'js/util/cn'
import { formatUSD } from 'js/util/formatting'
import { getPositionPnl } from 'js/util/positions'

interface PositionUnrealizedPnlCellProps {
  position: Position
}

const PositionUnrealizedPnlCell = ({ position }: PositionUnrealizedPnlCellProps) => {
  const marketsStats = useMarketsStats()
  const mark_price = marketsStats[position.market_id]?.mark_price
  const unrealizedPnl = getPositionPnl(position, Number(mark_price))

  return (
    <TableCell>
      <p
        className={cn('typography-body-1', {
          'text-red-main': unrealizedPnl < 0,
          'text-green-main': unrealizedPnl > 0,
          'text-white': unrealizedPnl === 0,
        })}
      >
        {formatUSD(unrealizedPnl, { signDisplay: 'exceptZero' })}
      </p>
    </TableCell>
  )
}

export default PositionUnrealizedPnlCell
