import { Button } from 'js/shared-components'
import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { forwardRef } from 'react'
import { Clickable } from './Clickable'
import Icon from './uikit/Icon'
import { useResponsiveness } from 'js/ResponsivenessProvider'

export const MetamaskLogin = forwardRef<HTMLButtonElement>((_, forwardedRef) => {
  const { isMobile } = useResponsiveness()
  const { setShowAuthFlow, sdkHasLoaded } = useDynamicContext()

  const login = () => setShowAuthFlow(true)

  if (isMobile) {
    if (!sdkHasLoaded) {
      return <Icon icon="spinner" className="size-5" />
    }

    return (
      <Clickable ref={forwardedRef} onClick={login}>
        <Icon icon="wallet" className="size-5" />
      </Clickable>
    )
  }

  return (
    <Button className="gap-2" ref={forwardedRef} isLoading={!sdkHasLoaded} onClick={login}>
      <Icon icon="wallet" className="size-5" />
      Connect Wallet
    </Button>
  )
})

MetamaskLogin.displayName = 'MetamaskLogin'
