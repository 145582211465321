import Table from 'js/shared-components/uikit/table/Table'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import TableCell from 'js/shared-components/uikit/table/TableCell'

import PublicPoolNameCell from './cells/PublicPoolNameCell'
import PublicPoolLeaderCell from './cells/PublicPoolLeaderCell'
import PublicPoolTVLCell from './cells/PublicPoolTVLCell'
import PublicPoolUserDepositCell from './cells/PublicPoolUserDepositCell'
import PublicPoolOperatorFeeCell from './cells/PublicPoolOperatorFeeCell'
import { useNavigate } from 'react-router-dom'
import { useInfiniteQuery } from '@tanstack/react-query'
import { accountApi } from 'js/util/api/sdk'
import { useState } from 'react'
import { PageSelector } from 'js/pages/trade/components/orders/user-orders/PageSelector'
import HeaderCell from 'js/shared-components/HeaderCell'
import Icon from 'js/shared-components/uikit/Icon'
import TableBody from 'js/shared-components/uikit/table/TableBody'

const PAGE_SIZE = 10

const UserPublicPoolList = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)

  const publicPoolListQuery = useInfiniteQuery({
    queryKey: ['public_pools'],
    queryFn: ({ pageParam }) =>
      accountApi.publicPools({ limit: PAGE_SIZE, index: pageParam, filter: 'user' }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.public_pools.at(-1)?.index,
    refetchInterval: 5000,
  })

  if (publicPoolListQuery.isPending) {
    return (
      <div className="flex h-[400px] items-center justify-center">
        <Icon icon="spinner" className="size-8" />
      </div>
    )
  }

  if (publicPoolListQuery.isError) {
    return <p className="typography-body-1 text-white">There was an error loading the list</p>
  }

  const totalPages = Math.ceil((publicPoolListQuery.data.pages[0]?.total ?? 0) / PAGE_SIZE)
  const userPublicPools =
    publicPoolListQuery.data.pages[publicPoolListQuery.isFetchingNextPage ? page - 1 : page]
      ?.public_pools ?? []

  if (userPublicPools.length === 0) {
    return <p className="typography-body-1 text-white">No User Public Pool</p>
  }

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderRow className="static border-b bg-transparent shadow-none backdrop-filter-none">
            <HeaderCell title="Public Pool" />
            <HeaderCell title="Leader" />
            <HeaderCell title="TVL" />
            <HeaderCell title="Operator Fee" className="justify-end" />
            <HeaderCell title="Your Deposit" className="justify-end" />
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {userPublicPools.map((publicPool) => (
            <TableRow
              key={publicPool.index}
              onClick={() => navigate(`/public-pools/${publicPool.index}`)}
            >
              <PublicPoolNameCell publicPool={publicPool} />
              <PublicPoolLeaderCell publicPool={publicPool} />
              <PublicPoolTVLCell publicPool={publicPool} />
              <PublicPoolOperatorFeeCell publicPool={publicPool} />
              <PublicPoolUserDepositCell publicPool={publicPool} />
            </TableRow>
          ))}
          {page + 1 === totalPages &&
            new Array((PAGE_SIZE - ((userPublicPools.length % PAGE_SIZE) % PAGE_SIZE)) % PAGE_SIZE)
              .fill(null)
              .map((_, index) => (
                <TableRow key={`${index}-fake`} className="opacity-0">
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              ))}
        </TableBody>
      </Table>
      {totalPages > 1 && (
        <PageSelector
          page={page}
          setPage={(newPage) => {
            if (publicPoolListQuery.data.pages.length < newPage + 1) {
              publicPoolListQuery.fetchNextPage()
            }
            setPage(newPage)
          }}
          totalPages={totalPages}
          hasNextPage={page + 1 !== totalPages && !publicPoolListQuery.isFetchingNextPage}
        />
      )}
    </>
  )
}

export default UserPublicPoolList
