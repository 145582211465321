import { forwardRef, type HTMLAttributes } from 'react'

import cn from 'js/util/cn'

interface BottomAnimatedDropdownProps extends HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean
}

export const BottomAnimatedDropdown = forwardRef<HTMLDivElement, BottomAnimatedDropdownProps>(
  ({ className, isOpen = false, ...rest }, forwardedRef) => (
    <div
      ref={forwardedRef}
      className={cn(
        'absolute inset-x-0 bottom-0 z-10 w-full origin-bottom animate-[growUp_150ms_ease-in-out_forwards] bg-black/30 shadow-dark-top backdrop-blur-xl transition-all',
        'max-mobile:fixed',
        { 'flex flex-col': isOpen, hidden: !isOpen },
        className,
      )}
      {...rest}
    />
  ),
)

BottomAnimatedDropdown.displayName = 'BottomAnimatedDropdown'
