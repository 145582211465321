import { useRef, useState } from 'react'
import { animate, stagger } from 'motion'

import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'

import { TradingViewChart } from './TradingViewChart'
import { DepthChart } from './DepthChart'
import { FundingRateChart } from './FundingRateChart'
import { MarketDetails } from './MarketDetails'
import cn from 'js/util/cn'

const Tabs = {
  price: { name: 'Price', id: 0 },
  depth: { name: 'Depth', id: 1 },
  funding: { name: 'Funding', id: 2 },
  details: { name: 'Details', id: 3 },
}

export const Chart = () => {
  const [activeChart, setActiveChart] = useState<{ name: string; id: number }>(Tabs.price)
  const chartRef = useRef<HTMLDivElement>(null)

  const changeChart = (to: { name: string; id: number }) => {
    if (!chartRef || chartRef.current == null) return
    animate(chartRef.current, { opacity: [0, 1] }, { delay: stagger(5), duration: 0.8 })
    setActiveChart(to)
  }

  return (
    <>
      <TabBar>
        <SelectorIndicator numButtons={Object.keys(Tabs).length} selectedElemIdx={activeChart.id} />
        {Object.entries(Tabs).map(([key, value]) => (
          <TabButton
            key={key}
            isSelected={activeChart.id === value.id}
            onClick={() => changeChart(value)}
          >
            {value.name}
          </TabButton>
        ))}
      </TabBar>
      <div className="h-[calc(100%_-_40px)] max-w-full" ref={chartRef}>
        <div className={cn('relative size-full', { hidden: activeChart !== Tabs.depth })}>
          {activeChart === Tabs.depth && <DepthChart />}
        </div>
        <div className={cn('relative size-full', { hidden: activeChart !== Tabs.price })}>
          <TradingViewChart />
        </div>
        <div className={cn('relative size-full', { hidden: activeChart !== Tabs.funding })}>
          <FundingRateChart />
        </div>
        <div className={cn('relative size-full', { hidden: activeChart !== Tabs.details })}>
          {activeChart === Tabs.details && <MarketDetails />}
        </div>
      </div>
    </>
  )
}
