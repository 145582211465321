import React from 'react'

import { Navbar } from 'js/pages/navbar/Navbar'
import { Footer } from './Footer'
import { useResponsiveness } from 'js/ResponsivenessProvider'

interface PageContainerProps {
  children: React.ReactNode
}

export const PageContainer = ({ children }: PageContainerProps) => {
  const { isMobile } = useResponsiveness()

  return (
    <div className="flex h-screen max-h-screen min-h-screen w-full flex-col items-center bg-black">
      <Navbar />
      <div className="flex min-h-[calc(100dvh_-_92px)] w-full flex-col overflow-y-scroll pb-8">
        {children}
      </div>
      {!isMobile && <Footer />}
    </div>
  )
}
