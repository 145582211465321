import React, { useState } from 'react'

import Icon from 'js/shared-components/uikit/Icon'

interface SearchBarProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  title: string
}

export const SearchBar = ({ onChange, title }: SearchBarProps) => {
  const [query, setQuery] = useState('')

  return (
    <div className="relative flex h-fit w-[30%] overflow-hidden rounded-xl border bg-white/[0.06] max-mobile:w-1/2">
      <input
        className="my-0.5 h-10 w-full bg-transparent indent-8 text-white placeholder:text-white-opaque"
        id="search_input"
        placeholder={title}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setQuery(e.target.value)
          onChange(e)
        }}
        onClick={(e) => e.stopPropagation()}
        value={query}
        enterKeyHint="search"
        name="search"
        autoComplete="off"
      />
      <Icon icon="search" className="absolute left-3 top-1/2 size-4 -translate-y-1/2 text-white" />
    </div>
  )
}
