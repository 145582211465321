import Input from 'js/shared-components/uikit/Input'

import { useOrderInputStore } from '../PlaceOrder'

export const RunningTime = () => {
  const { runtimeMinutes, runtimeHours, changeRuntimeMinutes, changeRuntimeHours } =
    useOrderInputStore()

  return (
    <div className="mt-2 flex flex-col gap-2">
      <p className="typography-body-1 text-white">Running Time (5m - 24h)</p>
      <div className="flex gap-2">
        <div className="flex w-full items-center gap-4 rounded-lg border bg-white-transparent px-4 py-2">
          <Input
            type="number"
            value={runtimeHours}
            onChange={(e) => changeRuntimeHours(e.target.value)}
            className="mt-1 h-[unset] w-full overflow-hidden border-none indent-0 text-sm placeholder:indent-0 placeholder:text-sm"
          />
          <p className="typography-body-2 text-white-opaque">Hours</p>
        </div>
        <div className="flex w-full items-center gap-4 rounded-lg border bg-white-transparent px-4 py-2">
          <Input
            type="number"
            value={runtimeMinutes}
            onChange={(e) => changeRuntimeMinutes(e.target.value)}
            className="mt-1 h-[unset] w-full overflow-hidden border-none indent-0 text-sm placeholder:indent-0 placeholder:text-sm"
          />
          <p className="typography-body-2 text-white-opaque">Minutes</p>
        </div>
      </div>
    </div>
  )
}
