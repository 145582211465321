import UIKitCurrencyInput from 'js/shared-components/uikit/CurrencyInput'

export const CurrencyInput = ({
  value,
  onChange,
  symbol,
  decimal,
}: {
  value: string
  onChange: (value: string) => void
  symbol: string
  decimal: number
}) => {
  const syntaxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/,/g, '.').replace(/^0+(?=\d)/, '')
    const split = inputValue.split('.')

    const regex = /^[0-9]*\.?[0-9]*$/
    if (inputValue.length <= 12 && regex.test(inputValue) && split.length <= 2) {
      if (split.length === 2 && split[1]!.length > decimal) {
        return false
      }
      e.target.value = inputValue
      return true
    }

    return false
  }

  return (
    <UIKitCurrencyInput
      label="Amount"
      helperText={`Amount of ${symbol} to short or long. This is the amount your position will increase or decrease when the order is filled, not  the final position amount.`}
      symbol={symbol}
      value={value}
      onChange={(e) => {
        if (syntaxCheck(e)) onChange(e.target.value)
      }}
      placeholder={Number(0).toFixed(decimal)}
    />
  )
}
