import smoothscroll from 'smoothscroll-polyfill'
import { renderToStaticMarkup } from 'react-dom/server'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import 'css/index.css'

import { colors, fonts } from 'css/css'

import {
  widget,
  type Bar,
  type ChartingLibraryWidgetOptions,
  type IChartingLibraryWidget,
  type ResolutionString,
  type Timezone,
  type IPositionLineAdapter,
} from '../../../../../../public/charting_library'
import { ChartLoader } from './ChartLoader'
import { type Trade } from 'js/providers/types'
import { useCurrentMarket, useCurrentMarketId } from 'js/providers/hooks/order-book-metas-hooks'
import { type Candlestick, type OrderBookDetail } from 'zklighter-perps'
import { candlesticksApi } from 'js/util/api/sdk'
import cn from 'js/util/cn'
import { useOrderBookTrades } from 'js/providers/order-book-store'
import { useWsSubStore } from 'js/providers/wsStore'
import { usePositionsWithLiqPrices } from 'js/util/positions'
import { useAccountIndex } from 'js/providers/user-store'

type ExtendedBar = Bar & {
  lastTradeId: number
}

const candleToBar = (candle: Candlestick): ExtendedBar => ({
  time: candle.timestamp,
  open: candle.open,
  close: candle.close,
  high: candle.high,
  low: candle.low,
  volume: candle.volume1,
  lastTradeId: candle.last_trade_id,
})

const resolutions = ['1', '5', '15', '1h', '4h', '1d'] as ResolutionString[]

const tvResToObRes = (res: ResolutionString) => {
  switch (res) {
    case '1':
      return '1m'
    case '5':
      return '5m'
    case '15':
      return '15m'
    case '60':
      return '1h'
    case '240':
      return '4h'
    case '1D':
      return '1d'
    default:
      return '' as never
  }
}

const tvResToResInMiliseconds = (res: ResolutionString) => {
  switch (res) {
    case '1':
      return 60 * 1000
    case '5':
      return 5 * 60 * 1000
    case '15':
      return 15 * 60 * 1000
    case '60':
      return 60 * 60 * 1000
    case '240':
      return 4 * 60 * 60 * 1000
    case '1D':
      return 24 * 60 * 60 * 1000
    default:
      return 0 as never
  }
}

// Polyfill invocation
smoothscroll.polyfill()

const lastBarsCache = new Map<number, ExtendedBar>()

export const TradingViewChart = () => {
  const trades = useOrderBookTrades()
  const [isChartLoading, setIsChartLoading] = useState(true)
  const [showOrderLines, setShowOrderLines] = useState(true)
  const positionLineRef = useRef<IPositionLineAdapter | null>(null)
  const liquidationLineRef = useRef<IPositionLineAdapter | null>(null)
  const currentMarket = useCurrentMarket()
  const currentMarketId = useCurrentMarketId()
  const currentMarketRef = useRef<OrderBookDetail>(currentMarket)
  const accountIndex = useAccountIndex()
  const position = usePositionsWithLiqPrices(accountIndex ?? -1)[currentMarketId]
  const chartContainerRef = useRef<HTMLDivElement | null>(null)
  const widgetRef = useRef<IChartingLibraryWidget | null>(null)
  const widgetLoadingRef = useRef(false)
  const newTradesCb = useRef<((trades: Trade[] | null) => void) | null>(null)
  const socketHealthy = useWsSubStore((state) => state.healthy)

  useEffect(() => {
    currentMarketRef.current = currentMarket
  }, [currentMarket])

  const handlePositionLines = useCallback(
    (widget: IChartingLibraryWidget) => {
      if (!showOrderLines || !position) {
        positionLineRef.current?.remove()
        liquidationLineRef.current?.remove()
        positionLineRef.current = null
        liquidationLineRef.current = null
        return
      }

      if (!positionLineRef.current) {
        positionLineRef.current = widget
          .activeChart()
          .createPositionLine()
          .setBodyBackgroundColor(colors.black)
          .setBodyTextColor(colors.whiteOpaque)
          .setText('Avg Entry')
          .setQuantity(position.position)
      }

      positionLineRef.current.setPrice(Number(position.avg_entry_price))

      if (!liquidationLineRef.current) {
        liquidationLineRef.current = widget
          .activeChart()
          .createPositionLine()
          .setBodyBackgroundColor(colors.black)
          .setBodyTextColor(colors.whiteOpaque)
          .setText('Liquidation')
          .setQuantity('1')
      }

      liquidationLineRef.current.setPrice(Number(position.liquidation_price))
    },
    [position, showOrderLines],
  )

  useEffect(() => {
    newTradesCb.current?.(trades)
  }, [trades])

  const datafeed = useMemo(() => {
    const df: ChartingLibraryWidgetOptions['datafeed'] = {
      onReady(callback) {
        setTimeout(() => callback({ supported_resolutions: resolutions }), 0)
      },
      resolveSymbol(name, resolve) {
        setTimeout(
          () =>
            resolve({
              ticker: name ?? '',
              full_name: name ?? '',
              listed_exchange: '',
              has_intraday: true,
              format: 'price',
              name: name ?? '',
              description: currentMarketRef.current.symbol,
              type: 'crypto',
              session: '24x7',
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone,
              exchange: '',
              minmov: 1,
              pricescale: 10 ** currentMarketRef.current.supported_price_decimals,
              visible_plots_set: 'ohlcv',
              supported_resolutions: resolutions,
              volume_precision: 2,
              data_status: 'streaming',
            }),
          0,
        )
      },
      unsubscribeBars: () => {},
      subscribeBars(_symbolnfo, resolution, onRealtimeCallback) {
        newTradesCb.current = (trades) =>
          trades?.toReversed().forEach((trade) => {
            const marketId = currentMarketRef.current.market_id
            const lastBar = lastBarsCache.get(marketId)

            if (!lastBar || lastBar.lastTradeId >= trade.trade_id || trade.market_id !== marketId) {
              return
            }

            const tradePrice = Number(trade.price)
            const tradeSize = Number(trade.size) * tradePrice

            if (trade.timestamp >= lastBar.time) {
              const newLastBar: ExtendedBar = {
                high: tradePrice,
                low: tradePrice,
                time: lastBar.time + tvResToResInMiliseconds(resolution),
                open: lastBar.close,
                close: tradePrice,
                volume: tradeSize,
                lastTradeId: trade.trade_id,
              }
              lastBarsCache.set(marketId, newLastBar)
              onRealtimeCallback(newLastBar)
              return
            }

            lastBar.volume = lastBar.volume! + tradeSize
            lastBar.high = Math.max(lastBar.high, tradePrice)
            lastBar.low = Math.min(lastBar.low, tradePrice)
            lastBar.close = tradePrice
            lastBar.lastTradeId = trade.trade_id
            lastBarsCache.set(marketId, lastBar)
            onRealtimeCallback(lastBar)
          })
      },
      searchSymbols() {},
      getBars: async (_symbol, res, period, resolve, _err) => {
        const marketId = currentMarketRef.current.market_id

        const returnBars = async ({
          period,
        }: {
          period: { to: number; from: number; countBack: number }
        }) => {
          try {
            const { candlesticks } = await candlesticksApi.candlesticks({
              market_id: marketId,
              resolution: tvResToObRes(res),
              // TradingView period uses seconds instead of miliseconds
              start_timestamp: period.from * 1000,
              end_timestamp: period.to * 1000,
              count_back: period.countBack,
              set_timestamp_to_end: true,
            })

            return candlesticks.map(candleToBar)
          } catch {
            return []
          }
        }
        try {
          const bars = await returnBars({ period })

          if (period.firstDataRequest) {
            lastBarsCache.set(marketId, bars.at(-1)!)
          }

          resolve(bars, { noData: bars.length !== period.countBack })
        } catch (error) {
          _err(error as string)
        }
      },
    }
    return df
  }, [currentMarketId])

  useEffect(() => {
    if (!socketHealthy) {
      widgetRef.current?.remove()
      widgetRef.current = null
      setIsChartLoading(true)
    }

    if (!chartContainerRef.current || widgetRef.current || widgetLoadingRef.current) {
      return
    }

    widgetLoadingRef.current = true

    const widgetOpts: ChartingLibraryWidgetOptions = {
      container: chartContainerRef.current,
      symbol: currentMarketRef.current.symbol,
      locale: 'en',
      load_last_chart: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone,
      study_count_limit: 5,
      // header_widget_buttons_mode: 'compact',
      disabled_features: [
        'symbol_search_hot_key',
        'use_localstorage_for_settings',
        'link_to_tradingview',
        'header_interval_dialog_button',
        'study_templates',
        'chart_property_page_trading',
        'chart_crosshair_menu',
        'hide_last_na_study_output',
        'go_to_date',
        'header_compare',
        'header_symbol_search',
        'timeframes_toolbar',
        'control_bar',
        'display_market_status',
        'symbol_info',
      ],
      debug: false,
      enabled_features: [
        'hide_left_toolbar_by_default',
        'lock_visible_time_range_on_resize',
        'shift_visible_range_on_new_bar	',
        'save_chart_properties_to_local_storage',
      ],
      interval: '5' as ResolutionString,
      custom_font_family: fonts.satoshi,
      datafeed,
      library_path: '/charting_library/',
      custom_css_url: '/tradingview.css',
      fullscreen: false,
      autosize: true,
      studies_overrides: {},
      theme: 'Dark',
      toolbar_bg: colors.black,
      overrides: {
        'paneProperties.background': colors.black,
        'paneProperties.backgroundType': 'solid',
        'paneProperties.legendProperties.showStudyArguments': false,
        'paneProperties.legendProperties.showStudyValues': true,
        'mainSeriesProperties.statusViewStyle.showInterval': false,
        'mainSeriesProperties.candleStyle.upColor': colors.greenMain,
        'mainSeriesProperties.candleStyle.downColor': colors.redMain,
        'paneProperties.legendProperties.showSeriesTitle': true,
        'scalesProperties.scaleSeriesOnly': true,
        'scalesProperties.showStudyLastValue': false,
        // 'mainSeriesProperties.statusViewStyle.showSymbol': false,
        'symbolWatermarkProperties.visibility': false,
      },
    }

    const tvWidget = new widget(widgetOpts)

    tvWidget.onChartReady(() => {
      widgetRef.current = tvWidget
      widgetLoadingRef.current = false
      handlePositionLines(tvWidget)
      setIsChartLoading(false)
      const volumeStudyId = tvWidget.activeChart().getAllStudies()[0]?.id

      if (!volumeStudyId) {
        return
      }

      tvWidget.activeChart().getStudyById(volumeStudyId).unmergeDown()
    })

    tvWidget.headerReady().then(function () {
      const button = tvWidget.createButton()
      const toggle = document.createElement('div')
      toggle.className = 'toggle-switcher on'
      toggle.innerHTML = renderToStaticMarkup(<div className="toggle-switcher-dot" />)
      button.setAttribute('title', 'Display or hide position lines')
      button.classList.add('trading-view-button')
      button.addEventListener('click', () => {
        setShowOrderLines((prev) => !prev)
        if (toggle.classList.contains('on')) {
          toggle.classList.remove('on')
        } else {
          toggle.classList.add('on')
        }
      })
      button.textContent = 'Position Lines'
      button.appendChild(toggle)
    })
  }, [datafeed, socketHealthy])

  useEffect(() => {
    if (!widgetRef.current) {
      return
    }

    handlePositionLines(widgetRef.current)
  }, [handlePositionLines])

  useEffect(() => {
    if (!widgetRef.current || !currentMarket) {
      return
    }

    widgetRef.current.chart().setSymbol(currentMarket.symbol)
  }, [currentMarket])

  return (
    <div className="relative size-full">
      {isChartLoading && <ChartLoader />}
      <div className={cn('size-full', { hidden: isChartLoading })} ref={chartContainerRef} />
    </div>
  )
}
