import React, { forwardRef, useState, type ButtonHTMLAttributes } from 'react'

import { TOKEN_LOGOS } from 'js/constants/shared'

import { useChangeMarket, useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useMarketsStats } from 'js/providers/order-book-store'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'
import cn from 'js/util/cn'
import Icon from 'js/shared-components/uikit/Icon'
import { Popover, PopoverContent, PopoverTrigger } from 'js/shared-components/uikit/Popover'
import { Markets } from './Markets'
import { Backdrop } from 'js/shared-components/Backdrop'
import { useResponsiveness } from 'js/ResponsivenessProvider'

const MarginFractionTick = 100

const MarketSelectorTrigger = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>((props, forwardedRef) => {
  const currentMarket = useCurrentMarket()
  const currentMarketStats = useMarketsStats()[currentMarket.market_id]

  const dayChange = currentMarketStats?.daily_price_change ?? 0

  return (
    <button
      ref={forwardedRef}
      {...props}
      className="group flex items-center gap-6 rounded-xl border bg-white-transparent px-6 py-3 max-mobile:gap-2 max-mobile:px-3 max-mobile:py-2"
    >
      <div className="flex items-center gap-2">
        <Icon
          icon={TOKEN_LOGOS[currentMarket.symbol as keyof typeof TOKEN_LOGOS]!}
          className="z-[1] size-6"
        />
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <p className="typography-body-2 text-white max-mobile:max-w-12">
              {currentMarket.symbol}
            </p>
            <div className="rounded-full border border-semi-transparent bg-white-transparent bg-origin-border p-1 px-2">
              <p className="typography-body-1 !leading-3 text-white">
                {Math.round((100 * MarginFractionTick) / currentMarket.initial_margin_fraction)}x
              </p>
            </div>
          </div>
          {!currentMarketStats?.last_trade_price ? (
            <SkeletonRectangle className="h-3 min-w-12 mobile:mt-1" />
          ) : (
            <p
              className={cn('typography-body-1 text-start text-white', {
                'text-green-main': dayChange > 0,
                'text-red-main': dayChange < 0,
                'text-white': dayChange === 0,
              })}
            >
              {Number(currentMarketStats.last_trade_price).toLocaleString('en-US', {
                maximumFractionDigits: currentMarket.supported_price_decimals,
                minimumFractionDigits: currentMarket.supported_price_decimals,
                style: 'currency',
                currency: 'USD',
              })}
            </p>
          )}
        </div>
      </div>
      <Icon
        icon="chevron"
        className={'ml-1 size-3 text-white transition-all group-data-[state=open]:rotate-180'}
      />
    </button>
  )
})

MarketSelectorTrigger.displayName = 'MarketSelectorTrigger'

export const MarketSelector = () => {
  const { isMobile } = useResponsiveness()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const changeMarket = useChangeMarket()

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <MarketSelectorTrigger />
      </PopoverTrigger>
      <Backdrop isVisible={isMobile && popoverOpen} onClick={() => setPopoverOpen(false)} />
      <PopoverContent
        align="start"
        sideOffset={isMobile ? -72 : 8}
        className="max-h-[min(500px,_100vh_-_150px)] w-[750px] overflow-y-scroll rounded-xl bg-black/30 backdrop-blur-xl max-mobile:w-screen max-mobile:rounded-none max-mobile:border-0 max-mobile:border-b"
      >
        <Markets
          closeSelector={() => setPopoverOpen(false)}
          onClickFunc={(marketId) => {
            setPopoverOpen(false)
            changeMarket(marketId)
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
