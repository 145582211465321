import React, { useRef } from 'react'

import { useOutsideAlerter } from 'js/util/util'
import Icon from './uikit/Icon'
import { Clickable } from './Clickable'

interface Props {
  isOpen: boolean
  onClose?: () => void
  children: React.ReactNode
  modalTitle?: string
}

export const Modal: React.FC<Props> = ({ isOpen, onClose, children, modalTitle }) => {
  const modalContentRef = useRef<HTMLDivElement>(null)
  useOutsideAlerter(modalContentRef, () => {
    if (onClose) onClose()
  })

  if (isOpen) {
    return (
      <div
        className="fixed left-1/2 top-1/2 z-[11] -translate-x-1/2 -translate-y-1/2 max-mobile:z-[101] max-mobile:w-[90%]"
        ref={modalContentRef}
      >
        <div className="flex w-[440px] animate-[fadeIn_150ms] flex-col items-center rounded-lg border bg-black px-6 py-4 text-white shadow-dark transition-all max-mobile:w-full">
          <div className="flex w-full items-center justify-between pb-3">
            <p className="typography-text-7 text-white">{modalTitle}</p>
            {!!onClose && (
              <Clickable onClick={onClose}>
                <Icon icon="x" className="size-5" />
              </Clickable>
            )}
          </div>
          {children}
        </div>
      </div>
    )
  }
  return null
}
