import { type Dispatch, type SetStateAction, useEffect, useRef, useState } from 'react'
import { type Position } from 'js/providers/types'
import { useAccountIndex } from 'js/providers/user-store'
import { TxOrderTypes, TxTimeInForceTypes } from 'js/types/user'
import { createOrder } from 'js/zklighter-js-sdk/sdk'
import {
  getMarketOrderFromAmount0,
  useCurrentMarket,
  useCurrentMarketId,
  useOrderBookMetasQuery,
} from 'js/providers/hooks/order-book-metas-hooks'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import { useAppState, useIsClosingPosition } from 'js/providers/base-store'
import { BuyingStats } from './BuyingStats'
import { useOrderBook } from 'js/providers/order-book-store'
import { Button } from 'js/shared-components'
import { CurrencyInput } from 'js/shared-components/CurrencyInput'
import { PriceImpact } from './PriceImpact'
import { useMutation } from '@tanstack/react-query'
import { useUserAccountPositions } from 'js/providers/accounts-store'
import type { TxEventInfo } from 'js/types/api-types'
import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'
import { OrderTypeEnum } from 'zklighter-perps'
import { toast } from 'sonner'
import { OrderNotification } from './OrderNotification'
import Toast from 'js/shared-components/uikit/Toast'

const percentButtons = [
  { value: 0.25, label: '25%' },
  { value: 0.5, label: '50%' },
  { value: 0.75, label: '75%' },
  { value: 1, label: 'Full Close' },
]

interface ClosePositionProps {
  slippage: number
  maxSlippage: number
  setLiquidationWarning: Dispatch<SetStateAction<boolean>>
}

export const ClosePosition = ({
  slippage,
  maxSlippage,
  setLiquidationWarning,
}: ClosePositionProps) => {
  const toastIdRef = useRef<number | string | null>(null)
  const [positionSize, setPositionSize] = useState('')
  const [selectedPercent, setSelectedPercent] = useState(1)
  const orderBook = useOrderBook() ?? { asks: [], bids: [] }
  const accountIndex = useAccountIndex()!
  const currentMarket = useCurrentMarket()
  const currentMarketId = useCurrentMarketId()
  const orderBookMetasQuery = useOrderBookMetasQuery()
  const positions = useUserAccountPositions()
  const isClosingPosition = useIsClosingPosition()

  useEffect(() => {
    if (positions[currentMarketId]) {
      setPositionSize(positions[currentMarketId].position)
    }
  }, [positions, currentMarketId])

  const position = positions[currentMarketId]

  const closePositionMutation = useMutation({
    mutationFn: async (position: Position) => {
      const market = orderBookMetasQuery.data[position.market_id]!
      const isAsk = position.sign === 1

      const amount0 = Math.floor(Number(positionSize) * 10 ** market.supported_size_decimals)
      const orders = isAsk ? orderBook.bids : orderBook.asks
      const { estPrice } = getMarketOrderFromAmount0(market, positionSize, orders)
      const bestPrice = Number(orders[0]?.price)
      const worstExecutionPrice = bestPrice * ((100 + maxSlippage * (isAsk ? -1 : 1)) / 100)
      const price = worstExecutionPrice * 10 ** market.supported_price_decimals

      toastIdRef.current = toast.custom(
        () => (
          <OrderNotification
            price={Number(estPrice)}
            size={Number(positionSize)}
            orderType={OrderTypeEnum.Market}
            isAsk={isAsk}
            marketId={position.market_id}
            closePopup={() => toast.dismiss(toastIdRef.current!)}
          />
        ),
        { position: 'top-right', duration: 12000 },
      )

      return createOrder({
        clientOrderIndex: 0,
        accountIndex,
        orderBookIndex: position.market_id,
        baseAmount: Math.floor(amount0),
        price: isAsk ? Math.floor(price) : Math.ceil(price),
        orderExpiry: 0,
        isAsk: Number(isAsk),
        orderType: TxOrderTypes.OrderTypeMarket,
        timeInForce: TxTimeInForceTypes.OrderImmediateOrCancel,
        reduceOnly: 0,
        triggerPrice: 0,
      })
    },
    onSuccess: (tx, position) => {
      const eventInfo = JSON.parse(tx.event_info) as TxEventInfo
      const orderIndex = eventInfo.to ? eventInfo.to.i : eventInfo.i
      const isAsk = position.sign === 1
      const market = orderBookMetasQuery.data[position.market_id]!
      const orders = isAsk ? orderBook.bids : orderBook.asks
      const { estPrice } = getMarketOrderFromAmount0(market, positionSize, orders)

      toast.custom(
        () => (
          <OrderNotification
            price={Number(estPrice)}
            size={Number(positionSize)}
            orderType={OrderTypeEnum.Market}
            isAsk={isAsk}
            marketId={position.market_id}
            orderIndex={orderIndex}
            closePopup={() => toast.dismiss(toastIdRef.current!)}
          />
        ),
        { id: toastIdRef.current! },
      )

      useAppState.setState({ isClosingPosition: false })
      setSelectedPercent(1)
    },
    onError: () => {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      ))

      toast.dismiss(toastIdRef.current!)
    },
  })

  if (!position) return null

  return (
    <BottomAnimatedDropdown className="h-full overflow-hidden bg-black" isOpen={isClosingPosition}>
      <div className="flex h-10 w-full items-center justify-between border-b bg-white-transparent px-3">
        <p className="typography-body-2 text-white">Close Position</p>
        <Clickable onClick={() => useAppState.setState({ isClosingPosition: false })}>
          <Icon icon="x" className="size-5" />
        </Clickable>
      </div>
      <BuyingStats
        setLiquidationWarning={setLiquidationWarning}
        isShort={position.sign === 1}
        newSize={Number(positionSize)}
      />
      <div className="flex flex-col gap-4 p-2">
        <CurrencyInput
          value={positionSize}
          onChange={(e) => {
            if (Number(e) <= Number(position.position)) setPositionSize(e)
          }}
          symbol={currentMarket.symbol}
          decimal={currentMarket.supported_size_decimals}
        />
        <div className="flex w-full justify-between gap-2">
          {percentButtons.map((button) => (
            <Clickable
              key={button.label}
              className="w-full rounded-lg border bg-white-transparent py-3"
              color={selectedPercent === button.value ? 'blue' : 'white'}
              onClick={() => {
                setPositionSize(
                  (Number(position.position) * button.value).toLocaleString(undefined, {
                    minimumFractionDigits: currentMarket.size_decimals,
                    maximumFractionDigits: currentMarket.size_decimals,
                  }),
                )
                setSelectedPercent(button.value)
              }}
            >
              <p className="typography-body-1">{button.label}</p>
            </Clickable>
          ))}
        </div>
        <Button
          className="w-full"
          color="red"
          onClick={() => closePositionMutation.mutate(position)}
          isLoading={closePositionMutation.isPending && !closePositionMutation.isError}
        >
          Close Position
        </Button>
        <PriceImpact
          newPositionSize={positionSize}
          isLimit={false}
          slippage={slippage}
          isClosingPosition
        />
      </div>
    </BottomAnimatedDropdown>
  )
}
