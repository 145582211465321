import { useUserAccountStats } from 'js/providers/accounts-store'
import { formatUSD } from 'js/util/formatting'
import { useAccountIndex } from 'js/providers/user-store'

import { Explanation } from 'js/shared-components/ExplanationPopup'
import cn from 'js/util/cn'
import { useMaintenanceMarginReq, useAccountTotalUnrealizedPnL } from 'js/util/positions'

export const AccountOverview = () => {
  const { portfolio_value, leverage, margin_usage } = useUserAccountStats()
  const accountIndex = useAccountIndex()

  const maintenanceMarginReq = useMaintenanceMarginReq(accountIndex ?? -1)
  const unrealizedPnl = useAccountTotalUnrealizedPnL(accountIndex ?? -1)

  const crossMarginRatio = maintenanceMarginReq / Number(portfolio_value)

  const stats = [
    {
      title: 'Portfolio Value',
      value: formatUSD(Number(portfolio_value ?? 0)),
      explanation: `Buying power represents the total USD position you can open using your available balance with leverage.`,
    },
    {
      title: 'Cross Leverage',
      value: leverage === null ? '-' : `${leverage}x`,
      explanation: `Leverage allows you to control a larger market position with less capital. It can multiply both profits and losses, increasing the potential for higher returns as well as greater risks.`,
    },
    {
      title: 'Cross Margin Usage',
      value: margin_usage === null ? '-' : `${margin_usage}%`,
      explanation: `Margin usage refers to the portion of your account’s equity used to maintain open positions. High margin usage can lead to margin calls or liquidation.`,
    },
    {
      title: 'Maintenance Margin Requirement',
      value: formatUSD(maintenanceMarginReq),
      explanation: `The maintenance margin requirement is the amount of collateral that must be maintained in an account to support open positions. If the account value falls below the maintenance margin requirement, the account will be liquidated.`,
    },
    {
      title: 'Cross Margin Ratio',
      value: crossMarginRatio ? `${crossMarginRatio.toFixed(2)}%` : '-',
      explanation: `The cross margin ratio is the ratio of the maintenance margin requirement to the total account value. A lower ratio indicates a higher risk of liquidation.`,
    },
    {
      title: 'Unrealized PnL',
      value: formatUSD(unrealizedPnl, { signDisplay: 'exceptZero' }),
      explanation: `Unrealized profit and loss is the potential profit or loss on open positions. It is calculated by the difference between the current market price and the average entry price of the position.`,
      valueClassName: cn({
        'text-red-main': unrealizedPnl < 0,
        'text-green-main': unrealizedPnl > 0,
      }),
    },
  ]

  return (
    <div className="flex w-full flex-col gap-3 rounded-lg border bg-white-transparent p-3">
      <p className="typography-label-1">Account Overview</p>
      {stats.map(({ title, explanation, value, valueClassName }) => (
        <div key={title} className="flex w-full items-center justify-between">
          <Explanation
            explanation={explanation}
            trigger={<p className="typography-body-2 text-white-opaque underline">{title}:</p>}
          />
          <p className={cn('typography-body-2 text-white', valueClassName)}>{value}</p>
        </div>
      ))}
    </div>
  )
}
