import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'
import { useState } from 'react'
import { usePublicPoolQuery } from '../utils'
import { Stats } from './Stats'

const PublicPoolPerformance = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const publicPoolQuery = usePublicPoolQuery()

  return (
    <div className="flex-[3] overflow-hidden rounded-lg border bg-black">
      <TabBar>
        <SelectorIndicator numButtons={2} selectedElemIdx={selectedTabIndex} />
        <TabButton isSelected={selectedTabIndex === 0} onClick={() => setSelectedTabIndex(0)}>
          About
        </TabButton>
        <TabButton isSelected={selectedTabIndex === 1} onClick={() => setSelectedTabIndex(1)}>
          Stats
        </TabButton>
      </TabBar>
      {selectedTabIndex === 0 && (
        <div className="flex min-h-[200px] flex-col gap-4 p-4">
          <div className="flex flex-col gap-3">
            <p className="typography-label-1 text-white">Leader</p>
            <p className="typography-body-1 text-white-opaque">
              {publicPoolQuery.data.l1_address
                .substring(0, 6)
                .concat('...')
                .concat(publicPoolQuery.data.l1_address.slice(-4))}
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="typography-label-1 text-white">Description</p>
            <p className="typography-body-1 text-white-opaque">
              {publicPoolQuery.data.description}
            </p>
          </div>
        </div>
      )}
      {selectedTabIndex === 1 && <Stats />}
    </div>
  )
}

export default PublicPoolPerformance
