import {
  useIsRegisteredQuery,
  useMainAccount,
  useUserAccount,
  useVerifyAndSwitchAccountMutation,
} from 'js/providers/hooks/useAccountQuery'
import { useUserStore } from 'js/providers/user-store'
import { Button } from 'js/shared-components'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'

interface RequestFundsButtonProps {
  closeDropdown: () => void
  openModal: () => void
}

const RequestFundsButton = ({ closeDropdown, openModal }: RequestFundsButtonProps) => {
  const isRegisteredQuery = useIsRegisteredQuery()
  const userAccount = useUserAccount()
  const mainAccount = useMainAccount()
  const verifyAndSwitchAccountMutation = useVerifyAndSwitchAccountMutation()

  if (!userAccount || !mainAccount) {
    return null
  }

  if (userAccount.index !== mainAccount.index) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            className="w-full"
            onClick={() => verifyAndSwitchAccountMutation.mutate(mainAccount!)}
            isLoading={verifyAndSwitchAccountMutation.isPending}
          >
            Switch
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="typography-body-2 text-white">
            Switch to the main account to request funds
          </p>
        </TooltipContent>
      </Tooltip>
    )
  }

  if (!isRegisteredQuery.data) {
    return (
      <Button
        onClick={() => {
          useUserStore.setState({ showOnboarding: true })
          closeDropdown()
        }}
        isLoading={isRegisteredQuery.data === null}
        className="w-full"
      >
        Recover Keys
      </Button>
    )
  }

  return (
    <Button
      onClick={() => {
        closeDropdown()
        openModal()
      }}
      className="w-full"
    >
      Request Funds
    </Button>
  )
}

export default RequestFundsButton
