import { useUserAccount, useUserAddress } from 'js/providers/hooks/useAccountQuery'

export const NotLoggedInText = () => {
  const userAddress = useUserAddress()
  const userAccount = useUserAccount()

  const getLogInText = () => {
    if (!userAddress) {
      return 'Please connect your wallet'
    } else if (!userAccount) {
      return 'Deposit funds to create an account'
    }
  }
  return (
    <div className="flex size-full items-center justify-center">
      <span className="typography-label-1 text-white">{getLogInText()}</span>
    </div>
  )
}
