export const NoOrdersText = ({
  type,
}: {
  type: 'activeOrders' | 'positions' | 'orderHistory' | 'tradeHistory' | 'fundingHistory'
}) => {
  const getText = () => {
    switch (type) {
      case 'activeOrders':
        return 'No Open Orders'
      case 'positions':
        return 'No Open Positions'
      case 'orderHistory':
        return 'No Order History'
      case 'tradeHistory':
        return 'No Trades'
      case 'fundingHistory':
        return 'No Funding History'
      default:
        return ''
    }
  }
  return (
    <div className="m-auto flex h-full items-center justify-center text-center">
      <p className="typography-label-1 text-white">{getText()}</p>
    </div>
  )
}
