import cn from 'js/util/cn'
import { forwardRef, type HTMLAttributes } from 'react'

export const OrderListContainer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...rest }, forwardedRef) => (
    <div
      data-testid="trades"
      ref={forwardedRef}
      className={cn('size-full overflow-scroll max-tablet:h-full max-mobile:max-w-full', className)}
      {...rest}
    />
  ),
)

OrderListContainer.displayName = 'OrderListContainer'
