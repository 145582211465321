import { Link, NavLink, useLocation } from 'react-router-dom'
import { WalletDropdownButton } from './wallet/WalletDropdownButton'
import { TradeDropdown } from './tablet-trade-dropdown/TradeDropdown'
import { CurrentBlock } from './CurrentBlock'
import { LinksMenu } from './LinksMenu'
import { Feedback } from 'js/shared-components/Feedback'
import { useState } from 'react'
import { PointsButton } from './Points'
import { useHealthQuery } from 'js/util/queries'
import cn from 'js/util/cn'
import Icon from 'js/shared-components/uikit/Icon'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'js/shared-components/uikit/DropdownMenu'
import { useResponsiveness } from 'js/ResponsivenessProvider'

export const Navbar = () => {
  const healthQuery = useHealthQuery()
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
  const location = useLocation()
  const { isTablet, isMobile } = useResponsiveness()

  return (
    <>
      <Feedback isOpen={isFeedbackModalOpen} closeModal={() => setIsFeedbackModalOpen(false)} />
      <div className="z-10 flex h-[60px] w-full items-center justify-between bg-black p-3 max-mobile:py-6">
        <div className="flex gap-12">
          <Link to="/trade" className="flex items-center gap-2 text-white max-mobile:gap-1">
            <Icon icon="logo" className="size-6" />
            <p className="typography-label-1 whitespace-nowrap">Lighter Testnet</p>
          </Link>
          <Links openFeedbackModal={() => setIsFeedbackModalOpen(true)} />
        </div>
        {healthQuery.data && (
          <div className="flex items-center gap-8 max-mobile:gap-3">
            <CurrentBlock />
            <PointsButton />
            <WalletDropdownButton />
            {isTablet && location.pathname.includes('trade') && <TradeDropdown />}
            {(isTablet || isMobile) && (
              <LinksMenu openFeedbackModal={() => setIsFeedbackModalOpen(true)} />
            )}
          </div>
        )}
      </div>
    </>
  )
}

const Links = ({ openFeedbackModal }: { openFeedbackModal: () => void }) => {
  const { isMobile, isTablet } = useResponsiveness()

  if (isMobile || isTablet) return null

  return (
    <div className="flex items-center gap-8">
      <NavLink
        to="/trade"
        className={({ isActive }) =>
          cn('typography-label-1 text-white-opaque hover:text-white', {
            'text-white': isActive,
          })
        }
      >
        Trade
      </NavLink>
      <NavLink
        to="/portfolio"
        className={({ isActive }) =>
          cn('typography-label-1 text-white-opaque hover:text-white', {
            'text-white': isActive,
          })
        }
      >
        Portfolio
      </NavLink>
      <NavLink
        to="/public-pools"
        className={({ isActive }) =>
          cn('typography-label-1 text-white-opaque hover:text-white', {
            'text-white': isActive,
          })
        }
      >
        Public Pools
      </NavLink>
      <NavLink
        to="/leaderboard"
        className={({ isActive }) =>
          cn('typography-label-1 text-white-opaque hover:text-white', {
            'text-white': isActive,
          })
        }
      >
        Leaderboard
      </NavLink>

      <DropdownMenu>
        <DropdownMenuTrigger
          className={
            'typography-label-1 border-0 !bg-transparent p-0 text-white-opaque hover:text-white data-[state=open]:text-white'
          }
        >
          More
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-56" align="start">
          <Link to="/sub-accounts">
            <DropdownMenuItem>Sub-Accounts</DropdownMenuItem>
          </Link>
          <a
            href="https://assets.lighter.xyz/whitepaper.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DropdownMenuItem>Whitepaper</DropdownMenuItem>
          </a>
          <a href="https://docs.lighter.xyz" target="_blank" rel="noopener noreferrer">
            <DropdownMenuItem>Docs</DropdownMenuItem>
          </a>
          <DropdownMenuItem onClick={openFeedbackModal}>Support</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
