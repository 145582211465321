import { useBlockHeight } from 'js/providers/order-book-store'

export const CurrentBlock = () => {
  const blockHeight = useBlockHeight()

  if (blockHeight === null) return null

  return (
    <div className="flex cursor-pointer flex-col justify-center whitespace-nowrap py-3 max-mobile:p-2">
      <div
        className="flex items-baseline gap-2"
        role="button"
        onClick={() => window.open(`https://scan.lighter.xyz/block/${blockHeight}`, '_blank')}
      >
        <div className="size-3 animate-[flicker_2s_infinite] rounded-full bg-green-main " />
        <p className="typography-label-1 text-white">Block</p>
        <p className="typography-body-2 text-green-main">{blockHeight}</p>
      </div>
    </div>
  )
}
