import { Button } from 'js/shared-components'
import { PageContainer } from 'js/pages/page-container/PageContainer'
import { Link } from 'react-router-dom'

const NotFound = () => (
  <PageContainer>
    <div className="flex size-full items-center justify-center">
      <div className="flex w-fit flex-col items-center justify-center gap-12 max-tablet:gap-8 max-mobile:gap-4">
        <p className="text-9xl font-light text-white">404</p>
        <p className="text-2xl font-light text-white">Page not found</p>
        <p className="typography-body-2 text-white max-mobile:mx-auto max-mobile:my-7">
          We are sorry but the page you are looking for does not exist.
        </p>
        <Link to="/">
          <Button className="w-full">Return to homepage</Button>
        </Link>
      </div>
    </div>
  </PageContainer>
)

export default NotFound
