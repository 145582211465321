import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import {
  useAccountsQuery,
  useUserAccount,
  useUserAddress,
} from 'js/providers/hooks/useAccountQuery'
import { ChartLoader } from '../../chart/ChartLoader'
import { NotLoggedInText } from '../shared/NotLoggedInText'
import type { PropsWithChildren } from 'react'

const TradeTablesBlocker = ({ children }: PropsWithChildren) => {
  const userAddress = useUserAddress()
  const userAccount = useUserAccount()
  const { sdkHasLoaded } = useDynamicContext()
  const accountsQuery = useAccountsQuery()

  if (!sdkHasLoaded || accountsQuery.isLoading) {
    return <ChartLoader />
  }

  if (!userAddress || !userAccount) {
    return <NotLoggedInText />
  }

  return <>{children}</>
}

export default TradeTablesBlocker
