import { useMemo, useState } from 'react'

import { type OrderSide } from 'js/constants/trades'

import Table from 'js/shared-components/uikit/table/Table'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { useAccountActiveOrders, useAccountLoading } from 'js/providers/accounts-store'
import { useIsRegisteredQuery } from 'js/providers/hooks/useAccountQuery'
import MarketCell from 'js/shared-components/cells/MarketCell'
import DateCell from 'js/shared-components/cells/DateCell'
import SideCell from 'js/shared-components/cells/SideCell'
import OrderAmountFilledCell from 'js/shared-components/cells/orders/OrderAmountFilledCell'
import OrderPriceCell from 'js/shared-components/cells/orders/OrderPriceCell'
import OrderExpiryCell from 'js/shared-components/cells/orders/OrderExpiryCell'
import OrderTypeCell from 'js/shared-components/cells/orders/OrderTypeCell'
import { NoItemsInMarket } from '../NoItemsInMarket'
import CancelOrderButton from './CancelOrderButton'
import { CancelAllModal } from './CancelAllModal'
import { NoOrdersText } from '../NoOrdersText'
import HeaderCell from 'js/shared-components/HeaderCell'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'
import type { OrderBookDetail } from 'zklighter-perps'
import { Clickable } from 'js/shared-components/Clickable'
import OrderTriggerCell from 'js/shared-components/cells/orders/OrderTriggerCell'

interface ActiveOrderTableProps {
  accountIndex: number
  showCancelColumn?: boolean
  selectedSide?: OrderSide
  selectedMarket?: OrderBookDetail | null
  setSelectedMarket?: (market: OrderBookDetail | null) => void
}

export const ActiveOrderTable = ({
  accountIndex,
  showCancelColumn: showCancelOrderColumn = false,
  selectedSide = 'all',
  selectedMarket = null,
  setSelectedMarket = () => {},
}: ActiveOrderTableProps) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const isRegistered = useIsRegisteredQuery().data
  const wsActiveOrdersLoading = useAccountLoading(accountIndex)

  const userActiveOrders = useAccountActiveOrders(accountIndex)

  const activeOrdersToDisplay = useMemo(
    () =>
      (selectedMarket
        ? userActiveOrders[selectedMarket.market_id] ?? []
        : Object.values(userActiveOrders).flat()
      )
        .filter((order) => {
          switch (selectedSide) {
            case 'asks': {
              return order.is_ask
            }
            case 'bids': {
              return !order.is_ask
            }
            case 'all':
            default: {
              return true
            }
          }
        })
        .sort((a, b) => b.timestamp - a.timestamp),
    [selectedSide, selectedMarket, userActiveOrders],
  )

  const showMarketColumn = !selectedMarket
  const showCancelColumn = showCancelOrderColumn && !!isRegistered
  const showCancelAll = !selectedMarket && activeOrdersToDisplay.length > 1

  if (wsActiveOrdersLoading) {
    return (
      <Table>
        <TableHeader>
          <TableHeaderRow>
            {showMarketColumn && <HeaderCell title="Name" />}
            <HeaderCell title="Date" />
            <HeaderCell title="Type" />
            <HeaderCell title="Side" />
            <HeaderCell title="Amount" subTitle="Filled" className="justify-end" />
            <HeaderCell title="Price" className="justify-end" />
            <HeaderCell title="Trigger Conditions" className="justify-end" />
            <HeaderCell title="Expires On" className="justify-end" />
            {showCancelColumn && <HeaderCell title="Cancel" className="justify-end" />}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {Array(4)
            .fill(null)
            .map((_, i) => (
              <TableRow key={i}>
                {new Array(6 + Number(showMarketColumn) + Number(showCancelColumn))
                  .fill(null)
                  .map((_, j) => (
                    <TableCell key={j} className="h-6 p-2">
                      <SkeletonRectangle />
                    </TableCell>
                  ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    )
  }

  if (!Object.values(userActiveOrders).flat().length) {
    return <NoOrdersText type={'activeOrders'} />
  }

  if (selectedMarket && !userActiveOrders[selectedMarket.market_id]?.length) {
    return (
      <NoItemsInMarket
        type={'activeOrders'}
        buttonOnClick={setSelectedMarket ? () => setSelectedMarket(null) : undefined}
      />
    )
  }

  return (
    <>
      {isCancelModalOpen && <CancelAllModal closeModal={() => setIsCancelModalOpen(false)} />}
      <Table>
        <TableHeader>
          <TableHeaderRow>
            {showMarketColumn && <HeaderCell title="Name" />}
            <HeaderCell title="Date" />
            <HeaderCell title="Type" />
            <HeaderCell title="Side" />
            <HeaderCell title="Amount" subTitle="Filled" className="justify-end" />
            <HeaderCell title="Price" className="justify-end" />
            <HeaderCell title="Trigger Conditions" className="justify-end" />
            <HeaderCell title="Expires On" className="justify-end" />
            {showCancelColumn &&
              (showCancelAll ? (
                <TableCell>
                  <Clickable color="red" onClick={() => setIsCancelModalOpen(true)}>
                    <p className="typography-body-2">Cancel All</p>
                  </Clickable>
                </TableCell>
              ) : (
                <HeaderCell title="Cancel" className="justify-end" />
              ))}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {activeOrdersToDisplay.map((order) => (
            <TableRow key={order.order_index}>
              {showMarketColumn && <MarketCell marketIndex={order.market_index} />}
              <DateCell timestamp={order.timestamp} />
              <OrderTypeCell order={order} />
              <SideCell is_short={order.is_ask} />
              <OrderAmountFilledCell order={order} />
              <OrderPriceCell order={order} />
              <OrderTriggerCell order={order} />
              <OrderExpiryCell order={order} />
              {showCancelColumn && (
                <TableCell>
                  <CancelOrderButton order={order} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
