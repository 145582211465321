import { TOKEN_DESCRIPTIONS, TOKEN_LOGOS, USDC_SYMBOL } from 'js/constants/shared'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { SmallArrow } from 'js/shared-components/SmallArrow'
import { useRef } from 'react'
import Icon from 'js/shared-components/uikit/Icon'
import { useResponsiveness } from 'js/ResponsivenessProvider'

const MarginFractionTick = 100

export const MarketDetails = () => {
  const currentMarket = useCurrentMarket()
  const {
    symbol,
    min_base_amount,
    min_quote_amount,
    supported_price_decimals,
    initial_margin_fraction,
    closeout_margin_fraction,
    maintenance_margin_fraction,
  } = currentMarket
  const description = TOKEN_DESCRIPTIONS[symbol as keyof typeof TOKEN_DESCRIPTIONS]
  const detailsRef = useRef<HTMLDivElement>(null)
  const { isTablet } = useResponsiveness()
  const tradingRules = [
    {
      title: 'Market Name',
      value: `${symbol}`,
    },
    {
      title: `Min ${symbol} Amount`,
      value: parseFloat(min_base_amount),
    },
    {
      title: `Min ${USDC_SYMBOL} Amount`,
      value: parseFloat(min_quote_amount),
    },
    {
      title: 'Price Steps',
      value: (1 / 10 ** supported_price_decimals).toString(),
    },
    {
      title: 'Max Leverage',
      value: `${Math.round((100 * MarginFractionTick) / initial_margin_fraction)}x`,
    },
    {
      title: 'Initial Margin Fraction',
      value: `${initial_margin_fraction / MarginFractionTick}%`,
    },
    {
      title: 'Maintenance Margin Fraction',
      value: `${maintenance_margin_fraction / MarginFractionTick}%`,
    },
    {
      title: 'Close Out Margin Fraction',
      value: `${closeout_margin_fraction / MarginFractionTick}%`,
    },
  ]

  const onArrowClick = () => {
    if (detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className="flex max-h-full flex-col gap-6 p-8 max-tablet:relative max-tablet:max-h-full max-mobile:p-4">
      {isTablet && (
        <SmallArrow
          onClick={onArrowClick}
          direction="down"
          className="absolute bottom-0 right-8 justify-end"
        />
      )}
      <div className="flex items-center gap-3">
        <Icon icon={TOKEN_LOGOS[symbol as keyof typeof TOKEN_LOGOS]!} className="size-8" />
        <p className="typography-label-1 text-white">{symbol}</p>
      </div>
      <div className="flex gap-6 overflow-y-scroll max-mobile:flex-col">
        <p className="typography-label-1 flex-[1] text-white">{description}</p>
        <div className="flex w-full flex-[1] flex-col gap-2 mobile:max-tablet:overflow-y-scroll">
          {tradingRules.map((rule, idx) => (
            <div
              className="relative flex w-full justify-between border-b-[0.5px] border-grey-light pb-2"
              key={`${rule.title}-${rule.value}-${idx}`}
            >
              <p className="typography-body-2 text-white-opaque">{rule.title}:</p>
              <p className="typography-body-2 text-white">{rule.value}</p>
            </div>
          ))}
          <div ref={detailsRef} />
        </div>
      </div>
    </div>
  )
}
