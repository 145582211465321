import { type PublicPool } from 'zklighter-perps'
import { useUserAccountShares } from 'js/providers/accounts-store'
import { getYourDeposits } from 'js/pages/public-pool/utils'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface PublicPoolUserDepositCellProps {
  publicPool: PublicPool
}

const PublicPoolUserDepositCell = ({ publicPool }: PublicPoolUserDepositCellProps) => {
  const shares = useUserAccountShares()

  return (
    <TableCell>
      <p className="typography-body-1 text-white">
        {getYourDeposits(
          shares,
          publicPool.index,
          Number(publicPool.total_asset_value),
          publicPool.pool_info.total_shares,
        )}
      </p>
    </TableCell>
  )
}
export default PublicPoolUserDepositCell
