import { Explanation } from 'js/shared-components/ExplanationPopup'
import { type ChangeEventHandler, type FocusEventHandler, type MouseEventHandler } from 'react'
import Input from '../Input'
import { useOrderInputStore } from 'js/pages/trade/components/place-order-panel/PlaceOrder'
import { CoinSelector } from 'js/pages/trade/components/place-order-panel/components/CoinSelector'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'

// this should forwardRef and reuse the default input types
// but styled-components doesn't allow for that :/
interface CurrencyInputProps {
  // input props
  type?: string
  step?: string
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  placeholder?: string

  // container props
  label: string
  symbol: string
  helperText?: string
  onClick?: MouseEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  hasCoinSelector?: boolean
}

const CurrencyInput = ({
  symbol,
  label,
  helperText,
  onClick,
  onFocus,
  onBlur,
  hasCoinSelector,
  ...rest
}: CurrencyInputProps) => {
  const orderInputs = useOrderInputStore()
  const optionOnCLick = (coin: string) => orderInputs.changeSelectedInputCoin(coin)
  const currentMarket = useCurrentMarket()

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-2">
        <p className="typography-body-2 text-white">{label}</p>
        {!!helperText && <Explanation explanation={helperText} />}
      </div>
      <div
        className="flex w-full items-center justify-between rounded-lg border bg-white-transparent p-2"
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <Input
          {...rest}
          className="h-[unset] w-full overflow-hidden rounded-none border-0 indent-0 text-sm placeholder:indent-0 placeholder:text-sm"
        />
        <div className="flex items-center gap-2">
          {hasCoinSelector ? (
            <CoinSelector
              options={[currentMarket.symbol, 'USD']}
              selectedOption={orderInputs.selectedInputCoin}
              onSelect={optionOnCLick}
            />
          ) : (
            <p className="typography-body-2 text-white">{symbol}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default CurrencyInput
