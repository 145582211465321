import { create } from 'zustand'
import { useUserStore } from './user-store'
import { useOrderBookStore } from './order-book-store'
import { type WebWorkerMessage } from './orderBookWorker/types'
import { useAccountsStore } from './accounts-store'

import { Unpackr } from 'msgpackr'

type Encoding = 'msgpack' | 'json'

function getEncoding(): Encoding {
  // Check URL parameters first (if running in browser)
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search)
    const encodingParam = urlParams.get('encoding')?.toLowerCase()

    // Validate the encoding parameter
    if (encodingParam === 'json' || encodingParam === 'msgpack') {
      return encodingParam
    }
  }

  // Fall back to domain-based logic
  if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
    return 'json'
  }

  // Default to msgpack
  return 'msgpack'
}

interface WsSubStore {
  marketIndex: number | null
  accountIndex: number | null
  poolIndexes: number[]
  healthy: boolean
  worker: Worker | null
  ws: WebSocket | null
  lastMessageAt: number | null
  actions: {
    logout: () => void
    init: () => void
    switchMarket: (newMarketIndex: number) => void
    subscribePublicPool: (publicPoolIndex: number) => void
    unsubscribePublicPool: (publicPoolIndex: number) => void
    switchAccount: (newAccountIndex: number | null) => void
  }
}

export const useWsSubStore = create<WsSubStore>((set, get) => ({
  marketIndex: null,
  accountIndex: null,
  poolIndexes: [],
  healthy: false,
  worker: null,
  ws: null,
  lastMessageAt: null,
  actions: {
    logout() {
      const accountIndex = useUserStore.getState().accountIndex!
      useAccountsStore.setState((state) => {
        if (state.accounts[accountIndex]) {
          delete state.accounts[accountIndex]
        }

        if (state.portfolioStats[accountIndex]) {
          delete state.portfolioStats[accountIndex]
        }

        return state
      })

      useUserStore.setState({ accountIndex: null })
    },
    init() {
      const worker = new Worker(new URL('./orderBookWorker/index.ts', import.meta.url), {
        type: 'module',
      })
      worker.onmessage = (e: MessageEvent<WebWorkerMessage>) => {
        const { type, data } = e.data

        switch (type) {
          case 'markets_stats': {
            useOrderBookStore.setState({ marketsStats: data })
            break
          }
          case 'subscribed_account_all': {
            useAccountsStore.setState({ accounts: data })
            break
          }
          case 'update_account_all': {
            useAccountsStore.setState({ accounts: data })
            break
          }
          case 'portfolio_stats': {
            useAccountsStore.setState({ portfolioStats: data })
            break
          }
          case 'pool_info': {
            useAccountsStore.setState({ poolInfo: data })
            break
          }
          case 'trades': {
            useOrderBookStore.setState({ trades: data })
            break
          }
          case 'orderbook': {
            useOrderBookStore.setState({ orderBook: data })
            break
          }
          case 'height': {
            useOrderBookStore.setState({ height: data })
          }
        }
      }
      const encoding = getEncoding()
      const ws = new WebSocket(import.meta.env.VITE_WS_API_BASE + `?encoding=${encoding}`)
      if (encoding === 'msgpack') {
        ws.binaryType = 'arraybuffer'
      }
      ws.onopen = () => {
        // timeout is used here so the orderbook is subscribed before this
        setTimeout(() => {
          ws.send(MSG.SUBSCRIBE_MARKETS_STATS())
          ws.send(MSG.SUBSCRIBE_BLOCK_HEIGHT())
        }, 0)

        set({ healthy: true, ws, worker }, undefined)
      }
      ws.onclose = () => {
        worker.terminate()
        set({ healthy: false, worker: null, lastMessageAt: null }, undefined)
      }
      const r = new Unpackr({ int64AsNumber: true, mapsAsObjects: true })
      ws.onmessage = (e) => {
        let msg: unknown
        if (encoding === 'json') {
          msg = JSON.parse(e.data)
        }
        if (encoding === 'msgpack') {
          try {
            msg = r.unpack(new Uint8Array(e.data))
          } catch (e) {
            console.error('Err while decoding msgpack', e)
          }
        }
        if (msg) {
          if ((msg as Record<string, unknown>).type === 'ping') {
            ws.send(JSON.stringify({ type: 'pong' }))
            return
          }
          // only care about seconds
          set({ lastMessageAt: Math.floor(Date.now() / 1000) })
          worker.postMessage(msg)
        }
      }

      return () => {
        set({ worker: null })
        worker.terminate()
        ws.close()
      }
    },
    switchMarket: (newMarketIndex) => {
      const { ws, worker } = get()

      if (!ws || !worker) {
        return
      }

      worker.postMessage({
        type: 'change_desired_market',
        marketIndex: newMarketIndex,
      })

      ws.send(MSG.SUBSCRIBE_PUBLIC_MARKET_DATA(newMarketIndex))

      set({ marketIndex: newMarketIndex })
    },
    subscribePublicPool: (publicPoolIndex: number) => {
      const { ws, poolIndexes } = get()

      if (poolIndexes.includes(publicPoolIndex)) {
        return
      }

      ws?.send(MSG.SUBSCRIBE_POOL_DATA(publicPoolIndex))
      ws?.send(MSG.SUBSCRIBE_USER_STATS(publicPoolIndex))
      ws?.send(MSG.SUBSCRIBE_POOL_INFO(publicPoolIndex))

      set({ poolIndexes: [...poolIndexes, publicPoolIndex] })
    },
    unsubscribePublicPool: (publicPoolIndex: number) => {
      const { ws, poolIndexes } = get()

      if (!poolIndexes.includes(publicPoolIndex)) {
        return
      }

      ws?.send(MSG.UNSUBSCRIBE_POOL_DATA(publicPoolIndex))
      ws?.send(MSG.UNSUBSCRIBE_USER_STATS(publicPoolIndex))
      ws?.send(MSG.UNSUBSCRIBE_POOL_INFO(publicPoolIndex))

      set({
        poolIndexes: poolIndexes.filter((index) => index !== publicPoolIndex),
      })
    },
    switchAccount: (newAccountIndex: number | null) => {
      const { ws, accountIndex } = get()

      if (!ws) {
        return
      }

      if (accountIndex !== null) {
        ws.send(MSG.UNSUBSCRIBE_ACCOUNT(accountIndex))
        ws.send(MSG.UNSUBSCRIBE_USER_STATS(accountIndex))
        ws.send(MSG.UNSUBSCRIBE_ACCOUNT_TRANSACTIONS(accountIndex))
      }

      if (newAccountIndex !== null) {
        ws.send(MSG.SUBSCRIBE_ACCOUNT(newAccountIndex))
        ws.send(MSG.SUBSCRIBE_USER_STATS(newAccountIndex))
        ws.send(MSG.SUBSCRIBE_ACCOUNT_TRANSACTIONS(newAccountIndex))
      }

      set({ accountIndex: newAccountIndex })
    },
  },
}))

const MSG = {
  SUBSCRIBE_ACCOUNT: (account: number) => {
    return JSON.stringify({
      type: 'subscribe',
      channel: `account_all/${account}`,
    })
  },
  UNSUBSCRIBE_ACCOUNT: (account: number) => {
    return JSON.stringify({
      type: 'unsubscribe',
      channel: `account_all/${account}`,
    })
  },
  SUBSCRIBE_USER_STATS: (account: number) => {
    return JSON.stringify({
      type: 'subscribe',
      channel: `user_stats/${account}`,
    })
  },
  UNSUBSCRIBE_USER_STATS: (account: number) => {
    return JSON.stringify({
      type: 'unsubscribe',
      channel: `user_stats/${account}`,
    })
  },
  SUBSCRIBE_POOL_INFO: (account: number) =>
    JSON.stringify({ type: 'subscribe', channel: `pool_info/${account}` }),
  SUBSCRIBE_POOL_DATA: (account: number) =>
    JSON.stringify({ type: 'subscribe', channel: `pool_data/${account}` }),
  UNSUBSCRIBE_POOL_DATA: (account: number) =>
    JSON.stringify({ type: 'unsubscribe', channel: `pool_data/${account}` }),
  UNSUBSCRIBE_POOL_INFO: (account: number) =>
    JSON.stringify({ type: 'unsubscribe', channel: `pool_info/${account}` }),
  SUBSCRIBE_PUBLIC_MARKET_DATA: (marketIndex: number) =>
    JSON.stringify({
      type: 'subscribe',
      channel: `public_market_data/${marketIndex}`,
    }),
  SUBSCRIBE_MARKETS_STATS: () => JSON.stringify({ type: 'subscribe', channel: 'market_stats/all' }),
  UNSUBSCRIBE_MARKETS_STATS: () =>
    JSON.stringify({ type: 'unsubscribe', channel: 'market_stats/all' }),
  SUBSCRIBE_BLOCK_HEIGHT: () => JSON.stringify({ type: 'subscribe', channel: 'height' }),
  SUBSCRIBE_ACCOUNT_TRANSACTIONS: (account: number) => {
    return JSON.stringify({
      type: 'subscribe',
      channel: `account_tx/${account}`,
    })
  },
  UNSUBSCRIBE_ACCOUNT_TRANSACTIONS: (account: number) => {
    return JSON.stringify({
      type: 'unsubscribe',
      channel: `account_tx/${account}`,
    })
  },
}
