import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import {
  useAccountsQuery,
  useIsRegisteredQuery,
  useMainAccount,
  useUserAccount,
  useUserAddress,
  useVerifyAndSwitchAccountMutation,
} from 'js/providers/hooks/useAccountQuery'
import { useUserStore } from 'js/providers/user-store'
import { Button } from 'js/shared-components'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import CreatePublicPoolModal from './CreatePublicPoolModal'

const CreatePublicPool = () => {
  const userAddress = useUserAddress()
  const isRegistered = useIsRegisteredQuery().data
  const accountsQuery = useAccountsQuery()
  const { setShowAuthFlow, sdkHasLoaded } = useDynamicContext()

  const verifyAndSwitchAccountMutation = useVerifyAndSwitchAccountMutation()

  const mainAccount = useMainAccount()
  const userAccount = useUserAccount()

  if (!sdkHasLoaded || accountsQuery.isLoading || isRegistered === null) {
    return null
  }

  if (!userAddress) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button onClick={() => setShowAuthFlow(true)}>Connect</Button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="typography-body-2 text-white">Connect an account to create a public-pool</p>
        </TooltipContent>
      </Tooltip>
    )
  }

  // both conditions are used for type assertion more than anything else
  if (!userAccount || !mainAccount) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button onClick={() => useUserStore.setState({ showOnboarding: true })}>
            Create Account
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="typography-body-2 text-white">Create an account to create a public-pool</p>
        </TooltipContent>
      </Tooltip>
    )
  }

  if (userAccount.index !== mainAccount.index) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={() => verifyAndSwitchAccountMutation.mutate(mainAccount)}
            isLoading={verifyAndSwitchAccountMutation.isPending}
          >
            Switch
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="typography-body-2 text-white">
            Switch to the main account to create a public pool
          </p>
        </TooltipContent>
      </Tooltip>
    )
  }

  if (!isRegistered) {
    return (
      <Button onClick={() => useUserStore.setState({ showOnboarding: true })}>Recover Keys</Button>
    )
  }

  return <CreatePublicPoolModal />
}

export default CreatePublicPool
