import type { FundingHistoryItem } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import cn from 'js/util/cn'

interface FundingRateCellProps {
  funding: FundingHistoryItem
}

const FundingRateCell = ({ funding }: FundingRateCellProps) => (
  <TableCell>
    <p
      className={cn('typography-body-1', {
        'text-green-main': Number(funding.rate) >= 0,
        'text-red-main': Number(funding.rate) < 0,
      })}
    >
      {Number(funding.rate) < 0 ? '-' : '\u00a0'}${Math.abs(Number(funding.rate))}
    </p>
  </TableCell>
)

export default FundingRateCell
