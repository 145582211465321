import { useOrderBookMetasQuery } from 'js/providers/hooks/order-book-metas-hooks'
import type { Order } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { useMemo } from 'react'
import { OrderTypeEnum } from 'zklighter-perps'

interface OrderTriggerCellProps {
  order: Order
}

const OrderTriggerCell = ({ order }: OrderTriggerCellProps) => {
  const market = useOrderBookMetasQuery().data[order.market_index]!
  const isShort = order.is_ask
  const formattedTriggerPrice = Number(order.trigger_price).toLocaleString('en-US', {
    maximumFractionDigits: market.price_decimals,
    minimumFractionDigits: market.price_decimals,
    style: 'currency',
    currency: 'USD',
  })

  const label = useMemo(() => {
    switch (order.type) {
      case OrderTypeEnum.StopLossLimit:
      case OrderTypeEnum.StopLoss: {
        return `Price ${isShort ? 'below' : 'above'} ${formattedTriggerPrice}`
      }
      case OrderTypeEnum.TakeProfitLimit:
      case OrderTypeEnum.TakeProfit: {
        return `Price ${isShort ? 'above' : 'below'} ${formattedTriggerPrice}`
      }
      default: {
        return '-'
      }
    }
  }, [order])

  return (
    <TableCell>
      <p className="typography-body-1 text-end text-white">{label}</p>
    </TableCell>
  )
}
export default OrderTriggerCell
