import {
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from 'js/shared-components/uikit/DropdownMenu'
import cn from 'js/util/cn'

export const CoinSelector = ({
  selectedOption,
  options,
  onSelect,
}: {
  selectedOption: string
  options: string[]
  onSelect: (option: string) => void
}) => (
  <DropdownMenu>
    <DropdownMenuTrigger className="typography-body-2 justify-normal gap-2 border-none bg-transparent p-0 text-white">
      {selectedOption}
    </DropdownMenuTrigger>
    <DropdownMenuContent
      className={cn(
        'max-h-[200px] min-w-0 gap-1 overflow-y-scroll bg-semi-transparent backdrop-blur-[25px] max-mobile:max-h-[150px]',
      )}
    >
      {options.map((option) => (
        <DropdownMenuItem
          key={option}
          onClick={() => onSelect(option)}
          className="typography-body-2 flex items-center gap-2 px-2 py-1 text-white data-[highlighted]:bg-white-transparent"
        >
          {option}
        </DropdownMenuItem>
      ))}
    </DropdownMenuContent>
  </DropdownMenu>
)
