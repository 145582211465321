import cn from 'js/util/cn'
import { forwardRef, type HTMLAttributes } from 'react'

const WarningContainer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...rest }, forwardedRef) => (
    <div
      ref={forwardedRef}
      className={cn(
        'relative flex rounded-lg bg-red-dark p-4',
        'after:absolute after:inset-0 after:w-1.5 after:rounded-l-lg after:bg-red-main',
        className,
      )}
      {...rest}
    />
  ),
)

WarningContainer.displayName = 'WarningContainer'

export default WarningContainer
