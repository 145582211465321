import { useOrderBookMetasQuery } from 'js/providers/hooks/order-book-metas-hooks'
import type { FundingHistoryItem } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface MarketCellProps {
  funding: FundingHistoryItem
}

const SymbolCell = ({ funding }: MarketCellProps) => {
  const orderBookMetasQuery = useOrderBookMetasQuery()
  const symbol = orderBookMetasQuery.data[funding.market_id]!.symbol

  return (
    <TableCell>
      <p className="typography-body-1 text-white">{symbol}</p>
    </TableCell>
  )
}

export default SymbolCell
