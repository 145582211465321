import { PnlBalanceCharts } from 'js/shared-components/PnlBalanceCharts'
import { usePublicPoolQuery } from './utils'

const PublicPoolPnl = () => {
  const publicPoolQuery = usePublicPoolQuery()

  return (
    <div className="min-h-[300px] flex-[3] overflow-hidden rounded-lg border bg-black">
      <div className="relative size-full overflow-hidden pb-2">
        <PnlBalanceCharts accountIndex={publicPoolQuery.data.index} />
      </div>
    </div>
  )
}

export default PublicPoolPnl
