import type { Trade } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface TradeRoleCellProps {
  trade: Trade
  accountIndex: number
}

const TradeRoleCell = ({ trade, accountIndex }: TradeRoleCellProps) => (
  <TableCell>
    <p className="typography-body-1 text-white">
      {(trade.ask_account_id === accountIndex) === trade.is_maker_ask ? 'Maker' : 'Taker'}
    </p>
  </TableCell>
)

export default TradeRoleCell
