import { useQuery } from '@tanstack/react-query'

export const useHealthQuery = () =>
  useQuery({
    queryKey: ['health'],
    queryFn: () =>
      fetch(import.meta.env.VITE_REST_API_BASE)
        .then((res) => res.ok)
        .catch(() => false),
    refetchInterval: 5000,
  })
