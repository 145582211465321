import { useEffect, useMemo } from 'react'

import { colors, fonts } from 'css/css'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useEchart } from 'js/shared-components/useEchart'
import cn from 'js/util/cn'
import { useDepthChartData } from 'js/providers/order-book-store'

export const DepthChart = () => {
  const currentMarket = useCurrentMarket()
  const { prices, maxDepth, bidsDepth, asksDepth } = useDepthChartData()

  const opts = useMemo(
    () => ({
      textStyle: { fontFamily: fonts.satoshi },
      grid: { left: 16, right: 16, top: 12, bottom: 50 },
      tooltip: {
        trigger: 'axis',
        backgroundColor: colors.greyMain,
        borderWidth: 0,
        padding: 3,
        formatter: (d: { data?: number; axisValue?: string }[]) => {
          if (d.length === 0) {
            return null
          }

          const isBuy = d.length === 1
          const price = Number(d[0]!.axisValue ?? 0)
          const size = d[0]!.data ?? 0
          const total = price * size

          const containerClassName = cn(
            'relative flex min-w-[250px] flex-col gap-1 p-4',
            'before:absolute before:left-0 before:top-0 before:h-full before:w-0.5',
            { 'before:bg-green-main': !isBuy, 'before:bg-red-main': isBuy },
          )

          return `
            <div class="${containerClassName}">
              <div class="flex justify-between">
                <span class="text-white-opaque">Price</span>
                <span class="text-white">
                  ${isBuy ? '≥ ' : '≤ '}$${price.toFixed(currentMarket.supported_price_decimals)}
                </span> 
              </div>
              <div class="flex justify-between">
                <div class="flex gap-1">
                  <span class="text-white-opaque">Total Size</span>
                  <div class='flex items-center h-4 rounded-lg mt-1 bg-grey-light px-1 text-[8px] text-white'>
                    ${currentMarket.symbol}
                  </div>
                </div>
                <span class="text-white">
                  ${size.toFixed(currentMarket.supported_size_decimals)}
                </span>
              </div>
              <div class="flex justify-between">
                <span class="text-white-opaque">Total Cost</span>
                <span class="text-white">$${total.toFixed(2)}</span>
              </div>
            </div>
          `
        },
      },
      xAxis: {
        data: prices,
        type: 'category',
        name: 'Price',
        nameLocation: 'middle',
        nameGap: 30,
        boundaryGap: false,
      },
      yAxis: [
        { type: 'value', offset: -50, axisLine: { show: false }, max: maxDepth },
        { type: 'value', offset: -50, axisLine: { show: false }, max: maxDepth },
      ],
      animation: false,
      series: [
        {
          data: bidsDepth,
          type: 'line',
          step: 'start',
          lineStyle: {
            color: colors.greenMain,
            width: 0.8,
          },
          areaStyle: {
            color: colors.greenMain,
            opacity: 0.5,
          },
          yAxisIndex: 0,
        },
        {
          data: asksDepth,
          type: 'line',
          step: 'end',
          lineStyle: {
            color: colors.redMain,
            width: 0.8,
          },
          areaStyle: {
            color: colors.redMain,
            opacity: 0.5,
          },
          yAxisIndex: 1,
        },
      ],
    }),
    [currentMarket, prices, maxDepth, bidsDepth, asksDepth],
  )
  const { chartContainerRef, disposeRef } = useEchart(opts)

  useEffect(() => {
    return () => {
      disposeRef.current()
    }
  }, [])

  return (
    <div className="absolute inset-0">
      <div className="size-full" ref={chartContainerRef} />
    </div>
  )
}
