import cn from 'js/util/cn'

interface ProgressBarProps {
  progress: number
  total: number
  isGreen: boolean
  reversed?: boolean
}

export const ProgressBar = ({ progress, isGreen, total, reversed = false }: ProgressBarProps) => (
  <div
    className={cn(
      'absolute flex h-5 opacity-80 transition-[width] max-mobile:h-[25px] max-mobile:opacity-60',
      {
        'justify-start self-start order-1 left-0': !reversed,
        'justify-end self-end order-3 right-0': reversed,
        'bg-green-dark': isGreen,
        'bg-red-dark': !isGreen,
      },
    )}
    style={{ width: `${total}%` }}
  >
    <div
      className={cn('h-full min-w-px transition-[width]', {
        'bg-green-main': isGreen,
        'bg-red-main': !isGreen,
      })}
      style={{ width: `${progress}%` }}
    />
  </div>
)
