import { Button } from 'js/shared-components'
import { Popover, PopoverTrigger, PopoverContent } from 'js/shared-components/uikit/Popover'
import { PlaceOrder } from 'js/pages/trade/components/place-order-panel/PlaceOrder'
import { Backdrop } from 'js/shared-components/Backdrop'
import { useState } from 'react'
import { useIsClosingPosition } from 'js/providers/base-store'
import { useResponsiveness } from 'js/ResponsivenessProvider'

export const TradeDropdown = () => {
  const { isMobile } = useResponsiveness()
  const isClosingPosition = useIsClosingPosition()
  const [open, setOpen] = useState(false)
  const dropdownOpen = open || isClosingPosition

  if (isMobile) return null

  return (
    <Popover modal onOpenChange={setOpen} open={dropdownOpen}>
      <PopoverTrigger asChild>
        <Button color="green">
          <p className="typography-label-1 text-white">Trade</p>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-[350px]" sideOffset={20}>
        <PlaceOrder />
      </PopoverContent>
      <Backdrop isVisible={dropdownOpen} onClick={() => setOpen(false)} />
    </Popover>
  )
}
