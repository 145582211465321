import React, { forwardRef, type ButtonHTMLAttributes, type MouseEvent } from 'react'

import cn from 'js/util/cn'
import Icon from './uikit/Icon'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  isLoading?: boolean
  color?: 'green' | 'blue' | 'grey' | 'red' | 'secondaryBlue'
  type?: 'button' | 'submit' | 'reset'
  isLoadingText?: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { isLoading, children, color = 'blue', isLoadingText, disabled, className, ...rest },
    forwardedRef,
  ) => (
    <button
      ref={forwardedRef}
      disabled={disabled || isLoading}
      className={cn(
        'flex h-10 min-h-10 items-center justify-center truncate rounded-lg p-4 transition-all max-mobile:px-4 max-mobile:py-0',
        'text-base font-medium text-white disabled:text-white-opaque',
        {
          'hover:bg-primary-blue-pale disabled:bg-primary-blue-pale bg-primary-blue-main':
            color === 'blue',
          'hover:bg-secondary-blue-pale disabled:bg-secondary-blue-pale bg-secondary-blue-main':
            color === 'secondaryBlue',
          'hover:bg-green-dark disabled:bg-green-dark bg-green-main': color === 'green',
          'hover:bg-red-dark disabled:bg-red-dark bg-red-main': color === 'red',
          'hover:bg-grey-dark disabled:bg-grey-dark bg-grey-main': color === 'grey',
        },
        className,
      )}
      {...rest}
    >
      {isLoading ? (
        <>
          <Icon icon="spinner" className="size-5" />
          {isLoadingText}
        </>
      ) : (
        children
      )}
    </button>
  ),
)

Button.displayName = 'Button'
