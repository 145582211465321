function getErrorObj(error: unknown) {
  if (typeof error !== 'object') {
    return
  }

  if (Array.isArray(error)) {
    return
  }

  if (error === null) {
    return
  }

  return error
}

export function getErrorObjStatusCode(error: unknown) {
  const errorObj = getErrorObj(error)

  if (!errorObj || !('status' in errorObj) || typeof errorObj.status !== 'number') {
    return
  }

  return errorObj.status
}
