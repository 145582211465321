import { constants } from './constants'

export const postFeedback = async ({
  telegram,
  feedback,
}: {
  telegram: string
  feedback: string
}) => {
  try {
    await fetch(
      constants.FEEDBACK_FORM_URL +
        new URLSearchParams({
          [constants.FEEDBACK_EMAIL_FIELD]: telegram,
          [constants.FEEDBACK_CONTENT_FIELD]: feedback,
        }),
      {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )

    return true
  } catch (e) {
    console.error('Failed to submit feedback', e)
    return false
  }
}

export const postWaitlist = async ({
  telegram,
  l1address,
}: {
  telegram: string
  l1address: string
}) => {
  try {
    if (!l1address) {
      l1address = 'anonymous'
    }

    const res = await fetch(
      constants.WAITLIST_FORM_URL +
        new URLSearchParams({
          [constants.WAITLIST_EMAIL_FIELD]: telegram,
          [constants.WAITLIST_L1_ADDRESS_FIELD]: l1address,
        }),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )

    return res.ok
  } catch (e) {
    console.error('Failed to add waitlist', e)
    return true
  }
}
