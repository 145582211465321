import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'
import { useState } from 'react'
import DepositForm from './DepositForm'
import WithdrawForm from './WithdrawForm'
import PublicPoolActionsBlocker from './PublicPoolActionsBlocker'

const PublicPoolActions = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  return (
    <div className="flex min-h-[300px] flex-[2] flex-col overflow-hidden rounded-lg border bg-black">
      <PublicPoolActionsBlocker className="mx-auto my-32">
        <TabBar>
          <SelectorIndicator numButtons={2} selectedElemIdx={selectedTabIndex} />
          <TabButton isSelected={selectedTabIndex === 0} onClick={() => setSelectedTabIndex(0)}>
            Deposit
          </TabButton>
          <TabButton isSelected={selectedTabIndex === 1} onClick={() => setSelectedTabIndex(1)}>
            Withdraw
          </TabButton>
        </TabBar>
        <div className="flex-1 p-4">
          {selectedTabIndex === 0 && <DepositForm />}
          {selectedTabIndex === 1 && <WithdrawForm />}
        </div>
      </PublicPoolActionsBlocker>
    </div>
  )
}

export default PublicPoolActions
