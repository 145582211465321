import { TxOrderTypes, TxTimeInForceTypes } from 'js/types/user'
import { OrderTypeEnum } from 'zklighter-perps'
export type TimeInForce = 'gtd' | 'ioc'
export type TimeInForceUnit = 'm' | 'h' | 'd'

const getTimestampDiff = (value: number, unit: TimeInForceUnit) => {
  switch (unit) {
    case 'm':
      return value * 60 * 1000
    case 'h':
      return value * 60 * 60 * 1000
    case 'd':
      return value * 24 * 60 * 60 * 1000
  }
}

const TIME_IN_FORCE_INPUT_MAX_VALUES = {
  d: 4 * 7,
  h: 24 * 4 * 7,
  m: 60 * 24 * 4 * 7,
} as const

export const validateAndParseTIFValue = (value: string, unit: TimeInForceUnit) => {
  if (value === '') {
    return ''
  }

  const intValue = parseInt(value, 10)

  if (Number.isNaN(intValue)) {
    return
  }

  const maxValue = TIME_IN_FORCE_INPUT_MAX_VALUES[unit]

  return `${Math.min(Math.floor(intValue), maxValue)}`
}

export const getOrderExpiry = (
  orderType: OrderTypeEnum,
  timeInForce: TimeInForce,
  timeInForceValue: string,
  timeInForceUnit: TimeInForceUnit,
  runtimeMinutes: string,
  runtimeHours: string,
) => {
  switch (orderType) {
    case OrderTypeEnum.Market: {
      return 0
    }
    case OrderTypeEnum.Limit:
    case OrderTypeEnum.StopLossLimit:
    case OrderTypeEnum.TakeProfitLimit: {
      if (timeInForce === 'ioc') {
        return 0
      }

      return Date.now() + getTimestampDiff(Number(timeInForceValue), timeInForceUnit)
    }
    case OrderTypeEnum.StopLoss:
    case OrderTypeEnum.TakeProfit: {
      return Date.now() + getTimestampDiff(28, 'd')
    }
    case OrderTypeEnum.Twap: {
      return (
        Date.now() +
        getTimestampDiff(Number(runtimeMinutes), 'm') +
        getTimestampDiff(Number(runtimeHours), 'h')
      )
    }
    case OrderTypeEnum.Liquidation:
    case OrderTypeEnum.TwapSub: {
      return 0 as never
    }
  }
}

export const getOrderTimeInForce = (
  orderType: OrderTypeEnum,
  timeInForce: TimeInForce,
  postOnly: boolean,
): TxTimeInForceTypes => {
  switch (orderType) {
    case OrderTypeEnum.Market: {
      return TxTimeInForceTypes.OrderImmediateOrCancel
    }
    case OrderTypeEnum.Limit:
    case OrderTypeEnum.StopLossLimit:
    case OrderTypeEnum.TakeProfitLimit: {
      if (timeInForce === 'ioc') {
        return TxTimeInForceTypes.OrderImmediateOrCancel
      }

      if (postOnly) {
        return TxTimeInForceTypes.OrderPostOnly
      }

      return TxTimeInForceTypes.OrderGoodTillTime
    }
    case OrderTypeEnum.StopLoss:
    case OrderTypeEnum.TakeProfit:
    case OrderTypeEnum.Twap: {
      return TxTimeInForceTypes.OrderGoodTillTime
    }
    case OrderTypeEnum.Liquidation:
    case OrderTypeEnum.TwapSub: {
      return 0 as never
    }
  }
}

export const getOrderType = (orderType: OrderTypeEnum) => {
  switch (orderType) {
    case OrderTypeEnum.Limit:
      return TxOrderTypes.OrderTypeLimit
    case OrderTypeEnum.Market:
      return TxOrderTypes.OrderTypeMarket
    case OrderTypeEnum.StopLoss:
      return TxOrderTypes.OrderTypeStopLoss
    case OrderTypeEnum.StopLossLimit:
      return TxOrderTypes.OrderTypeStopLossLimit
    case OrderTypeEnum.TakeProfit:
      return TxOrderTypes.OrderTypeTakeProfit
    case OrderTypeEnum.TakeProfitLimit:
      return TxOrderTypes.OrderTypeTakeProfitLimit
    case OrderTypeEnum.Twap:
      return TxOrderTypes.OrderTypeTWAP
    case OrderTypeEnum.TwapSub:
      return TxOrderTypes.OrderTypeTWAPSubMarket as never
    case OrderTypeEnum.Liquidation:
      return TxOrderTypes.OrderTypeLiquidation as never
  }
}

export const getOrderPrice = (
  orderType: OrderTypeEnum,
  isShort: boolean,
  limitPrice: number,
  worstExecutionPrice: number,
) => {
  const roundingFunction = isShort ? Math.floor : Math.ceil

  switch (orderType) {
    case OrderTypeEnum.Market: {
      return roundingFunction(worstExecutionPrice)
    }
    case OrderTypeEnum.Limit:
    case OrderTypeEnum.StopLossLimit:
    case OrderTypeEnum.TakeProfitLimit: {
      return roundingFunction(limitPrice)
    }
    case OrderTypeEnum.StopLoss:
    case OrderTypeEnum.TakeProfit:
    case OrderTypeEnum.Twap: {
      return isShort ? 1 : 2 ** 32 - 2
    }
    case OrderTypeEnum.TwapSub:
    case OrderTypeEnum.Liquidation: {
      return 0 as never
    }
  }
}

export const getPlaceOrderButtonLabel = (orderType: OrderTypeEnum) => {
  switch (orderType) {
    case OrderTypeEnum.Market:
      return 'Place Market Order'
    case OrderTypeEnum.Limit:
      return 'Place Limit Order'
    case OrderTypeEnum.StopLossLimit:
      return 'Place Stop Loss Limit Order'
    case OrderTypeEnum.TakeProfitLimit:
      return 'Place Take Profit Limit Order'
    case OrderTypeEnum.StopLoss:
      return 'Place Stop Loss Market Order'
    case OrderTypeEnum.TakeProfit:
      return 'Place Take Profit Market Order'
    case OrderTypeEnum.Twap:
      return 'Place TWAP Order'
    case OrderTypeEnum.TwapSub:
    case OrderTypeEnum.Liquidation: {
      return '' as never
    }
  }
}
