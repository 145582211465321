import type { Position } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatUSD } from 'js/util/formatting'

interface PositionLiquidationPriceCellProps {
  position: Position
}

const PositionLiquidationPriceCell = ({ position }: PositionLiquidationPriceCellProps) => (
  <TableCell>
    {position.liquidation_price ? formatUSD(Number(position.liquidation_price)) : 'N/A'}
  </TableCell>
)

export default PositionLiquidationPriceCell
