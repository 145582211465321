import Table from 'js/shared-components/uikit/table/Table'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'

import { useProtocolPoolListQuery } from 'js/pages/public-pool/utils'
import PublicPoolNameCell from './cells/PublicPoolNameCell'
import PublicPoolLeaderCell from './cells/PublicPoolLeaderCell'
import PublicPoolTVLCell from './cells/PublicPoolTVLCell'
import PublicPoolOperatorFeeCell from './cells/PublicPoolOperatorFeeCell'
import PublicPoolUserDepositCell from './cells/PublicPoolUserDepositCell'
import { useNavigate } from 'react-router-dom'
import HeaderCell from 'js/shared-components/HeaderCell'
import Icon from 'js/shared-components/uikit/Icon'
import TableBody from 'js/shared-components/uikit/table/TableBody'

const ProtocolPublicPoolList = () => {
  const navigate = useNavigate()
  const protocolPoolListQuery = useProtocolPoolListQuery()
  const protocolPublicPools = protocolPoolListQuery.data?.public_pools ?? []

  if (protocolPoolListQuery.isPending) {
    return (
      <div className="flex h-[100px] items-center justify-center">
        <Icon icon="spinner" className="size-8" />
      </div>
    )
  }

  if (!protocolPublicPools.length) {
    return <p className="typography-body-1 text-white">No Protocol Public Pools</p>
  }

  return (
    <Table>
      <TableHeader>
        <TableHeaderRow className="static border-b bg-transparent shadow-none backdrop-filter-none">
          <HeaderCell title="Public Pool" />
          <HeaderCell title="Leader" />
          <HeaderCell title="TVL" />
          <HeaderCell title="Operator Fee" className="justify-end" />
          <HeaderCell title="Your Deposit" className="justify-end" />
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {protocolPublicPools.map((publicPool) => (
          <TableRow
            key={publicPool.index}
            onClick={() => navigate(`/public-pools/${publicPool.index}`)}
          >
            <PublicPoolNameCell publicPool={publicPool} />
            <PublicPoolLeaderCell publicPool={publicPool} />
            <PublicPoolTVLCell publicPool={publicPool} />
            <PublicPoolOperatorFeeCell publicPool={publicPool} />
            <PublicPoolUserDepositCell publicPool={publicPool} />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ProtocolPublicPoolList
