import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useMainAccount } from 'js/providers/hooks/useAccountQuery'
import { Button } from 'js/shared-components'
import { Backdrop } from 'js/shared-components/Backdrop'
import { Modal } from 'js/shared-components/Modal'
import Input from 'js/shared-components/uikit/Input'
import cn from 'js/util/cn'
import { setAccountMetadata } from 'js/zklighter-js-sdk/sdk'
import { useState } from 'react'
import type { DetailedAccount } from 'zklighter-perps'
import WarningContainer from 'js/shared-components/WarningContainer'
import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'
import { toast } from 'sonner'
import Toast from 'js/shared-components/uikit/Toast'

interface RenameAccountProps {
  account: DetailedAccount
}

const RenameAccount = ({ account }: RenameAccountProps) => {
  const queryClient = useQueryClient()
  const mainAccount = useMainAccount()!
  const [subAccountName, setSubAccountName] = useState(account.name)
  const [modalOpen, setModalOpen] = useState(false)

  const renameAccountMutation = useMutation({
    mutationFn: () =>
      setAccountMetadata({
        masterAccountIndex: mainAccount.index,
        targetAccountIndex: account.index,
        name: subAccountName,
        description: '',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['account'] })
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Sub-account renamd successfully"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
    },
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => setModalOpen(false),
  })

  return (
    <>
      <Backdrop fullScreen isVisible={modalOpen} onClick={() => setModalOpen(false)} />
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} modalTitle="Rename Sub-Account">
        <div className="flex w-full flex-col gap-4">
          <div className="flex flex-col items-start gap-2">
            <p className="typography-body-1 text-white">Name</p>
            <Input
              value={subAccountName}
              onChange={(e) => setSubAccountName(e.target.value)}
              className="w-full"
            />
          </div>
          <WarningContainer className={cn({ 'opacity-0': subAccountName.length >= 3 })}>
            Sub-Account name must be at least 3 letters long.
          </WarningContainer>
          <div className="flex justify-end gap-2">
            <Button onClick={() => setModalOpen(false)} color="grey" className="w-fit">
              Cancel
            </Button>
            <Button
              className="w-fit"
              onClick={() => renameAccountMutation.mutate()}
              disabled={subAccountName.length < 3}
              isLoading={renameAccountMutation.isPending && !renameAccountMutation.isError}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
      <Clickable color="blue" onClick={() => setModalOpen(true)}>
        <Icon icon="edit" className="size-4" />
      </Clickable>
    </>
  )
}

export default RenameAccount
