import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import cn from 'js/util/cn'
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react'

export const DropdownMenuContent = forwardRef<
  ElementRef<typeof RadixDropdownMenu.Content>,
  ComponentPropsWithoutRef<typeof RadixDropdownMenu.Content>
>(({ sideOffset = 8, className, ...rest }, forwardedRef) => (
  <RadixDropdownMenu.Content
    ref={forwardedRef}
    sideOffset={sideOffset}
    className={cn(
      'border bg-black/30 shadow-popover backdrop-blur-xl',
      'z-[5] max-h-80 min-w-[var(--radix-dropdown-menu-trigger-width)] overflow-y-scroll rounded-[4px]',
      'origin-[var(--radix-dropdown-menu-content-transform-origin)] data-[state=closed]:animate-[growDown_150ms_ease-out] data-[state=open]:animate-[growUp_150ms_ease-out]',
      className,
    )}
    {...rest}
  />
))

DropdownMenuContent.displayName = 'DropdownMenuContent'
