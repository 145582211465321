import React, { useMemo } from 'react'
import { formatDate } from 'date-fns'

import Table from 'js/shared-components/uikit/table/Table'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import TableCell from 'js/shared-components/uikit/table/TableCell'

import { type OrderSide } from 'js/constants/trades'

import { OrderListContainer } from '../styles'
import { USDC_SYMBOL } from 'js/constants/shared'
import { useOrderBookTrades } from 'js/providers/order-book-store'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import HeaderCell from 'js/shared-components/HeaderCell'
import cn from 'js/util/cn'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'

interface TradesListProps {
  selectedSide: OrderSide
}

export const TradesList = ({ selectedSide }: TradesListProps) => {
  const trades = useOrderBookTrades()
  const currentMarket = useCurrentMarket()

  const tradesToDisplay = useMemo(
    () =>
      (trades ?? [])
        .filter((t) => {
          switch (selectedSide) {
            case 'asks':
              return !t.is_maker_ask
            case 'bids':
              return t.is_maker_ask
            case 'all':
            default:
              return true
          }
        })
        .slice(0, 20),
    [trades, selectedSide],
  )

  return (
    <OrderListContainer>
      {trades === null ? (
        <Table>
          <TableHeader>
            <TableHeaderRow>
              <HeaderCell title="Time" />
              <HeaderCell title="Size" symbol={currentMarket.symbol} className="justify-end" />
              <HeaderCell title="Price" symbol={USDC_SYMBOL} className="justify-end" />
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {Array(10)
              .fill(null)
              .map((_, i) => (
                <TableRow key={i} className="h-5">
                  {new Array(3).fill(null).map((_, j) => (
                    <TableCell key={j} className="h-3 p-1">
                      <SkeletonRectangle />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        <Table>
          <TableHeader>
            <TableHeaderRow>
              <HeaderCell title="Time" />
              <HeaderCell title="Size" symbol={currentMarket.symbol} className="justify-end" />
              <HeaderCell title="Price" symbol={USDC_SYMBOL} className="justify-end" />
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {tradesToDisplay.map((trade) => (
              <TableRow
                key={trade.trade_id}
                className="h-5 border-0"
                onClick={() =>
                  window.open(`https://scan.lighter.xyz/tx/${trade.tx_hash}`, '_blank')
                }
              >
                <TableCell>
                  <p className="typography-body-1 text-white">
                    {formatDate(new Date(trade.timestamp), 'HH:mm:ss')}
                  </p>
                </TableCell>
                <TableCell>
                  <p
                    className={cn('typography-body-1 text-end', {
                      'text-green-main': trade.is_maker_ask,
                      'text-red-main': !trade.is_maker_ask,
                    })}
                  >
                    {trade.size}
                  </p>
                </TableCell>
                <TableCell>
                  <p className="typography-body-1 text-white">{trade.price}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </OrderListContainer>
  )
}
