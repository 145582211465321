import type { Order } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface OrderAmountFilledCellProps {
  order: Order
}

const OrderAmountFilledCell = ({ order }: OrderAmountFilledCellProps) => (
  <TableCell>
    <div className="flex flex-col items-end">
      <p className="typography-body-1 text-white">{order.initial_base_amount}</p>
      <p className="typography-body-1 text-white">{order.filled_base_amount}</p>
    </div>
  </TableCell>
)

export default OrderAmountFilledCell
